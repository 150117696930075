input[type="text"],
input[type="email"],
input[type="password"], select {
	width: 100%;
	border: 0;
	background-color: #fff;
	padding: 20px;
	margin-bottom: 10px;
	font-family: $fontRegular;
	font-size: 14px;
	color: $colorAccent1;
	letter-spacing: 1px;
	appearance: none;

	@media (max-width: 599px) {
		padding: 15px;
		font-size: 13px;
	}
}

select {
	-webkit-appearance: none;
	background-image: url("/img/site/arrow-down.svg");
	background-repeat: no-repeat;
	background-size: 20px;
	background-position: calc(100% - 20px) center;
}

::placeholder {
		font-family: $fontRegular;
		color: $colorAccent1;
		font-size: 14px;
		letter-spacing: 1px;
	}

input[type="submit"] {
	border: 0;
	background-color: $colorAccent1;
	color: $colorAccent2;
	font-family: $fontSemi;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding: 20px 60px 20px 20px;
	cursor: pointer;
	background-image: url("/img/site/work-icon.svg");
	background-repeat: no-repeat;
	background-size: 15px;
	background-position: calc(100% - 20px) center;
	transition: all 0.2s ease;

	&:hover {
		background-color: $colorAccent3;
	}
}

input[type="file"] {
	width: 100%;
	position: relative;
	z-index: 100;
	padding-left: 80px;
}

textarea {
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	height: 200px;
	min-height: 200px;
	border: 0;
	background-color: #fff;
	padding: 20px;
	font-family: $fontRegular;
	font-size: 14px;
	letter-spacing: 1px;

	@media (max-width: 599px) {
		padding: 15px;
	}
}

.input-wrap {
	width: 100%;
	background-color: #fff;
	padding: 20px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	.regular-text {
		font-size: 14px;
		color: $colorAccent1;
	}

	.plus {
		width: 25px;
		position: absolute;
		right: 20px;
		top: 17px;
		z-index: 1;
	}
}

.radio-btns {
	margin-top: 20px;

	@media (max-width: 767px) {
		margin-top: 0;
	}

	&.negate-margin {
		margin-top: 0;
	}

	label {
		font-family: $fontRegular;
		font-size: 14px;
		color: #212C47;
		letter-spacing: 1px;
		line-height: 1;

		@media (max-width: 767px) {
			font-size: 13px;
		}
	}

	.radios-wrap {
		display: flex;
		align-items: center;

		.radio-input {
			display: flex;
			align-items: start;
			padding-left: 15px;
			cursor: pointer;

			@media (max-width: 767px) {
				padding-left: 10px;
			}

			input {
				position: relative;
				top: -1px;
				margin: 0;
				padding: 0;
				margin-right: 5px;
				cursor: pointer;

				@media (max-width: 767px) {
					margin-right: 3px;
				}
			}
		}
	}
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);

  font-family: $fontRegular;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input.logo::before {
	content: 'Logo';
}
.custom-file-input.photo::before {
	content: 'Photo';
}
.custom-file-input.cv::before {
	content: 'Curriculum vitae';
}
.custom-file-input.lettre::before {
	content: 'Lettre';
}

.custom-file-input.lettre.letter::before {
	content: 'Cover letter';
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 25px;
  cursor: pointer;;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $colorAccent2;
  border: 3px solid $colorAccent1;
}


/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $colorAccent3;
  border: 3px solid $colorAccent3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 7px;
  border: solid $colorAccent2;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.regular-text.placeholder-label,
.regular-text.placeholder-label a {
	color: $colorAccent1;

	strong {
		font-family: $fontSemi;
		display: inline-block;
		color: $colorAccent3;
		font-weight: 800;
		padding-right: 5px;
	}
}
