.simple-hero {
  position: relative;
  width: 100%;

  margin-top: 115px;

  @media (max-width: 1024px) {
    margin-top: 75px;
  }

  img.main {
    display: block;
    width: 100%;

    @media (max-width: 599px) {
      min-height: 400px;
      object-fit: cover;
    }
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    width: 100%;
    height: 100%;
    padding: 4vw 10vw;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: 1255px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .simple-hero-title {
    font-family: $fontBlack;
    font-style: normal;
    font-weight: 900;
    font-size: 54px;
    line-height: 100%;
    /* or 65px */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* Beige */
    color: $colorAccent2;

    @media (max-width: 1255px) {
      font-size: 46px;
    }

    @media (max-width: 599px) {
      font-size: 32px;
    }
  }

  .play-btn {
    @media (max-width: 1024px) {
      max-width: 55px;
    }
  }
}

.intro {
  position: relative;
  width: 100%;
  padding: 6vw 0;
  padding-bottom: 4vw;
  background: $colorAccent2;

  display: flex;
  align-items: center;

  &.reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .left {
    width: 50%;
    padding: 0 10vw;

    @media (max-width: 1255px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .intro-title {
      font-family: $fontBlack;
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 100%;
      /* or 45px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Beige */
      color: $colorAccent1;
      margin-bottom: 45px;

      @media (max-width: 1255px) {
        font-size: 36px;
      }

      @media (max-width: 599px) {
        font-size: 26px;
        margin-bottom: 20px;
      }
    }

    .intro-text {
      font-family: $fontRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* or 30px */

      letter-spacing: 0.05em;

      /* Bleu */
      color: $colorAccent1;

      margin: 0;
      padding: 0;
      margin-bottom: 35px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    width: 50%;

    img {
      display: block;
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    padding-top: 45px;

    &.reverse {
      flex-direction: column-reverse;
      padding-top: 45px;
    }

    .intro-title {
      br {
        display: none;
      }
    }

    .left, .right {
      width: 100%;
    }

    .left {
      padding-top: 45px;
    }

    .right {

      img {
        display: block;
        width: 100%;
        max-height: 60vw;
        object-fit: cover;
        object-position: center bottom;
      }
    }
  }
}

.testimonial-block {
  position: relative;
  width: 100%;
  padding: 4vw 10vw;
  background: $colorAccent2;

  @media (max-width: 1255px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 599px) {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .box {
    padding: 4vw;
    background: $colorAccent4;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    .left {
      width: 35%;
      border: 5px solid $colorAccent2;

      @media (max-width: 767px) {
        width: 100%;
        border: none;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .right {
      width: calc(100% - 35%);
      padding: 4vw;

      @media (max-width: 767px) {
        width: 100%;
        padding: 20px;
      }

      .testimonial-text,
      span {
        font-family: $fontSemi;
        font-style: italic;
        font-weight: 600;
        font-size: 25px;
        line-height: 150%;
        /* or 45px */

        letter-spacing: 0.05em;

        /* Beige */
        color: $colorAccent2;
        margin-top: 35px;

        @media (max-width: 1255px) {
          font-size: 18px;
        }
      }

      span {
        display: inline-block;
        font-family: $fontLight;
        line-height: 1.2;

        strong {
          font-family: $fontSemi;
          display: block;
        }
      }
    }
  }
}

.articles-block {
  position: relative;
  width: 100%;
  background: $colorAccent2;
  padding: 4vw 10vw;
  padding-bottom: 0;

  @media (max-width: 1255px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .articles-block-title {
    font-family: $fontBlack;
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    line-height: 100%;
    /* identical to box height, or 45px */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* Bleu */
    color: $colorAccent1;
    padding-bottom: 45px;

    @media (max-width: 1255px) {
      font-size: 32px;
    }

    @media (max-width: 1024px) {
      font-size: 25px;
      line-height: 1;
      padding-bottom: 20px;
    }
  }

  .articles {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .article {
      width: calc(98% / 2);
      margin-bottom: 45px;

      @media (max-width: 599px) {
        width: 100%;
      }

      img {
        display: block;
        width: 100%;
      }

      .article-title {
        font-family: $fontRegular;
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 1.2;
        /* identical to box height */

        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Bleu */
        color: $colorAccent1;
        margin-bottom: 15px;
        padding-top: 25px;

        @media (max-width: 1255px) {
          font-size: 25px;
          line-height: 1;
        }
      }

      .article-text {
        font-family: $fontRegular;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        /* or 30px */

        letter-spacing: 0.05em;

        /* Bleu */

        color: $colorAccent1;
      }
    }
  }
}

.ctas-center {
  padding-top: 0;
  padding-bottom: 4vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 599px) {
    margin-bottom: 30px;
  }
}

.avatanges-list {
  position: relative;
  width: 100%;
  background: $colorAccent1;
  padding: 4vw 10vw;
  padding-bottom: 6vw;

  @media (max-width: 1255px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .avatanges-title {
    width: 100%;

    font-family: $fontBlack;
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    line-height: 100%;
    /* or 45px */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    padding-bottom: 45px;

    /* Beige */
    color: $colorAccent2;

    @media (max-width: 599px) {
      font-size: 25px;
      padding-bottom: 20px;
    }
  }

  .avantages {
    margin-top: 2.5vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    .sub-title {
      width: 100%;
      font-size: 18px;
      color: $colorAccent2;
      margin-bottom: 1.5vw;
    }

    .avantage {
      width: calc(33.33% - 10px);
      display: flex;
      align-items: center;
      padding: 20px;
      border: 2px solid $colorAccent2;
      margin-bottom: 20px;

      @media (max-width: 1024px) {
        padding: 15px;
      }

      @media (max-width: 599px) {
        width: 100%;
      }

      img {
        width: 25px;
        margin-right: 15px;
      }

      .sub-title {
        font-size: 14px;
        color: $colorAccent2;
        margin-bottom: 0;

        @media (max-width: 1024px) {
          font-size: 12px;
          line-height: 1.2;
        }
      }
    }
  }
}

.testimonial-block-carousel {
  position: relative;

  .box, .box.carousel-cell {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    min-height: 100%;

    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 767px) {
      padding-bottom: 45px;
    }

    @media (max-width: 599px) {
      .testimonial-text {
        font-size: 16px;
      }

      .right span {
        font-size: 16px;
      }

      .left img {
        min-height: 420px;
      }
    }
  }

  .flickity-page-dots {
		bottom: inherit;
		right: 50%;
		bottom: 20px;
		transform: translate(50%, 0);
		width: auto;
		z-index: 200;

    @media (max-width: 767px) {
      bottom: 20px;
    }

		.dot {
			display: inline-block;
			margin-bottom: 0;
			width: 20px;
			height: 20px;
			opacity: 1;
			background-color: transparent;
			border: 4px solid $colorAccent2;
			transition: all 0.2s ease;

			&.is-selected {
				background-color: $colorAccent2;
			}

      @media (max-width: 767px) {
        display: inline-block;
        margin: 0 5px;
      }
		}
	}
}