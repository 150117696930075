.header-lang {
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    font-family: $fontBlack;
    background: $colorAccent1;
    color: $colorAccent2;

    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #F2EEDF;
    z-index: 400;
    width: 100%;
    background: #4B5365;
    padding-left: 22vw;

    display: flex;
    align-items: stretch;
    justify-content: flex-end;
}

.left-search {
    position: relative;

    form {
      position: relative;
    }

    input {
      width: 340px;
      border-radius: 0;
      background: transparent;
      border: none;
      padding: 15px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      color: $colorAccent2;
      border-left: 1px solid rgba($colorAccent2, 0.3);

      @media (max-width: 767px) {
        width: 186px;
        min-width: 186px;
      }

      &::-webkit-input-placeholder {
        color: $colorAccent2;
      }
    }

    button {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      z-index: 2;
      background: none;
      border: none;
      margin-top: 2px;
      cursor: pointer;
    }
  }

.recherche {
  .max-container {
    max-width: 1040px;
    margin: 0 auto;
    padding: 0 20px;
    padding-bottom: 80px;
  }

  .pagination-wrap {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .no-results {
    padding-top: 4vw;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 6vw 0;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(#212C47, 0.3);
      color: $colorAccent1;

      .right {
        width: 100px;
        min-width: 100px;
        border-radius: 0;
        margin-left: 6vw;

        img,
        svg {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 0;
        }
      }

      .medium-title {
        color: $colorAccent1;
        font-size: 20px;
        font-weight: 900;
        font-family: $fontBlack;
        text-transform: uppercase;
      }

      .small-title {
        color: $colorAccent4;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 10px;
        font-weight: 900;
        font-family: $fontBlack;
        text-transform: uppercase;
      }

      .reading-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.55;
      }
    }
  }
}

.search-header-box {
    background: $colorAccent1;
    padding: 6vw;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .search-title {
        font-family: $fontBlack;
        font-weight: 900;
        text-transform: uppercase;
        color: $colorAccent2;
        font-size: 48px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%; /* 65px */
        letter-spacing: 3.25px;
        text-transform: uppercase;
        padding-bottom: 20px;
    }

    .search-subtitle {
        font-family: $fontBlack;
        font-weight: 900;
        text-transform: uppercase;
        color: $colorAccent4;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%; /* 30px */
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
}