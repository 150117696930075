.emplois-hero {
	width: 100%;
	margin-top: 115px;
	display: flex;

	@media (max-width: 1024px) {
		margin-top: 75px;
	}

	.left {
		width: 50%;
		height: 25vw;
		background-image: url("/img/site/emplois-hero-1.jpg");
		background-size: cover;
		background-position: center center;
	}

	.right {
		width: 50%;
		height: 25vw;
		display: flex;

		@media (max-width: 599px) {
		  display: none;
		}

		.half {
			width: 50%;

			&.work {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $colorAccent1;

				img {
					width: 10vw;
				}

				.sub-title {
					position: absolute;
					right: 20px;
					bottom: 20px;
					font-size: 18px;
					color: $colorAccent2;
				}
			}

			&.img {
				background-image: url("/img/site/emplois-hero-3.jpg");
				background-size: cover;
				background-position: center center;
			}
		}
	}

	@media (max-width: 955px) {
		flex-wrap: wrap;

		.left, .right {
			width: 100%;
			height: 255px;
		}
	}
}

.emploi-details {
	width: 100%;
	margin-top: 75px;
	display: flex;

	@media (max-width: 1024px) {
    margin-top: 50px;
  }

	&.fixed {
		.left {
			.main-carousel {
				position: fixed;
				top: 75px;
				left: 0;
				width: 50%;

				@media (max-width: 955px) {
					position: relative;
					width: 100%;
					top: 0;
					height: 80vw;

					.carousel-cell {
						height: 80vw;
					}
				}
			}
		}
	}

	.left {
		width: 50%;
		position: relative;

		.main-carousel {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: calc(100vh - 75px);

			.carousel-cell {
				width: 100%;
				height: calc(100vh - 75px);
				background-size: cover;
				background-position: center center;

				.play {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 80px;
					cursor: pointer;
				}
			}

			.flickity-page-dots {
				bottom: 2vw;

				.dot {
					width: 20px;
					height: 20px;
					opacity: 1;
					background-color: transparent;
					border: 4px solid $colorAccent2;
					transition: all 0.2s ease;
					z-index: 200;

					@media (max-width: 599px) {
            width: 13px;
            height: 13px;
            border: 2px solid $colorAccent2;
            margin: 0 4px;
          }

					&.is-selected {
						background-color: $colorAccent2;
					}
				}
			}
		}

		.logo {
			position: absolute;
			left: 45px;
			top: calc(90vh - 200px);
			z-index: 9;

			display: block;
			width: 200px;
			height: auto;
		}
	}

	.right {
		width: 50%;
		min-height: calc(100vh - 75px);
		background-color: $colorAccent1;
		padding: 5vw;

		@media (max-width: 599px) {
			padding-bottom: 15px;
			padding-top: 45px;
	  }

		.emplois-infos {
			margin-top: 25px;
			margin-bottom: 4vw;
			line-height: 1.5;
		}

		.title {
			font-size: 28px;
			color: $colorAccent2;
		}

		.entreprise-infos {
			width: 100%;
			display: flex;
			align-items: center;
			margin-top: 1.5vw;
			margin-bottom: 0;
			padding-bottom: 0;

			img {
				width: 75px;
				margin-right: 20px;
			}

			.sub-title {
				color: $colorAccent4;
				margin-bottom: 0;
				padding-bottom: 0;
				transition: all 0.2s ease;
			}

			&:hover {
				.sub-title {
					color: $colorAccent3;
				}
			}
		}

		.avantages {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			margin-top: 35px;

			.avantage {
				width: 48%;
				display: flex;
				align-items: center;
				margin-bottom: 1vw;

				@media (max-width: 599px) {
				  width: 100%;
				  margin-bottom: 0;
				  padding: 10px 0;
				}

				img {
					width: 25px;
					margin-right: 15px;
				}

				.sub-title {
					font-size: 14px;
					color: $colorAccent2;
				}
			}
		}

		.salaire-wrap {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-image: url("/img/site/dot-2.jpg");
			background-repeat: repeat-x;
			background-size: 30px;
			background-position: center center;
			position: relative;
			margin-top: 3vw;
			margin-bottom: 2.5vw;

			@media (max-width: 955px) {
				margin-top: 45px;
				margin-bottom: 45px;
			}

			.round {
				width: auto;
				height: auto;
				background-color: $colorAccent2;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 10px;
				border-radius: 0;

				.sub-title {
					color: $colorAccent1;
					font-size: 14px;
					margin-bottom: 0;
					line-height: 1;
				}
			}

			.salaire-title {
				position: absolute;
				top: -10px;
				left: 50%;
				transform: translate(-50%,0);
				color: $colorAccent2;
				font-size: 14px;
			}

			&.only-one {
				background: none;

				.round {
					width: auto;
					height: auto;
					padding: 10px;
					border-radius: 0;
				}

				.salaire-title {
					position: absolute;
					top: -30px;
					left: 0;
					transform: none;
				}
			}
		}

		.data-wrap {
			width: 100%;
			margin-top: 3vw;
			margin-bottom: 3vw;

			.sub-title {
				font-size: 18px;
				color: $colorAccent2;
				margin-bottom: 1.5vw;
			}

			.regular-text {
				color: $colorAccent2;
				margin-bottom: 1.5vw;
			}

			ul {
				li {
					padding-left: 30px;
					position: relative;
					&.regular-text {
						margin-bottom: 10px;
					}
					&:before {
						content: "";
						display: block;
						width: 14px;
						height: 14px;
						border-radius:50%;
						background-color: $colorAccent4;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(0,-50%);
					}
				}
			}
		}

		.cta-wrap {
			background-color: $colorAccent4;

			@media (max-width: 955px) {
				margin-top: 30px;
				margin-bottom: 30px;
			}

			&:hover {
				background-color: $colorAccent3;
			}
		}
	}

	@media (max-width: 955px) {
		flex-wrap: wrap;

		.left, .right {
			width: 100%;
			min-height: inherit;
		}
	}
}

.emplois-listing,
.filters-bar-wrapper {
	width: 100%;
	padding: 5vw 10vw;

	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&.no-padding {
		padding: 0;

		.title {
			margin-bottom: 0;
		}
	}

	@media (max-width: 1255px) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.title {
		width: 100%;
		font-size: 28px;
		color: $colorAccent1;
		margin-bottom: 2.5vw;

		@media (max-width: 767px) {
			margin-bottom: 25px;
		}
	}

	.subtitle {
		width: 100%;
		font-size: 20px;
		color: $colorAccent4;
		margin-bottom: 1.5vw;
	}

	.regular-text {
		width: 100%;
		margin-bottom: 25px;
	}

	.regular-text.intro-text {
		font-size: 20px;

		@media (max-width: 767px) {
			font-size: 16px;
		}
	}

	.select-radio-wrap {
		width: 100%;
		display: flex;
		margin-bottom: 3vw;
		margin-top: 1vw;
		position: relative;
		z-index: 100;

		align-items: stretch;
		flex-wrap: nowrap;

		@media (max-width: 955px) {
			flex-wrap: wrap;
		}

		.select {
			margin: 0;
			width: 33.33%;
			height: 70px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 25px;
			border-top: 2px solid $colorAccent1;
			border-bottom: 2px solid $colorAccent1;
			border-right: 2px solid $colorAccent1;
			cursor: pointer;
			position: relative;
			background-color: $colorAccent2;

			color: #212C47;
			text-decoration: none;
			font-size: 16px;
			text-transform: none;
			font-family: 'alvar_black', 'Arial', 'sans-serif';

			@media (max-width: 955px) {
				width: 100%;
				margin-bottom: 10px;
				border-top: 2px solid $colorAccent1;
				border-bottom: 2px solid $colorAccent1;
				border-right: 2px solid $colorAccent1;
				border-left: 2px solid $colorAccent1;
			}

			&.half {
				width: 50%;

				@media (max-width: 955px) {
					width: 100%;
				}
			}

			&:first-child {
				border-left: 2px solid $colorAccent1;
			}

			.sub-title {
				font-size: 16px;
				color: $colorAccent1;
			}

			img {
				width: 15px;
			}

			&.selected {
				.drop-down {
					height: 256px;
				}
			}

			.wrap {
				padding: 2vw;
			}

			.drop-down {
				width: calc(100% + 4px);
				height: 0;
				background-color: $colorAccent4;
				position: absolute;
				left: -2px;
				top: 68px;
				overflow: hidden;
				transition: all 0.2s ease;
			}

			/* The container */
			.container {
				display: block;
				position: relative;
				padding-left: 35px;
				margin-bottom: 12px;
				cursor: pointer;
				font-size: 16px;
				text-transform: uppercase;
				user-select: none;
				font-family: $fontSemi;
				color: $colorAccent1;
				padding-top: 3px;
				text-align: left;

				&:last-child {
					margin-bottom: 0;
				}
			}

			/* Hide the browser's default radio button */
			.container input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
			}

			/* Create a custom radio button */
			.checkmark {
				position: absolute;
				top: 0;
				left: 0;
				height: 20px;
				width: 20px;
				background-color: $colorAccent4;
				border-radius: 50%;
				border: 4px solid $colorAccent1;
				transition: all 0.2s ease;
			}

			/* When the radio button is checked, add a blue background */
			.container input:checked ~ .checkmark {
				background-color: $colorAccent1;
			}

			/* Create the indicator (the dot/circle - hidden when not checked) */
			.checkmark:after {
				content: "";
				position: absolute;
				display: none;
			}

			/* Show the indicator (dot/circle) when checked */
			.container input:checked ~ .checkmark:after {
				display: block;
			}

			/* Style the indicator (dot/circle) */
			.container .checkmark:after {
			 	opacity: 0;
			}

			.container input:checked ~ .checkmark {
				border: 5px solid $colorAccent1;
			}
		}

		.filters-button {
			width: 70px;
			background-color: $colorAccent2;
			background-image: url(/img/site/slider-arrow-right-reverted.jpg);
			background-position: center center;
			background-repeat: no-repeat;
	  
			cursor: pointer;
	  
			transition: opacity 0.2s ease;
	  
			&:hover {
			  opacity: 0.75;
			}
	  
			@media (max-width: 955px) {
			  display: none;
			}
		  }
	}

	.pagination-wrap {
		margin-top: 2.5vw;
		width: 100%;
		display: flex;
		justify-content: center;

		.cta-wrap {
			margin: 0 2%;
			&:hover {
				background-color: $colorAccent3;
			}
		}
	}

	.emplois-box {
		width: calc(50% - 10px);
		margin-bottom: 20px;
		background-color: $colorAccent1;
		padding: 3vw 1vw 1vw 3vw;
		position: relative;

		@media (max-width: 599px) {
			width: 100%;
			padding: 30px 20px;
		}

		&.entreprise-box {
			padding: 0;
			.entreprise-img {
				width: 100%;
			}
			.data {
				padding: 3vw 1vw 1vw 3vw;

				@media (max-width: 599px) {
					padding: 30px 20px;
				}

				.avantages {
					margin: 2.5vw 0;
				}

				.box-footer {
					position: absolute;
					right: 1vw;
					bottom: 1vw;
					width: fit-content;

					@media (max-width: 1025px) {
					  right: 0;
					  bottom: 0;
					}
				}
			}
		}

		.title {
			font-size: 24px;
			color: $colorAccent2;
			margin-bottom: 10px;
		}

		.sub-title {
			font-size: 14px;
			color: $colorAccent2;
		}

		.avantages {
			margin: 3.5vw 0;

			margin-top: 25px;

			@media (max-width: 599px) {
			  margin-bottom: 25px;
			}

			.avantage {
				display: flex;
				align-items: center;
				margin-bottom: 1vw;

				@media (max-width: 599px) {
					margin-bottom: 10px;
				}

				img {
					width: 25px;
					margin-right: 15px;
				}

				.sub-title {
					font-size: 14px;
					color: $colorAccent2;
				}
			}
		}

		.box-header {
			display: flex;
			align-items: stretch;
			justify-content: flex-start;

			flex-direction: row-reverse;

			padding-bottom: 0;
			margin-bottom: 0;

			.logo-wrap {
				max-width: 95px;
				min-width: 95px;
				min-height: 95px;

				padding: 5px;

				margin-right: 0;
				margin-left: auto;

				background: $colorAccent2;

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				@media (max-width: 599px) {
					max-width: 75px;
					min-width: 75px;
					min-height: 75px;
				}
			}

			.text-wrap {
				width: 100%;
				max-width: 375px;

				display: flex;
				flex-direction: column;
				justify-content: center;

				.title {
					padding-right: 2vw;
				}

				.sub-title {
					display: none;
				}

				@media (max-width: 599px) {
					.title {
						font-size: 18px;
					}
				}
			}
		}

		.box-footer {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.regular-text {
				font-size: 14px;
				color: $colorAccent2;
			}

			.cta {
				width: 50px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $colorAccent4;
				transition: all 0.2s ease;

				img {
					width: 15px;
				}
			}
		}

		&:hover {
			.box-footer {
				.cta {
					background-color: $colorAccent3;
				}
			}
		}
	}
}

p.disclaimer {
	width: 100%;
	line-height: 1.25;
	@media (max-width: 599px) {
		text-align: center;
	}
}

p.disclaimer small {
	width: 100%;
	font-size: 88%;
	line-height: 1.25;

	@media (max-width: 599px) {
		text-align: center;
		font-size: 80%;
	}
}