body {
	background-color: $colorAccent2;
	letter-spacing: 1px;

	&.scroll {
		overflow: hidden;
	}
}

* { box-sizing: border-box; }

img {
	width: 100%;
	height: auto;
	display: inherit;
}

button {
	border:0;
	background-color: transparent;
	letter-spacing: 1px;
}

a {
	text-decoration: none;
}

::selection {
  background-color: $colorAccent4;
  color: #fff;
}

::-moz-selection {
  background-color: $colorAccent4;
  color: #fff;
}

/*! Lazyload
---------------------------------------------- */
/*
img {
  opacity: 0;
  transition: opacity 0.45s ease 0.45s, padding 0s linear 0s, height 0s linear 0s;

  height: 0;
  width: 100%;
  padding-bottom: 56.25%;

  &.lazyloaded,
  &.flickity-lazyloaded {
    opacity: 1;
    height: auto;
    padding-bottom: 0;
  }

  &.force-lazyload {
    opacity: 1;
  }
}

.main-carousel .flickity-slider {
  img {
    opacity: 1;
  }
}

#map {
  img {
    opacity: 1;
    transition: none;
  }
}
*/
