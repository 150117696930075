.basic-content {
  width: 100%;
  max-width: 1100px;
  margin: 2.5vw auto;
  padding: 2.5vw 20px;
  background: #fff;
}

.basic-content-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 0;

  font-family: $fontRegular;

  .regular-title, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
    color: $colorAccent1;
    text-transform: uppercase;
    font-size: 30px;

    @media (max-width: 1100px) {
      font-size: 24px;
    }

    @media (max-width: 890px) {
      font-size: 22px;
    }
  }

  h3 {
    font-size: 28px;

    @media (max-width: 1100px) {
      font-size: 22px;
    }

    @media (max-width: 890px) {
      font-size: 20px;
    }
  }

  h4 {
    font-size: 26px;

    @media (max-width: 1100px) {
      font-size: 20px;
    }

    @media (max-width: 890px) {
      font-size: 18px;
    }
  }

  h5 {
    font-size: 26px;

    @media (max-width: 1100px) {
      font-size: 20px;
    }

    @media (max-width: 890px) {
      font-size: 18px;
    }
  }

  h6 {
    font-size: 26px;

    @media (max-width: 1100px) {
      font-size: 20px;
    }

    @media (max-width: 890px) {
      font-size: 18px;
    }
  }

  &, .regular-text, p, ul, ul li {
    color: $colorAccent1;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.8;
    font-size: 18px;

    strong {
      font-family: $fontSemi;
      font-weight: 500;
    }

    @media (max-width: 1100px) {
      font-size: 16px;
    }

    a {
      color: $colorAccent3;
    }
  }

  ul li {
    margin: 0;
  }

  li {
    margin-bottom: 10px;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  img.full,
  figure img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .iframe-fix {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 25px 0;

    iframe {
      position: relative;
      width: 100% !important;
      height: auto !important;
      min-height: 32vw !important;

      @media (max-width: 700px) {
        min-height: 50vw !important;
      }
    }
  }
}
