.simple-hero-recits {
    position: relative;
    width: 100%;
  
    margin-top:115px;
  
    @media (max-width: 1024px) {
      margin-top: 75px;
    }
  
    img.main {
      display: block;
      width: 100%;
  
      @media (max-width: 1224px) {
        min-height: 600px;
        object-fit: cover;
      }

      @media (max-width: 599px) {
        min-height: 445px;
      }
    }

    .img-video-asset {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      z-index: 2;

      video {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          object-fit: cover;

          &::-webkit-media-controls {
              display: none !important;
          }
      }
  }
  
    .content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
  
      width: 48%;
      height: 100%;
      padding: 4vw 10vw;
  
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      margin-top: 25px;

      @media (max-width: 1224px) {
        width: 60%;
      }

      @media (max-width: 599px) {
        width: 100%;
        margin-top: 0;
        padding-top: 45px;
      }
    }
  
    .simple-hero-title {
      font-family: $fontBlack;
      font-style: normal;
      font-weight: 900;
      font-size: 46px;
      line-height: 100%;
      /* or 65px */
  
      letter-spacing: 0.05em;
      text-transform: uppercase;
  
      /* Beige */
      color: $colorAccent2;

      margin-top: 75px;
  
      @media (max-width: 1024px) {
        font-size: 32px;
      }
    }

    .simple-hero-subtitle {
        font-family: $fontSemi;
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 100%;
        /* or 65px */
    
        letter-spacing: 0.05em;
        text-transform: uppercase;
    
        /* Beige */
        color: $colorAccent4;

        margin-top: 25px;
        margin-bottom: 5px;
    }

    .simple-hero-text {
      font-family: $fontRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.05em;
      margin: 0;
      padding: 0;
      /* Beige */
      color: $colorAccent2;

      margin-top: 5px;
      margin-bottom: 25px;
    }
    
    .play-btn {
      max-width: 55px;

      @media (max-width: 1024px) {
        max-width: 45px;
      }
    }
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        bottom: 0;
        left: 0;
        z-index: 3;

        background: -moz-linear-gradient(left,  rgba(0,0,0,0.95) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(left,  rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to right,  rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%);

        @media (max-width: 1024px) {
          right: 0;
        }
    }
}

.notice {
  padding: 2vw 10vw;
  text-align: center;
  background: rgba($colorAccent1, 0.05123);

  @media (max-width: 1255px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.recits-block {
    position: relative;
    width: 100%;
    background: $colorAccent2;
    padding: 4vw 10vw;
    padding-bottom: 0;
  
    @media (max-width: 1255px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .articles-block-title {
      font-family: $fontBlack;
      font-style: normal;
      font-weight: 900;
      font-size: 38px;
      line-height: 100%;
      /* identical to box height, or 45px */
  
      letter-spacing: 0.05em;
      text-transform: uppercase;
  
      /* Bleu */
      color: $colorAccent1;
      padding-bottom: 45px;
  
      @media (max-width: 1255px) {
        font-size: 32px;
      }
  
      @media (max-width: 1024px) {
        font-size: 25px;
        line-height: 1;
        padding-bottom: 20px;
      }
    }
  
    .articles {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  
      .article {
        width: calc(98% / 2);
        margin-bottom: 45px;
  
        @media (max-width: 599px) {
          width: 100%;
        }

        .img-wrap {
            position: relative;
            
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(#212C47, 0.4);
                z-index: 1;
            }
        }
  
        img {
          display: block;
          width: 100%;
        }

        .overlay-link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;

            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: block;
                width: 55px;
            }
        }
  
        .article-title {
          font-family: $fontRegular;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 1.2;
          /* identical to box height */
  
          letter-spacing: 0.05em;
          text-transform: uppercase;
  
          /* Bleu */
          color: $colorAccent4;
          margin-bottom: 15px;
          padding-top: 15px;
        }
  
        .article-text {
          font-family: $fontRegular;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.05em;
          color: $colorAccent1;
          margin: 0;
          padding: 0;
          margin-bottom: 35px;

          @media (max-width: 599px) {
            max-width: 100%;
          }
        }
      }
    }
}
.img-video-asset {
        position: relative;
        overflow: hidden;
        width: 100%;
        min-height: 375px;

        img {
            width: 100%;

            @media (max-width: 699px) {
                min-height: 375px;
                object-fit: cover;
            }
        }

        video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            object-fit: cover;

            @media (max-width: 699px) {
                min-height: 375px;
            }

            &::-webkit-media-controls {
                display: none !important;
            }
        }
    }