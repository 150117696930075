.emploi-details.region-hero .right .emplois-infos {
  margin-bottom: 0;
}

.accordion-wrapper {
  padding: 2.5vw 0;

  .accordion-title {
    font-family: $fontRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $colorAccent2;

    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url(/img/site/dot-2.jpg);
    background-repeat: repeat-x;
    background-size: 20px;
    background-position: center center;
    position: relative;
    padding: 15px 0;
    margin-right: 25px;

    span {
      display: inline-block;
      background: $colorAccent1;
      padding-right: 10px;
    }

    svg {
      position: relative;
      right: -25px;
      display: inline-block;
      background: $colorAccent1;
    }
  }

  .accordion-content {
    position: relative;
    overflow: hidden;
    height: 0;
    transform: scale(1, 0);
    transform-origin: center top;

    transition: height $t2 $bounce $t2, transform $t2 $bounce 0s;

    .inner {
      background: rgba(#fff, 0.05723);
      padding: 15px;
      font-family: $fontRegular;

      .regular-title, h2, h3, h4, h5, h6 {
        margin-bottom: 10px;
        color: $colorAccent2;
        text-transform: uppercase;
        font-size: 24px;

        @media (max-width: 1100px) {
          font-size: 22px;
        }

        @media (max-width: 890px) {
          font-size: 18px;
        }
      }

      &, .regular-text, p, ul, ul li {
        color: $colorAccent2;
        font-weight: 400;
        margin-bottom: 20px;
        line-height: 1.8;
        font-size: 16px;

        @media (max-width: 1100px) {
          font-size: 14px;
        }

        a {
          color: $colorAccent4;
        }
      }

      ul {
        padding-left: 15px;
        list-style-type: disc;
        line-height: 1.15;
      }

      ul li {
        margin: 0;
      }

      li {
        margin: 0;
        line-height: 1.25;
        padding-bottom: 7px;
      }

      figure {
        margin: 0;
        padding: 0;
      }

      img.full,
      figure img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 4px;
      }

      .iframe-fix {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 25px 0;

        iframe {
          position: relative;
          width: 100% !important;
          height: auto !important;
          min-height: 32vw !important;

          @media (max-width: 700px) {
            min-height: 50vw !important;
          }
        }
      }
    }
  }

  .is-active .accordion-content {
    height: auto;
    transform: none;
  }
}

.form-message {
  font-family: $fontRegular;
  display: block;
  margin: 0;
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 50px;
  line-height: 1;
  color: green;

  &.error {
    color: red;
  }
}
