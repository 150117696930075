.hero-all-entreprises {
	width: 100%;
	display: flex;
	margin-top: 75px;

	@media (max-width: 1024px) {
    margin-top: 50px;
  }

	.left {
		width: 50%;
		height: 40vw;
		background-image: url("/img/site/hero-entreprises.jpg");
		background-size: cover;
		background-position: center center;
		position: relative;

		img {
			position: absolute;

			@media (max-width: 1024px) {
				display: none;
			}
		}

		#work1 {
			width: 3vw;
			top: 26vw;
			left: 5vw;
			opacity: 0;
			animation-name: work1;
  			animation-duration: 1s;
  			animation-delay: 0s;
  			animation-fill-mode: forwards;
  			animation-timing-function: ease;
		}
		@keyframes work1 {
		  from {top: 16vw; opacity: 0;}
		  to {top: 21vw; opacity: 1;}
		}
		#work2 {
			width: 4.5vw;
			top: 20vw;
			left: 40vw;
			opacity: 0;
			animation-name: work2;
  			animation-duration: 1s;
  			animation-delay: 0.6s;
  			animation-fill-mode: forwards;
  			animation-timing-function: ease;
		}
		@keyframes work2 {
		  from {top: 15vw; opacity: 0;}
		  to {top: 20vw; opacity: 1;}
		}
		#work3 {
			width: 5vw;
			top: 24vw;
			left: 16vw;
			opacity: 0;
			animation-name: work3;
  			animation-duration: 1s;
  			animation-delay: 1.2s;
  			animation-fill-mode: forwards;
  			animation-timing-function: ease;
		}
		@keyframes work3 {
		  from {top: 19vw; opacity: 0;}
		  to {top: 24vw; opacity: 1;}
		}
		#play1 {
			width: 3vw;
			top: 13vw;
			left: 43vw;
			opacity: 0;
			animation-name: play1;
  			animation-duration: 1s;
  			animation-delay: 0.2s;
  			animation-fill-mode: forwards;
  			animation-timing-function: ease;
		}
		@keyframes play1 {
		  from {top: 8vw; opacity: 0;}
		  to {top: 13vw; opacity: 1;}
		}
		#play2 {
			width: 3.9vw;
			top: 18vw;
			left: 21vw;
			opacity: 0;
			animation-name: play2;
  			animation-duration: 1s;
  			animation-delay: 0.8s;
  			animation-fill-mode: forwards;
  			animation-timing-function: ease;
		}
		@keyframes play2 {
		  from {top: 13vw; opacity: 0;}
		  to {top: 18vw; opacity: 1;}
		}
		#play3 {
			width: 4.8vw;
			top: 30vw;
			left: 30vw;
			opacity: 0;
			animation-name: play3;
  			animation-duration: 1s;
  			animation-delay: 1.4s;
  			animation-fill-mode: forwards;
  			animation-timing-function: ease;
		}
		@keyframes play3 {
		  from {top: 25vw; opacity: 0;}
		  to {top: 30vw; opacity: 1;}
		}
		#relax1 {
			width: 2.8vw;
			top: 12.5vw;
			left: 27vw;
			opacity: 0;
			animation-name: relax1;
  			animation-duration: 1s;
  			animation-delay: 0.4s;
  			animation-fill-mode: forwards;
  			animation-timing-function: ease;
		}
		@keyframes relax1 {
		  from {top: 7vw; opacity: 0;}
		  to {top: 12.5vw; opacity: 1;}
		}
		#relax2 {
			width: 5vw;
			top: 31vw;
			left: 10vw;
			opacity: 0;
			animation-name: relax2;
  			animation-duration: 1s;
  			animation-delay: 1s;
  			animation-fill-mode: forwards;
  			animation-timing-function: ease;
		}
		@keyframes relax2 {
		  from {top: 26vw; opacity: 0;}
		  to {top: 31vw; opacity: 1;}
		}
	}

	.right {
		width: 50%;
		height: 40vw;
		background-color: $colorAccent1;
		display: flex;
		align-items: center;
		padding: 8vw 6vw;

		@media (max-width: 1255px) {
			padding-left: 45px;
			padding-right: 45px;
		}

		@media (max-width: 955px) {
			height: auto;
			padding: 30px 20px;
		}

		.title {
			color: $colorAccent2;
			font-size: 55px;
			margin-bottom: 1vw;

			@media (max-width: 955px) {
				font-size: 42px;
			}
		}

		.sub-title {
			color: $colorAccent2;
			font-size: 22px;
			width: 70%;
			line-height: 1.2;
		}

		.stats {
			display: flex;

			.stat {
				text-align: center;
				margin-right: 5vw;
				margin-top: 2vw;

				.number {
					font-size: 100px;
					color: $colorAccent3;
					line-height: 1;
					font-family: $fontLight;

					@media (max-width: 955px) {
						font-size: 65px;
					}
				}

				.sub-title {
					font-size: 18px;
					width: 100%;
				}
			}
		}
	}

	@media (max-width: 955px) {
		flex-wrap: wrap;

		.left, .right {
			width: 100%;
		}

		.data {
			width: 100%;
		}
	}
}

.hero-entreprise {
	width: 100%;
	margin-top: 75px;
	position: relative;

	@media (max-width: 1024px) {
    margin-top: 50px;
  }

	.carousel-cell {
		width: 100%;
		height: 70vh;
		position: relative;
		z-index: 10;

		background-size: cover;
		background-position: center center;

		.play {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 80px;
			cursor: pointer;
		}
	}

	.flickity-page-dots {
		bottom: inherit;
		right: 20px;
		top: 50%;
		transform: translate(0,-50%);
		width: auto;
		z-index: 200;

		.dot {
			display: block;
			margin-bottom: 15px;
			width: 20px;
			height: 20px;
			opacity: 1;
			background-color: transparent;
			border: 4px solid $colorAccent2;
			transition: all 0.2s ease;

			&.is-selected {
				background-color: $colorAccent2;
			}
		}
	}

	.gradient {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 100;
		width: 120px;
		height: 100%;
		background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
		opacity: 0.5;
	}
}

.entreprise-profil-header {
	width: 100%;
	height: 130px;
	padding: 0 10vw;
	position: relative;
	background-color: $colorAccent2;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 1024px) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.profil-picture {
		width: 200px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);

		@media (max-width: 599px) {
			width: 140px;
			top: 0;
			left: 0;
			transform: translate( 0, -100%);
		}
	}

	.title {
		font-size: 28px;
		max-width: 25vw;

		@media (max-width: 599px) {
			max-width: 100%;
		}
	}

	.cta-wrap {
		img {
			transform: rotate(90deg);
		}
		&:hover {
			background-color: $colorAccent3;
		}

		@media (max-width: 599px) {
			display: none;
		}
	}
}

.entreprise-informations {
	width: 100%;
	background-color: $colorAccent1;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 6vw 10vw 10vw 10vw;

	@media (max-width: 1255px) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.left {
		width: 47%;

		.sub-title {
			font-size: 18px;
			color: $colorAccent2;
			margin-bottom: 1.5vw;
		}

		.regular-text {
			color: $colorAccent2;
		}

		address {
			margin-top: 1.5vw;

			@media (max-width: 1025px) {
			  margin-top: 35px;
			  margin-bottom: 15px;
			}

			a {
				display: flex;
				align-items: center;

				img {
					width: 25px;
					margin-right: 15px;
				}
			}
		}
	}

	.right {
		width: 47%;

		.stat {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 3.5vw;
			background-image: url("/img/site/dot-2.jpg");
			background-repeat: repeat-x;
			background-size: 30px;
			background-position: center center;

			.title {
				font-size: 40px;
				color: $colorAccent2;
				background-color: $colorAccent1;
				padding-right: 20px;

				@media (max-width: 599px) {
					font-size: 26px;
        }
			}

			.sub-title {
				font-size: 16px;
				color: $colorAccent2;
				background-color: $colorAccent1;
				padding-left: 20px;
			}
		}
	}

	@media (max-width: 955px) {
		flex-wrap: wrap;

		.left, .right {
			width: 100%;
			padding-bottom: 45px;
		}
	}


	.avantages {
		margin-top: 2.5vw;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;

		.sub-title {
			width: 100%;
			font-size: 18px;
			color: $colorAccent2;
			margin-bottom: 1.5vw;
		}

		.avantage {
			width: calc(33.33% - 10px);
			display: flex;
			align-items: center;
			padding: 20px;
			border: 2px solid $colorAccent2;
			margin-bottom: 20px;

			@media (max-width: 599px) {
				width: 100%;
			}

			img {
				width: 25px;
				margin-right: 15px;
			}

			.sub-title {
				font-size: 14px;
				color: $colorAccent2;
				margin-bottom: 0;
			}
		}
	}
}

.testimonials-wrap {
	padding: 0 10vw;
	margin-top: -5vw;

	@media (max-width: 1255px) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.main-carousel {
		width: 100%;
		background-color: $colorAccent4;
		padding: 7vw 0 9vw 0;

		.carousel-cell {
			width: 100%;
			text-align: center;

			img {
				width: 80px;
				display: inline-block;
				margin-bottom: 2.5vw;
			}

			.quote {
				text-transform: none;
				font-size: 25px;
				font-style: italic;
				margin-bottom: 2.5vw;
				padding: 0 10vw;
				color: $colorAccent2;
				line-height: 1.3;
			}

			.name {
				font-size: 16px;
				color: $colorAccent2;
			}

			.poste {
				color: $colorAccent2;
			}
		}

		.flickity-page-dots {
			bottom: 2vw;

			.dot {
				width: 20px;
				height: 20px;
				opacity: 1;
				background-color: transparent;
				border: 4px solid $colorAccent2;
				transition: all 0.2s ease;

				&.is-selected {
					background-color: $colorAccent2;
				}
			}
		}
	}
}

.entreprise-job-list {

	.title {
		font-size: 28px;
		margin-bottom: 1.5vw;
	}

	.job-wrap {
		.left {
			.sub-title {
				margin-bottom: 0;
			}
		}

		.right {
			.regular-text {
				color: $colorAccent2;
			}
		}

		@media (max-width: 599px) {
			flex-wrap: wrap;
			padding: 15px;

			.left, .right {
				width: 100%;
			}

			.left {
				padding-bottom: 25px;

				.sub-title {
					line-height: 1.3;
				}
			}

			.right {
				display: flex;
				justify-content: space-between;
			}
		}
	}

	&.item-half {
		display: flex;
		justify-content: space-between;
		align-items: stretch;

		flex-wrap: wrap;

		.title {
			width: 100%;
		}

		.job-wrap {
			width: calc(98% / 2);

			@media (max-width: 955px) {
				width: 100%;
			}

			&:hover {
				.arrow {
					background-color: #D31C1C;
				}
			}
		}
	}
}

.candidature-form {
	width: 100%;
	background-color: $colorAccent4;
	padding: 7vw 20vw;

	@media (max-width: 1255px) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.user-account-box {
		width: 100%;
		background-color: $colorAccent1;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 2vw;
		margin-bottom: 1.5vw;

		@media (max-width: 955px) {
			padding: 20px;
		}

		.left {
			width: 50%;

			.sub-title {
				font-size: 14px;
				color: $colorAccent4;
				margin-bottom: 20px;
			}

			.regular-text {
				color: $colorAccent2;
				text-align: left;

				@media (max-width: 1025px) {
					text-align: left;
					font-size: 18px;
					line-height: 1.2;
				}
			}
		}

		.right {
			width: 50%;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			@media (max-width: 955px) {
				align-items: flex-start;
				justify-content: flex-start;
			}

			@media (max-width: 599px) {
				flex-direction: column;
      }

			.cta-wrap {
				background-color: $colorAccent4;
				margin-left: 10px;

				@media (max-width: 955px) {
					margin-left: 0;
					margin-right: 10px;
				}

				@media (max-width: 599px) {
					width: 100%;
					margin: 5px 0;
        }

				&:hover {
					background-color: $colorAccent3;
				}
			}
		}

		@media (max-width: 955px) {
			flex-wrap: wrap;
			align-items: flex-start;
			text-align: left;

			.left, .right {
				width: 100%;
			}
		}

	}

	.user-profile-box {
		width: 100%;
		background: url('/img/site/icon-double-down.svg') center right+25px no-repeat;
		background-color: $colorAccent1;
		display: flex;
		justify-content: felx-start;
		align-items: center;
		padding: 2vw;
		margin-bottom: 1.5vw;

		color: $colorAccent2;

		@media (max-width: 599px) {
			padding: 10px;
			background: $colorAccent1;
		}

		img {
			display: block;
			width: 120px;

			@media (max-width: 599px) {
				width: 88px;
			}
		}

		p {
			padding-left: 25px;
			font-family: $fontLight;
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 1.5;
			letter-spacing: 0.05em;

			@media (max-width: 599px) {
				font-size: 14px;
				line-height: 1.1;
				margin-bottom: 5px;
			}

			strong {
				display: block;
				font-family: $fontSemi;
				text-transform: uppercase;
				font-size: 20px;

				@media (max-width: 599px) {
					font-size: 18px;
					padding-bottom: 5px;
				}
			}
		}

	}

	form {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.title {
		width: 100%;
		font-size: 28px;
		margin-bottom: 1vw;
		color: $colorAccent2;
		text-align: center;

		@media (max-width: 955px) {
			margin-bottom: 25px;
		}

		@media (max-width: 599px) {
			margin-bottom: 10px;
	  }
	}

	.regular-text {
		width: 100%;
		font-size: 22px;
		margin-bottom: 2.5vw;
		color: $colorAccent2;
		text-align: center;

		@media (max-width: 599px) {
			font-size: 18px;
			line-height: 1.2;
			margin-bottom: 25px;
		}
	}

	.third {
		width: calc(33.33% - 5px);
	}

	.half {
		width: calc(50% - 5px);
	}

	.half-file {
		@media (max-width: 955px) {
			width: 100%;
		}
	}

	.quarter {
		width: calc(25% - 5px);
	}

	textarea {
		margin-bottom: 10px;
	}

	.submit-wrap {
		width: 100%;
		margin-top: 2.5vw;
		display: flex;
		justify-content: center;
	}
}
