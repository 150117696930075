.title {
	font-family: $fontBlack;
	color: $colorAccent1;
	text-transform: uppercase;
}

.sub-title {
	font-family: $fontSemi;
	color: $colorAccent1;
	text-transform: uppercase;
}

.sup-title {
	font-family: $fontSemi;
	color: $colorAccent1;
	text-transform: uppercase;
}

.regular-text {
	font-family: $fontRegular;
	color: $colorAccent1;
	line-height: 1.5;
}
