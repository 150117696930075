.max-width-padding {
  padding: 0 10vw 0 10vw;

  @media (max-width: 1255px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.default-block {
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 0 10vw 0 10vw;

  @media (max-width: 1255px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.no-padding {
    padding: 0;
  }
}

.home-hero {
  position: relative;
  width: 100%;
  margin-top: 75px;
  overflow: hidden;

  @media (max-width: 1024px) {
    margin-top: 50px;
  }

  .left {
    width: 50%;
    height: auto;
    position: relative;

    .main-carousel {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      background: $colorAccent1;

      .carousel-cell {
        width: 100%;
        height: 100vh;
        background-size: cover;
        background-position: center center;

        img {
          display: block;
          width: 100%;
          height: 100vh;
          object-fit: cover;
        }
      }

      .flickity-page-dots {
        bottom: 2vw;

        .dot {
          width: 20px;
          height: 20px;
          opacity: 1;
          background-color: transparent;
          border: 4px solid $colorAccent2;
          transition: all 0.2s ease;
          z-index: 200;

          @media (max-width: 599px) {
            width: 13px;
            height: 13px;
            border: 2px solid $colorAccent2;
            margin: 0 4px;
          }

          &.is-selected {
            background-color: $colorAccent2;
          }
        }
      }
    }
  }

  .right {
    width: 50%;
    height: 100%;
    background-color: $colorAccent1;

    .top {
      width: 100%;
      height: 45%;
      padding: 6vw 6vw;

      @media (max-width: 1024px) {
        padding-left: 25px;
        padding-right: 25px;
      }

      .home-title {
        font-family: $fontBlack;
        font-style: normal;
        font-weight: 900;
        font-size: 52px;
        line-height: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $colorAccent2;

        @media (max-width: 1255px) {
          font-size: 44px;
        }

        @media (max-width: 599px) {
          font-size: 32px;
        }
      }

      .ctas {
        display: flex;
        margin-top: 25px;

        @media (max-width: 599px) {
          flex-direction: column;
        }

        .cta-wrap {
          margin-right: 5%;

          @media (max-width: 599px) {
            width: 100%;
            margin: 0;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .sub-title {
            font-size: 13px;
          }
        }
      }
    }

    .bottom {
      position: relative;
      width: 100%;
      height: 55%;

      img {
        display: block;
        width: 100%;
      }

      .placeholder {
        opacity: 0;
      }

      .bottom-img {
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;

        transition: opacity 0.55s ease 0s;

        &.is-active {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;

    .left, .right {
      width: 100%;
      height: auto;

      .bottom, .top {
        height: auto;
      }

      .main-carousel {
        position: relative;

        .carousel-cell {
          height: 80vw;
        }
      }
    }
  }
}

body .filters-bar-wrapper {
  background-color: $colorAccent1;

  .title {
    color: $colorAccent2;
    margin-bottom: 1vw;
  }

  .select-radio-wrap {
    margin-bottom: 0;
    align-items: stretch;
    flex-wrap: nowrap;

    @media (max-width: 955px) {
      flex-wrap: wrap;
    }

    .select {
      margin: 0;
      background-color: $colorAccent1;
      border-color: $colorAccent2;
      color: $colorAccent2;
      background-image: url(/img/site/arrow-down-light.svg);
      
      &:first-child {
        border-left-color: $colorAccent2;
      }

      @media (max-width: 955px) {
				width: 100%;
        margin-bottom: 10px;
			}
    }

    .filters-button {
			background-image: url(/img/site/slider-arrow-right.jpg);
    }
  }
}

.scroll-fix {
  position: relative;
  width: 100%;

  .fixed-floating-image {
    position: absolute;
    top: 0;
    right: 0;

    width: 50%;
    height: 100vh;

    background: $colorAccent2;

    z-index: 9;

    @media (max-width: 1024px) {
      display: none;
    }

    .cta-wrap {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 5;
    }

    .float-img {
      display: block;
      width: 100%;
      height: 100vh;
      object-fit: cover;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      object-fit: cover;

      opacity: 0;

      transition: opacity 0.65s ease 0s;

      &.is-active {
        opacity: 1;
      }
    }
  }
}

.split {
  align-items: stretch;
  height: 100vh;

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 50%;
    height: 100%;
    padding: 10vw 10vw;

    @media (max-width: 1255px) {
      padding-left: 25px;
      padding-right: 25px;
    }

    .split-title {
      font-family: $fontBlack;
      font-style: normal;
      font-weight: 900;
      font-size: 46px;
      line-height: 100%;
      /* or 55px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Bleu */
      color: $colorAccent1;

      margin: 0;
      padding: 0;
      margin-bottom: 40px;

      @media (max-width: 1255px) {
        font-size: 35px;
      }

      @media (max-width: 599px) {
        font-size: 32px;
      }
    }

    .split-subtitle {
      font-family: $fontSemi;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Bleu */
      color: $colorAccent1;

      margin: 0;
      padding: 0;
      margin-bottom: 20px;

      @media (max-width: 1255px) {
        font-size: 22px;
        line-height: 1.3;
      }

      @media (max-width: 599px) {
        font-size: 20px;
      }
    }

    .split-text {
      font-family: $fontRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* or 30px */

      letter-spacing: 0.05em;

      /* Bleu */
      color: $colorAccent1;

      margin: 0;
      padding: 0;
      margin-bottom: 35px;
    }
  }

  .right {
    position: relative;
    width: 50%;
    height: 100%;

    .cta-wrap {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    height: auto;

    .left, .right {
      width: 100%;
      height: auto;
    }

    .right img {
      display: block;
      width: 100%;
      max-height: 60vw;
      object-fit: cover;
    }
  }
}

.stats {
  .row {
    display: flex;
    align-items: center;

    padding: 10px 0;

    span {
      min-width: 115px;
      margin: 0;
      padding: 0;

      font-family: $fontBlack;
      font-style: normal;
      font-weight: 900;
      font-size: 52px;
      line-height: 100%;
      /* identical to box height, or 65px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Bleu */

      color: $colorAccent1;

      @media (max-width: 599px) {
        font-size: 42px;
        min-width: 97px;
      }
    }

    p {
      margin: 0;
      padding: 0;

      font-family: $fontSemi;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Bleu */

      color: $colorAccent1;
    }
  }
}

.marquee {
  position: relative;
  overflow: hidden;

  padding: 6vw 0 0 0;
  background: $colorAccent1;

  font-family: $fontLight;
  font-style: normal;
  font-weight: 100;
  font-size: 100px;
  line-height: 100%;
  /* identical to box height, or 150px */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* Rouge */
  color: $colorAccent2;

  @media (max-width: 1024px) {
    font-size: 77px;
  }

  @media (max-width: 599px) {
    font-size: 56px;
  }

  .marquee-slide {
    display: flex;

    animation: infinityLeft 300s linear infinite;
  }

  strong {
    color: $colorAccent3;
  }

  span {
    display: inline-block;
    padding-right: 45px;
  }
}

.carrieres {
  position: relative;
  width: 100%;
  padding: 6vw 0 0 10vw;
  background: $colorAccent1;

  @media (max-width: 1255px) {
    padding-left: 20px;
  }

  .carrieres-title {
    font-family: $fontBlack;
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 100%;
    /* or 45px */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* Beige */
    color: $colorAccent2;
    margin-bottom: 45px;

    @media (max-width: 599px) {
      font-size: 23px;
    }
  }

  .headings {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 12.5vw;
  }

  .controls {
    display: flex;

    @media (max-width: 767px) {
      display: none;
    }

    .control {
      width: 55px;
      margin-left: 15px;
      cursor: pointer;

      rect {
        fill: $colorAccent2;
        transition: fill 0.5s ease 0s;
      }

      &:hover {
        rect {
          fill: $colorAccent3;
        }
      }
    }
  }

  .listing {
    position: relative;
    overflow: hidden;

    .thumb-ph {
      width: 400px;
      height: 1px;

      @media (max-width: 599px) {
        display: none;
      }
    }

    .thumb {
      position: relative;
      display: block;
      width: calc(100vw / 4);
      margin-right: 20px;
      border: 1px solid $colorAccent2;

      transition: none;

      @media (max-width: 1255px) {
        width: calc(100vw / 2.65);
      }

      @media (max-width: 1024px) {
        width: calc(100vw / 1.95);
      }

      @media (max-width: 599px) {
        width: 330px;
        margin-right: 10px;
      }

      .img-wrap {
        position: relative;
        overflow: hidden;
        border-bottom: 1px solid $colorAccent2;
      }

      img {
        display: block;
        width: 100%;

        transform: none;
        backface-visibility: hidden;
        filter: none;
        transition: all 4.5s ease;
      }

      .content {
        padding: 20px;
      }

      transition: all 0.2s ease;

      &:hover {
        background-color: rgba(#fff, 0.1275);

        img {
          transform: scale(1.175);
        }
      }

      .thumb-title {
        font-family: $fontSemi;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Vert */
        color: $colorAccent4;

      }

      .infos {
        display: flex;
        align-items: center;

        margin-top: 20px;

        .col {
          width: 50%;
          text-align: center;
          border: 1px solid $colorAccent2;
          padding: 10px 5px;

          font-family: $fontSemi;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.05em;
          text-transform: uppercase;

          /* Beige */
          color: $colorAccent2;

          &:first-child {
            border-right: none;
          }

          @media (max-width: 1255px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.emplois {
  position: relative;
  width: 100%;
  padding: 8vw 0;
  background: $colorAccent1;

  display: flex;
  align-items: center;

  .left {
    width: 50%;

    img {
      display: block;
      width: 100%;
    }
  }

  .right {
    width: 50%;
    padding: 0 10vw 0 6vw;

    @media (max-width: 1255px) {
      padding-right: 20px;
    }

    .emplois-title {
      font-family: $fontBlack;
      font-style: normal;
      font-weight: 900;
      font-size: 38px;
      line-height: 100%;
      /* or 45px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Beige */
      color: $colorAccent2;
      margin-bottom: 35px;

      @media (max-width: 1255px) {
        font-size: 35px;
      }

      @media (max-width: 599px) {
        font-size: 24px;
      }
    }

    .emplois-text {
      font-family: $fontRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* or 30px */

      letter-spacing: 0.05em;

      /* Bleu */
      color: $colorAccent2;

      margin: 0;
      padding: 0;
      margin-bottom: 35px;
    }

    .avantages {
      .avantage {
        width: 100%;
        transition: all 0.2s ease;

        &:hover {
          background-color: rgba(#fff, 0.1275);
        }
      }
    }
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;

    .left, .right {
      width: 100%;
    }

    .right {
      padding-top: 10vw;
      padding-bottom: 10vw;
    }

    .left img {
      display: block;
      width: 100%;
      max-height: 60vw;
      object-fit: cover;
    }
  }

  @media (max-width: 599px) {
    .right {
      padding-bottom: 0;
    }
  }

  .avantages {
    margin-top: 2.5vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .sub-title {
      width: 100%;
      font-size: 18px;
      color: $colorAccent2;
      margin-bottom: 1.5vw;
    }

    .avantage {
      position: relative;
      width: calc(33.33% - 10px);
      display: flex;
      align-items: center;
      padding: 20px;
      border: 2px solid $colorAccent2;
      margin-bottom: 20px;

      img {
        width: 25px;
        margin-right: 15px;
      }

      .sub-title {
        font-size: 14px;
        color: $colorAccent2;
        margin-bottom: 0;
      }

      .click-icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
        width: 18px;
      }
    }
  }
}

.profile {
  position: relative;
  width: 100%;
  padding: 10vw 0;
  background: $colorAccent4;

  display: flex;
  align-items: center;

  .left {
    width: 50%;
    padding: 0 10vw;

    @media (max-width: 1255px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 599px) {
      padding-top: 25px;
    }

    .profile-title {
      font-family: $fontBlack;
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 100%;
      /* or 45px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Beige */
      color: $colorAccent2;

      @media (max-width: 599px) {
        font-size: 28px;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    width: 50%;

    img {
      display: block;
      width: 100%;
      margin-bottom: -8vw;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .left, .right {
      width: 100%;
    }

    .right {
      align-items: flex-end;
      justify-content: flex-end;
    }

    .left {
      padding-top: 10vw;
      padding-bottom: 10vw;
    }

    .right img {
      display: inline-block;
      width: 80%;
    }
  }

  @media (max-width: 599px) {
    .left {
      padding-top: 0;
    }

    .right {
      display: none;
    }
  }
}

.arguments {
  padding-top: 45px;

  .row {
    position: relative;
    padding-left: 55px;
    margin-bottom: 45px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    strong {
      font-family: $fontSemi;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Beige */
      color: $colorAccent2;
    }

    p {
      font-family: $fontRegular;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 30px */

      letter-spacing: 0.05em;

      /* Beige */
      color: $colorAccent2;
    }
  }
}

@keyframes infinityLeft {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-500%, 0, 0);
    }
}
