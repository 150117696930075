.main-logo {
	width: 250px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 510;

	@media (max-width: 1255px) {
		width: 215px;
	}

	@media (max-width: 699px) {
		width: 145px;
	}
}

.main-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: fixed;
	top: 46px;
	left: 0;
	width: 100%;
	background-color: $colorAccent2;
	z-index: 500;

  transition: all 0.2s ease;

  &.is-scrolled {
    top: 0;
  }

	@media (max-width: 1255px) {

	}

	.left {
		display: flex;
		align-items: center;
    margin-right: auto;

		@media (max-width: 699px) {
			width: 100%;
		}

		.small-logo {
			width: 250px;

			@media (max-width: 1255px) {
				width: 215px;
			}

			@media (max-width: 699px) {
				width: 145px;
			}
		}

		nav {
			margin-left: 2vw;

			@media (max-width: 1255px) {
				margin-left: 5px;
			}

			@media (max-width: 1024px) {
				display: none;
			}

			a {
				font-family: $fontBlack;
				text-transform: uppercase;
				color: $colorAccent1;
				text-decoration: none;
				font-size: 16px;
				margin-left: 1.5vw;
				transition: all 0.2s ease;

				&:hover,
				&.is-active {
					color: $colorAccent3;
				}
			}
		}
	}

  .header-lang {
    font-family: $fontBlack;
    text-transform: uppercase;
    color: $colorAccent1;
    font-size: 16px;
    transition: all 0.2s ease;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 2px solid $colorAccent1;

    @media (max-width: 1024px) {
      margin-right: 10px;
      border-right: none;
    }
  }

	.right {
		display: flex;
    align-items: center;

		@media (max-width: 699px) {
			display: none;
		}

		.connexion {
			display: flex;
			align-items: center;

			p {
				font-family: $fontBlack;
				text-transform: uppercase;
				color: $colorAccent1;
				font-size: 16px;
				transition: all 0.2s ease;
			}

			img {
				width: 25px;
				margin-right: 10px;
			}

			&:hover {
				p {
					color: $colorAccent3;
				}
			}
		}

		.main-cta {
			height: 78px;
			padding: 0 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: $colorAccent4;
			margin-left: 2vw;
			transition: all 0.2s ease;

			@media (max-width: 1024px) {
				height: 67px;
			}

			p {
				font-family: $fontBlack;
				text-transform: uppercase;
				color: $colorAccent2;
				font-size: 16px;
				margin-right: 15px;
			}

			img {
				width: 18px;
			}

			&:hover {
				background-color: $colorAccent3;
			}
		}
	}
}

.mobile-menu-trigger {
  display: none;
  width: 30px;
  height: 30px;
  margin-left: 25px;
  cursor: pointer;

  fill: $colorAccent1;

  @media (max-width: 1024px) {
    display: block;
  }

  @media (max-width: 699px) {
  	margin-left: auto;
  	margin-right: 20px;
  }
}

/*! Mobile Nav
---------------------------------------------- */
.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  transform: translateX(400px);
  transition: all 0.3s ease-in-out;
  background-color: #fff;

  @media all and (max-width: 1140px) {
    width: 400px;
    height: 100vh;
  }

  @media all and (max-width: 499px) {
    width: 85%;
  }

  &.is-active {
    @media all and (max-width: 1140px) {
      display: block;
      transform: translateX(0px);
    }
  }

  &.active {
    @media all and (max-width: 1140px) {
      width: 400px;
      display: flex;
    }

    @media all and (max-width: 499px) {
      width: 85%;
    }
  }

  .navigation-header {
    display: flex;
    align-items: center;
    padding: 9px 20px;
    background: #fff;

    .logo {
      height: auto;
      margin-right: auto;
      text-align: left;

      display: flex;
      justify-content: flex-start;

      img {
      	display: inline-block;
      	height: 60px;
      }
    }
  }

  .navigation-close {
    flex-grow: 1;
    text-align: right;
    font-size: 28px;
    color: $colorAccent1;
  }

  .navigation-link {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 1px solid rgba(#000, 0.075);

    a {
    	font-family: $fontSemi;
      margin-bottom: 10px;
      color: $colorAccent1;
      font-size: 16px;
      text-align: center;
      line-height: 1;
      background-color: $colorAccent2;
      padding: 15px;
      border-radius:3px;

      &:last-child {
        margin-bottom: 0;
      }

      &.is-active {
        background: $colorAccent1;
        color: #fff;
      }
    }
  }

  .header-license-img {
    display: block;
    width: 100%;
    max-width: 80px;
    margin: 25px auto 0 auto;
  }

  .navigation-sublink {
    display: flex;
    flex-direction: column;
    padding: 0 20px 15px;
    border-bottom: 1px solid rgba(#000, 0.075);
    text-align: center;

    a {
    	font-family: $fontSemi;
      font-size: 14px;
      color: $colorAccent1;
      text-transform: uppercase;
      text-align: center;

      &.site-btn {
        color: #fff;
        margin-bottom: 10px;

        &:hover {
          color: $colorAccent1;
        }
      }
    }

    .connexion {
			display: flex;
			align-items: center;
			padding: 15px;
			margin-bottom: 5px;

			p {
				font-family: $fontBlack;
				text-transform: uppercase;
				color: $colorAccent1;
				font-size: 16px;
				transition: all 0.2s ease;
			}

			img {
				width: 25px;
				margin-right: 10px;
			}

			&:hover {
				p {
					color: $colorAccent3;
				}
			}
		}

		.main-cta {
			height: auto;
			padding: 15px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: $colorAccent4;
			margin-left: 0;
			margin-bottom: 10px;
			transition: all 0.2s ease;

			p {
				font-family: $fontBlack;
				text-transform: uppercase;
				color: $colorAccent2;
				font-size: 16px;
				margin-right: 15px;
			}

			img {
				width: 18px;
			}

			&:hover {
				background-color: $colorAccent3;
			}
		}
  }

  .navigation-footer {
    display: flex;
    align-items: center;
    padding: 20px 0;

    a {
    	font-family: $fontSemi;
      font-size: 13px;
      padding-left: 20px;
      color: $colorAccent1;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
      }
    }

    form {
      width: 100%;
      padding: 0 20px;

      input {
        width: 100%;
        height: 35px;
        padding: 5px 10px;
        font-size: 14px;
        color: rgba($colorAccent1, 0.7523);
        border: 1px solid rgba($colorAccent1, 0.123);
      }
    }
  }

  .navigation-footer-social {
    flex-grow: 1;

    a {
      padding: 0;
    }

    svg {
      margin-right: 20px;
      height: 24px;
      transition: all .3s ease-in-out;
    }
  }

  .subnav-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;

    &.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }

    a {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}

.navigation-mobile-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background: rgba($colorAccent1, 0.70);

  opacity: 0;

  &.is-active {
    opacity: 1;
    z-index: 15;
  }
}
