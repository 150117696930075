.cta-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	background-color: $colorAccent1;
	width: fit-content;
	transition: all 0.2s ease;

	&:hover {
		background-color: $colorAccent3;
	}

	.sub-title {
		font-size: 14px;
		color: $colorAccent2;
		padding-right: 20px;
	}

	img {
		width: 15px;
	}

	&.is-left {
		.sub-title {
			padding-left: 20px;
			padding-right: 0;
		}
	}

	&.is-outlined {
		border: 2px solid $colorAccent2;

		&:hover {
			background-color: $colorAccent4;
			border-color: $colorAccent4;
		}

		svg {
			fill: $colorAccent2;
			width: 17px;
		}
	}

	&.is-light {
		background-color: $colorAccent2;

		&:hover {
			background-color: $colorAccent4;
		}

		.sub-title {
			color: $colorAccent1;
		}

		svg {
			fill: $colorAccent1;
			width: 17px;
		}
	}

	&.is-red {
		background-color: $colorAccent3;

		&:hover {
			background-color: $colorAccent4;
		}

		.sub-title {
			color: $colorAccent2;
		}

		svg {
			fill: $colorAccent2;
			width: 17px;
		}
	}

	&.is-light-blue {
		background-color: $colorAccent4;

		&:hover {
			background-color: $colorAccent3;
		}

		.sub-title {
			color: $colorAccent2;
		}

		svg {
			fill: $colorAccent2;
			width: 17px;
		}
	}
}
