body {
	background-color: #F2EEDF;
	letter-spacing: 1px;
}
body.scroll {
		overflow: hidden;
	}
* { -webkit-box-sizing: border-box; box-sizing: border-box; }
img {
	width: 100%;
	height: auto;
	display: inherit;
}
button {
	border:0;
	background-color: transparent;
	letter-spacing: 1px;
}
a {
	text-decoration: none;
}
::-moz-selection {
  background-color: #63B3C1;
  color: #fff;
}
::selection {
  background-color: #63B3C1;
  color: #fff;
}
::-moz-selection {
  background-color: #63B3C1;
  color: #fff;
}
/*! Lazyload
---------------------------------------------- */
/*
img {
  opacity: 0;
  transition: opacity 0.45s ease 0.45s, padding 0s linear 0s, height 0s linear 0s;

  height: 0;
  width: 100%;
  padding-bottom: 56.25%;

  &.lazyloaded,
  &.flickity-lazyloaded {
    opacity: 1;
    height: auto;
    padding-bottom: 0;
  }

  &.force-lazyload {
    opacity: 1;
  }
}

.main-carousel .flickity-slider {
  img {
    opacity: 1;
  }
}

#map {
  img {
    opacity: 1;
    transition: none;
  }
}
*/
.title {
	font-family: 'alvar_black', 'Arial', 'sans-serif';
	color: #212C47;
	text-transform: uppercase;
}
.sub-title {
	font-family: 'alvar_semibold', 'Arial', 'sans-serif';
	color: #212C47;
	text-transform: uppercase;
}
.sup-title {
	font-family: 'alvar_semibold', 'Arial', 'sans-serif';
	color: #212C47;
	text-transform: uppercase;
}
.regular-text {
	font-family: 'alvar_regular', 'Arial', 'sans-serif';
	color: #212C47;
	line-height: 1.5;
}
.cta-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	padding: 20px;
	background-color: #212C47;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	-webkit-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.cta-wrap:hover {
		background-color: #D31C1C;
	}
.cta-wrap .sub-title {
		font-size: 14px;
		color: #F2EEDF;
		padding-right: 20px;
	}
.cta-wrap img {
		width: 15px;
	}
.cta-wrap.is-left .sub-title {
			padding-left: 20px;
			padding-right: 0;
		}
.cta-wrap.is-outlined {
		border: 2px solid #F2EEDF;
	}
.cta-wrap.is-outlined:hover {
			background-color: #63B3C1;
			border-color: #63B3C1;
		}
.cta-wrap.is-outlined svg {
			fill: #F2EEDF;
			width: 17px;
		}
.cta-wrap.is-light {
		background-color: #F2EEDF;
	}
.cta-wrap.is-light:hover {
			background-color: #63B3C1;
		}
.cta-wrap.is-light .sub-title {
			color: #212C47;
		}
.cta-wrap.is-light svg {
			fill: #212C47;
			width: 17px;
		}
.cta-wrap.is-red {
		background-color: #D31C1C;
	}
.cta-wrap.is-red:hover {
			background-color: #63B3C1;
		}
.cta-wrap.is-red .sub-title {
			color: #F2EEDF;
		}
.cta-wrap.is-red svg {
			fill: #F2EEDF;
			width: 17px;
		}
.cta-wrap.is-light-blue {
		background-color: #63B3C1;
	}
.cta-wrap.is-light-blue:hover {
			background-color: #D31C1C;
		}
.cta-wrap.is-light-blue .sub-title {
			color: #F2EEDF;
		}
.cta-wrap.is-light-blue svg {
			fill: #F2EEDF;
			width: 17px;
		}
input[type="text"],
input[type="email"],
input[type="password"], select {
	width: 100%;
	border: 0;
	background-color: #fff;
	padding: 20px;
	margin-bottom: 10px;
	font-family: 'alvar_regular', 'Arial', 'sans-serif';
	font-size: 14px;
	color: #212C47;
	letter-spacing: 1px;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
}
@media (max-width: 599px) {
input[type="text"],
input[type="email"],
input[type="password"], select {
		padding: 15px;
		font-size: 13px
}
	}
select {
	-webkit-appearance: none;
	background-image: url("/img/site/arrow-down.svg");
	background-repeat: no-repeat;
	background-size: 20px;
	background-position: calc(100% - 20px) center;
}
::-webkit-input-placeholder {
		font-family: 'alvar_regular', 'Arial', 'sans-serif';
		color: #212C47;
		font-size: 14px;
		letter-spacing: 1px;
	}
::-moz-placeholder {
		font-family: 'alvar_regular', 'Arial', 'sans-serif';
		color: #212C47;
		font-size: 14px;
		letter-spacing: 1px;
	}
:-ms-input-placeholder {
		font-family: 'alvar_regular', 'Arial', 'sans-serif';
		color: #212C47;
		font-size: 14px;
		letter-spacing: 1px;
	}
::-ms-input-placeholder {
		font-family: 'alvar_regular', 'Arial', 'sans-serif';
		color: #212C47;
		font-size: 14px;
		letter-spacing: 1px;
	}
::placeholder {
		font-family: 'alvar_regular', 'Arial', 'sans-serif';
		color: #212C47;
		font-size: 14px;
		letter-spacing: 1px;
	}
input[type="submit"] {
	border: 0;
	background-color: #212C47;
	color: #F2EEDF;
	font-family: 'alvar_semibold', 'Arial', 'sans-serif';
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
	padding: 20px 60px 20px 20px;
	cursor: pointer;
	background-image: url("/img/site/work-icon.svg");
	background-repeat: no-repeat;
	background-size: 15px;
	background-position: calc(100% - 20px) center;
	-webkit-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
input[type="submit"]:hover {
		background-color: #D31C1C;
	}
input[type="file"] {
	width: 100%;
	position: relative;
	z-index: 100;
	padding-left: 80px;
}
textarea {
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	height: 200px;
	min-height: 200px;
	border: 0;
	background-color: #fff;
	padding: 20px;
	font-family: 'alvar_regular', 'Arial', 'sans-serif';
	font-size: 14px;
	letter-spacing: 1px;
}
@media (max-width: 599px) {
textarea {
		padding: 15px
}
	}
.input-wrap {
	width: 100%;
	background-color: #fff;
	padding: 20px;
	margin-bottom: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	position: relative;
}
.input-wrap .regular-text {
		font-size: 14px;
		color: #212C47;
	}
.input-wrap .plus {
		width: 25px;
		position: absolute;
		right: 20px;
		top: 17px;
		z-index: 1;
	}
.radio-btns {
	margin-top: 20px;
}
@media (max-width: 767px) {
.radio-btns {
		margin-top: 0
}
	}
.radio-btns.negate-margin {
		margin-top: 0;
	}
.radio-btns label {
		font-family: 'alvar_regular', 'Arial', 'sans-serif';
		font-size: 14px;
		color: #212C47;
		letter-spacing: 1px;
		line-height: 1;
	}
@media (max-width: 767px) {
.radio-btns label {
			font-size: 13px
	}
		}
.radio-btns .radios-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
	}
.radio-btns .radios-wrap .radio-input {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: start;
			    -ms-flex-align: start;
			        align-items: start;
			padding-left: 15px;
			cursor: pointer;
		}
@media (max-width: 767px) {
.radio-btns .radios-wrap .radio-input {
				padding-left: 10px
		}
			}
.radio-btns .radios-wrap .radio-input input {
				position: relative;
				top: -1px;
				margin: 0;
				padding: 0;
				margin-right: 5px;
				cursor: pointer;
			}
@media (max-width: 767px) {
.radio-btns .radios-wrap .radio-input input {
					margin-right: 3px
			}
				}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	        transform: translateY(-50%);

  font-family: 'alvar_regular', 'Arial', 'sans-serif';
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input.logo::before {
	content: 'Logo';
}
.custom-file-input.photo::before {
	content: 'Photo';
}
.custom-file-input.cv::before {
	content: 'Curriculum vitae';
}
.custom-file-input.lettre::before {
	content: 'Lettre';
}
.custom-file-input.lettre.letter::before {
	content: 'Cover letter';
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 25px;
  cursor: pointer;;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #F2EEDF;
  border: 3px solid #212C47;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #D31C1C;
  border: 3px solid #D31C1C;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 7px;
  border: solid #F2EEDF;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.regular-text.placeholder-label,
.regular-text.placeholder-label a {
	color: #212C47;
}
.regular-text.placeholder-label strong, .regular-text.placeholder-label a strong {
		font-family: 'alvar_semibold', 'Arial', 'sans-serif';
		display: inline-block;
		color: #D31C1C;
		font-weight: 800;
		padding-right: 5px;
	}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
}
.main-logo {
	width: 250px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 510;
}
@media (max-width: 1255px) {
.main-logo {
		width: 215px
}
	}
@media (max-width: 699px) {
.main-logo {
		width: 145px
}
	}
.main-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	    -ms-flex-pack: end;
	        justify-content: flex-end;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	position: fixed;
	top: 46px;
	left: 0;
	width: 100%;
	background-color: #F2EEDF;
	z-index: 500;

  -webkit-transition: all 0.2s ease;

  -o-transition: all 0.2s ease;

  transition: all 0.2s ease;
}
.main-header.is-scrolled {
    top: 0;
  }
@media (max-width: 1255px) {

	}
.main-header .left {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
    margin-right: auto;
	}
@media (max-width: 699px) {
.main-header .left {
			width: 100%
	}
		}
.main-header .left .small-logo {
			width: 250px;
		}
@media (max-width: 1255px) {
.main-header .left .small-logo {
				width: 215px
		}
			}
@media (max-width: 699px) {
.main-header .left .small-logo {
				width: 145px
		}
			}
.main-header .left nav {
			margin-left: 2vw;
		}
@media (max-width: 1255px) {
.main-header .left nav {
				margin-left: 5px
		}
			}
@media (max-width: 1024px) {
.main-header .left nav {
				display: none
		}
			}
.main-header .left nav a {
				font-family: 'alvar_black', 'Arial', 'sans-serif';
				text-transform: uppercase;
				color: #212C47;
				text-decoration: none;
				font-size: 16px;
				margin-left: 1.5vw;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
.main-header .left nav a:hover,
				.main-header .left nav a.is-active {
					color: #D31C1C;
				}
.main-header .header-lang {
    font-family: 'alvar_black', 'Arial', 'sans-serif';
    text-transform: uppercase;
    color: #212C47;
    font-size: 16px;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 2px solid #212C47;
  }
@media (max-width: 1024px) {
.main-header .header-lang {
      margin-right: 10px;
      border-right: none
  }
    }
.main-header .right {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
	}
@media (max-width: 699px) {
.main-header .right {
			display: none
	}
		}
.main-header .right .connexion {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
		}
.main-header .right .connexion p {
				font-family: 'alvar_black', 'Arial', 'sans-serif';
				text-transform: uppercase;
				color: #212C47;
				font-size: 16px;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
.main-header .right .connexion img {
				width: 25px;
				margin-right: 10px;
			}
.main-header .right .connexion:hover p {
					color: #D31C1C;
				}
.main-header .right .main-cta {
			height: 78px;
			padding: 0 20px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			background-color: #63B3C1;
			margin-left: 2vw;
			-webkit-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
@media (max-width: 1024px) {
.main-header .right .main-cta {
				height: 67px
		}
			}
.main-header .right .main-cta p {
				font-family: 'alvar_black', 'Arial', 'sans-serif';
				text-transform: uppercase;
				color: #F2EEDF;
				font-size: 16px;
				margin-right: 15px;
			}
.main-header .right .main-cta img {
				width: 18px;
			}
.main-header .right .main-cta:hover {
				background-color: #D31C1C;
			}
.mobile-menu-trigger {
  display: none;
  width: 30px;
  height: 30px;
  margin-left: 25px;
  cursor: pointer;

  fill: #212C47;
}
@media (max-width: 1024px) {
.mobile-menu-trigger {
    display: block
}
  }
@media (max-width: 699px) {
.mobile-menu-trigger {
  	margin-left: auto;
  	margin-right: 20px
}
  }
/*! Mobile Nav
---------------------------------------------- */
.navigation-mobile {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100000000;
  -webkit-transform: translateX(400px);
      -ms-transform: translateX(400px);
          transform: translateX(400px);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
}
@media all and (max-width: 1140px) {
.navigation-mobile {
    width: 400px;
    height: 100vh
}
  }
@media all and (max-width: 499px) {
.navigation-mobile {
    width: 85%
}
  }
@media all and (max-width: 1140px) {
.navigation-mobile.is-active {
      display: block;
      -webkit-transform: translateX(0px);
          -ms-transform: translateX(0px);
              transform: translateX(0px)
  }
    }
@media all and (max-width: 1140px) {
.navigation-mobile.active {
      width: 400px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex
  }
    }
@media all and (max-width: 499px) {
.navigation-mobile.active {
      width: 85%
  }
    }
.navigation-mobile .navigation-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 9px 20px;
    background: #fff;
  }
.navigation-mobile .navigation-header .logo {
      height: auto;
      margin-right: auto;
      text-align: left;

      display: -webkit-box;

      display: -ms-flexbox;

      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
    }
.navigation-mobile .navigation-header .logo img {
      	display: inline-block;
      	height: 60px;
      }
.navigation-mobile .navigation-close {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    text-align: right;
    font-size: 28px;
    color: #212C47;
  }
.navigation-mobile .navigation-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px;
    border-top: 1px solid rgba(0,0,0, 0.075);
  }
.navigation-mobile .navigation-link a {
    	font-family: 'alvar_semibold', 'Arial', 'sans-serif';
      margin-bottom: 10px;
      color: #212C47;
      font-size: 16px;
      text-align: center;
      line-height: 1;
      background-color: #F2EEDF;
      padding: 15px;
      border-radius:3px;
    }
.navigation-mobile .navigation-link a:last-child {
        margin-bottom: 0;
      }
.navigation-mobile .navigation-link a.is-active {
        background: #212C47;
        color: #fff;
      }
.navigation-mobile .header-license-img {
    display: block;
    width: 100%;
    max-width: 80px;
    margin: 25px auto 0 auto;
  }
.navigation-mobile .navigation-sublink {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0 20px 15px;
    border-bottom: 1px solid rgba(0,0,0, 0.075);
    text-align: center;
  }
.navigation-mobile .navigation-sublink a {
    	font-family: 'alvar_semibold', 'Arial', 'sans-serif';
      font-size: 14px;
      color: #212C47;
      text-transform: uppercase;
      text-align: center;
    }
.navigation-mobile .navigation-sublink a.site-btn {
        color: #fff;
        margin-bottom: 10px;
      }
.navigation-mobile .navigation-sublink a.site-btn:hover {
          color: #212C47;
        }
.navigation-mobile .navigation-sublink .connexion {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			padding: 15px;
			margin-bottom: 5px;
		}
.navigation-mobile .navigation-sublink .connexion p {
				font-family: 'alvar_black', 'Arial', 'sans-serif';
				text-transform: uppercase;
				color: #212C47;
				font-size: 16px;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
.navigation-mobile .navigation-sublink .connexion img {
				width: 25px;
				margin-right: 10px;
			}
.navigation-mobile .navigation-sublink .connexion:hover p {
					color: #D31C1C;
				}
.navigation-mobile .navigation-sublink .main-cta {
			height: auto;
			padding: 15px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			background-color: #63B3C1;
			margin-left: 0;
			margin-bottom: 10px;
			-webkit-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
.navigation-mobile .navigation-sublink .main-cta p {
				font-family: 'alvar_black', 'Arial', 'sans-serif';
				text-transform: uppercase;
				color: #F2EEDF;
				font-size: 16px;
				margin-right: 15px;
			}
.navigation-mobile .navigation-sublink .main-cta img {
				width: 18px;
			}
.navigation-mobile .navigation-sublink .main-cta:hover {
				background-color: #D31C1C;
			}
.navigation-mobile .navigation-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 0;
  }
.navigation-mobile .navigation-footer a {
    	font-family: 'alvar_semibold', 'Arial', 'sans-serif';
      font-size: 13px;
      padding-left: 20px;
      color: #212C47;
    }
.navigation-mobile .navigation-footer a i {
        position: relative;
        top: 1px;
        font-size: 16px;
      }
.navigation-mobile .navigation-footer form {
      width: 100%;
      padding: 0 20px;
    }
.navigation-mobile .navigation-footer form input {
        width: 100%;
        height: 35px;
        padding: 5px 10px;
        font-size: 14px;
        color: rgba(33,44,71, 0.7523);
        border: 1px solid rgba(33,44,71, 0.123);
      }
.navigation-mobile .navigation-footer-social {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
.navigation-mobile .navigation-footer-social a {
      padding: 0;
    }
.navigation-mobile .navigation-footer-social svg {
      margin-right: 20px;
      height: 24px;
      -webkit-transition: all .3s ease-in-out;
      -o-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
    }
.navigation-mobile .subnav-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-left: 15px;
    padding-bottom: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
  }
.navigation-mobile .subnav-mobile.active {
      max-height: inherit;
      opacity: 1;
      padding-bottom: 20px;
    }
.navigation-mobile .subnav-mobile a {
      font-size: 14px;
      margin-bottom: 10px;
    }
.navigation-mobile-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background: rgba(33,44,71, 0.70);

  opacity: 0;
}
.navigation-mobile-overlay.is-active {
    opacity: 1;
    z-index: 15;
  }
.instagram-footer {
	width: 100%;
	background-color: #212C47;
	padding: 5vw 10vw;

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
@media (max-width: 955px) {
.instagram-footer {
		padding-left: 25px;
		padding-right: 25px
}
	}
@media (max-width: 599px) {
.instagram-footer {
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center
}
	}
.instagram-footer .title {
		font-size: 28px;
		color: #F2EEDF;
	}
.instagram-footer .social {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
	}
@media (max-width: 599px) {
.instagram-footer .social {
			padding-top: 25px;
			padding-bottom: 25px
	}
		}
.instagram-footer .social .icon {
			width: 36px;
			height: 36px;
			border-radius: 50%;
			margin-left: 15px;
			background-color: #63B3C1;
			-webkit-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			transition: all 0.2s ease;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			-webkit-box-pack: center;
			    -ms-flex-pack: center;
			        justify-content: center;
		}
.instagram-footer .social .icon:hover {
				background-color: #D31C1C;
			}
.instagram-footer .social .icon img {
				width: 20px;
			}
.instagram-footer .social .icon img.facebook {
					width: 10px;
				}
.instagram-footer .social .icon img.linkedin {
					width: 16px;
				}
.instagram-footer .feed {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		margin-top: 2.5vw;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
.instagram-footer .feed img {
			width: 24.5%;
    	margin-bottom: 0.5%;
		}
@media (max-width: 599px) {
.instagram-footer .feed img {
    		width: 49%;
    		margin-bottom: 10px
		}
    	}
.instagram-footer .feed a {
			width: 24.5%;
			margin-bottom: 0.5%;
		}
.instagram-footer .feed a img {
				display: block;
				width: 100%;
				margin-bottom: 0;
			}
@media (max-width: 599px) {
				.instagram-footer .feed a:nth-child(5),
				.instagram-footer .feed a:nth-child(6),
				.instagram-footer .feed a:nth-child(7),
				.instagram-footer .feed a:nth-child(8),
				.instagram-footer .feed img:nth-child(5),
				.instagram-footer .feed img:nth-child(6),
				.instagram-footer .feed img:nth-child(7),
				.instagram-footer .feed img:nth-child(8) {
					display: none;
				}
		}
.legal {
	width: 100%;
	padding: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
.legal .spacer {
		height: auto;
	}
.legal .regular-text {
		margin: 0 5px;
	}
.legal a {
		-webkit-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
.legal a:hover {
			color: #D31C1C;
		}
@media (max-width: 955px) {
.legal {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		text-align: center
}

		.legal .spacer {
			display: none;
		}

		.legal a {
			display: inline-block;
			padding-top: 12px;
		}
	}
/*! Flickity v2.2.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled{position:relative}
.flickity-enabled:focus{outline:0}
.flickity-viewport{overflow:hidden;position:relative;height:100%}
.flickity-slider{position:absolute;width:100%;height:100%}
.flickity-enabled.is-draggable{-webkit-tap-highlight-color:transparent;tap-highlight-color:transparent;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.flickity-enabled.is-draggable .flickity-viewport{cursor:move;cursor:-webkit-grab;cursor:grab}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down{cursor:-webkit-grabbing;cursor:grabbing}
.flickity-button{position:absolute;background:hsla(0,0%,100%,.75);border:none;color:#333}
.flickity-button:hover{background:#fff;cursor:pointer}
.flickity-button:focus{outline:0;-webkit-box-shadow:0 0 0 5px #19F;box-shadow:0 0 0 5px #19F}
.flickity-button:active{opacity:.6}
.flickity-button:disabled{opacity:.3;cursor:auto;pointer-events:none}
.flickity-button-icon{fill:currentColor}
.flickity-prev-next-button{top:50%;width:44px;height:44px;border-radius:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%)}
.flickity-prev-next-button.previous{left:10px}
.flickity-prev-next-button.next{right:10px}
.flickity-rtl .flickity-prev-next-button.previous{left:auto;right:10px}
.flickity-rtl .flickity-prev-next-button.next{right:auto;left:10px}
.flickity-prev-next-button .flickity-button-icon{position:absolute;left:20%;top:20%;width:60%;height:60%}
.flickity-page-dots{position:absolute;width:100%;bottom:-25px;padding:0;margin:0;list-style:none;text-align:center;line-height:1}
.flickity-rtl .flickity-page-dots{direction:rtl}
.flickity-page-dots .dot{display:inline-block;width:10px;height:10px;margin:0 8px;background:#333;border-radius:50%;opacity:.25;cursor:pointer}
.flickity-page-dots .dot.is-selected{opacity:1}
.glightbox-container{width:100%;height:100%;position:fixed;top:0;left:0;z-index:999999!important;overflow:hidden;-ms-touch-action:none;touch-action:none;-webkit-text-size-adjust:100%;-moz-text-size-adjust:100%;-ms-text-size-adjust:100%;text-size-adjust:100%;-webkit-backface-visibility:hidden;backface-visibility:hidden;outline:0;overflow:hidden}
.glightbox-container.inactive{display:none}
.glightbox-container .gcontainer{position:relative;width:100%;height:100%;z-index:9999;overflow:hidden}
.glightbox-container .gslider{-webkit-transition:-webkit-transform .4s ease;transition:-webkit-transform .4s ease;-o-transition:transform .4s ease;transition:transform .4s ease;transition:transform .4s ease, -webkit-transform .4s ease;transition:transform .4s ease,-webkit-transform .4s ease;height:100%;left:0;top:0;width:100%;position:relative;overflow:hidden;display:-webkit-box!important;display:-ms-flexbox!important;display:flex!important;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
.glightbox-container .gslide{width:100%;position:absolute;opacity:1;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;opacity:0}
.glightbox-container .gslide.current{opacity:1;z-index:99999;position:relative}
.glightbox-container .gslide.prev{opacity:1;z-index:9999}
.glightbox-container .gslide-inner-content{width:100%}
.glightbox-container .ginner-container{position:relative;width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;max-width:100%;margin:auto;height:100vh}
.glightbox-container .ginner-container.gvideo-container{width:100%}
.glightbox-container .ginner-container.desc-bottom,.glightbox-container .ginner-container.desc-top{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
.glightbox-container .ginner-container.desc-left,.glightbox-container .ginner-container.desc-right{max-width:100%!important}
.gslide iframe,.gslide video{outline:0!important;border:none;min-height:165px;-webkit-overflow-scrolling:touch;-ms-touch-action:auto;touch-action:auto}
.gslide-image{-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.gslide-image img{max-height:100vh;display:block;padding:0;float:none;outline:0;border:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;max-width:100vw;width:auto;height:auto;-o-object-fit:cover;object-fit:cover;-ms-touch-action:none;touch-action:none;margin:auto;min-width:200px}
.desc-bottom .gslide-image img,.desc-top .gslide-image img{width:auto}
.desc-left .gslide-image img,.desc-right .gslide-image img{width:auto;max-width:100%}
.gslide-image img.zoomable{position:relative}
.gslide-image img.dragging{cursor:-webkit-grabbing!important;cursor:grabbing!important;-webkit-transition:none;-o-transition:none;transition:none}
.gslide-video{position:relative;max-width:100vh;width:100%!important}
.gslide-video .gvideo-wrapper{width:100%;margin:auto}
.gslide-video::before{content:'';display:block;position:absolute;width:100%;height:100%;background:rgba(255,0,0,.34);display:none}
.gslide-video.playing::before{display:none}
.gslide-video.fullscreen{max-width:100%!important;min-width:100%;height:75vh}
.gslide-video.fullscreen video{max-width:100%!important;width:100%!important}
.gslide-inline{background:#fff;text-align:left;max-height:calc(100vh - 40px);overflow:auto;max-width:100%}
.gslide-inline .ginlined-content{padding:20px;width:100%}
.gslide-inline .dragging{cursor:-webkit-grabbing!important;cursor:grabbing!important;-webkit-transition:none;-o-transition:none;transition:none}
.ginlined-content{overflow:auto;display:block!important;opacity:1}
.gslide-external{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;min-width:100%;background:#fff;padding:0;overflow:auto;max-height:75vh;height:100%}
.gslide-media{display:-webkit-box;display:-ms-flexbox;display:flex;width:auto}
.zoomed .gslide-media{-webkit-box-shadow:none!important;box-shadow:none!important}
.desc-bottom .gslide-media,.desc-top .gslide-media{margin:0 auto;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
.gslide-description{position:relative;-webkit-box-flex:1;-ms-flex:1 0 100%;flex:1 0 100%}
.gslide-description.description-left,.gslide-description.description-right{max-width:100%}
.gslide-description.description-bottom,.gslide-description.description-top{margin:0 auto;width:100%}
.gslide-description p{margin-bottom:12px}
.gslide-description p:last-child{margin-bottom:0}
.zoomed .gslide-description{display:none}
.glightbox-button-hidden{display:none}
.glightbox-mobile .glightbox-container .gslide-description{height:auto!important;width:100%;background:0 0;position:absolute;bottom:15px;padding:19px 11px;max-width:100vw!important;-webkit-box-ordinal-group:3!important;-ms-flex-order:2!important;order:2!important;max-height:78vh;overflow:auto!important;background:-webkit-gradient(linear,left top, left bottom,color-stop(0, rgba(0,0,0,0)),to(rgba(0,0,0,.75)));background:-o-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,.75) 100%);background:linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.75) 100%);-webkit-transition:opacity .3s linear;-o-transition:opacity .3s linear;transition:opacity .3s linear;padding-bottom:50px}
.glightbox-mobile .glightbox-container .gslide-title{color:#fff;font-size:1em}
.glightbox-mobile .glightbox-container .gslide-desc{color:#a1a1a1}
.glightbox-mobile .glightbox-container .gslide-desc a{color:#fff;font-weight:700}
.glightbox-mobile .glightbox-container .gslide-desc *{color:inherit}
.glightbox-mobile .glightbox-container .gslide-desc string{color:#fff}
.glightbox-mobile .glightbox-container .gslide-desc .desc-more{color:#fff;opacity:.4}
.gdesc-open .gslide-media{-webkit-transition:opacity .5s ease;-o-transition:opacity .5s ease;transition:opacity .5s ease;opacity:.4}
.gdesc-open .gdesc-inner{padding-bottom:30px}
.gdesc-closed .gslide-media{-webkit-transition:opacity .5s ease;-o-transition:opacity .5s ease;transition:opacity .5s ease;opacity:1}
.greset{-webkit-transition:all .3s ease;-o-transition:all .3s ease;transition:all .3s ease}
.gabsolute{position:absolute}
.grelative{position:relative}
.glightbox-desc{display:none!important}
.glightbox-open{overflow:hidden}
.gloader{height:25px;width:25px;-webkit-animation:lightboxLoader .8s infinite linear;animation:lightboxLoader .8s infinite linear;border:2px solid #fff;border-right-color:transparent;border-radius:50%;position:absolute;display:block;z-index:9999;left:0;right:0;margin:0 auto;top:47%}
.goverlay{width:100%;height:calc(100vh + 1px);position:fixed;top:-1px;left:0;background:#000;will-change:opacity}
.glightbox-mobile .goverlay{background:#000}
.gclose,.gnext,.gprev{z-index:99999;cursor:pointer;width:26px;height:44px;border:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
.gclose svg,.gnext svg,.gprev svg{display:block;width:25px;height:auto;margin:0;padding:0}
.gclose.disabled,.gnext.disabled,.gprev.disabled{opacity:.1}
.gclose .garrow,.gnext .garrow,.gprev .garrow{stroke:#fff}
.gbtn.focused{outline:2px solid #0f3d81}
iframe.wait-autoplay{opacity:0}
.glightbox-closing .gclose,.glightbox-closing .gnext,.glightbox-closing .gprev{opacity:0!important}
.glightbox-clean .gslide-description{background:#fff}
.glightbox-clean .gdesc-inner{padding:22px 20px}
.glightbox-clean .gslide-title{font-size:1em;font-weight:400;font-family:arial;color:#000;margin-bottom:19px;line-height:1.4em}
.glightbox-clean .gslide-desc{font-size:.86em;margin-bottom:0;font-family:arial;line-height:1.4em}
.glightbox-clean .gslide-video{background:#000}
.glightbox-clean .gclose,.glightbox-clean .gnext,.glightbox-clean .gprev{background-color:rgba(0,0,0,.75);border-radius:4px}
.glightbox-clean .gclose path,.glightbox-clean .gnext path,.glightbox-clean .gprev path{fill:#fff}
.glightbox-clean .gprev{position:absolute;top:-100%;left:30px;width:40px;height:50px}
.glightbox-clean .gnext{position:absolute;top:-100%;right:30px;width:40px;height:50px}
.glightbox-clean .gclose{width:35px;height:35px;top:15px;right:10px;position:absolute}
.glightbox-clean .gclose svg{width:18px;height:auto}
.glightbox-clean .gclose:hover{opacity:1}
.gfadeIn{-webkit-animation:gfadeIn .5s ease;animation:gfadeIn .5s ease}
.gfadeOut{-webkit-animation:gfadeOut .5s ease;animation:gfadeOut .5s ease}
.gslideOutLeft{-webkit-animation:gslideOutLeft .3s ease;animation:gslideOutLeft .3s ease}
.gslideInLeft{-webkit-animation:gslideInLeft .3s ease;animation:gslideInLeft .3s ease}
.gslideOutRight{-webkit-animation:gslideOutRight .3s ease;animation:gslideOutRight .3s ease}
.gslideInRight{-webkit-animation:gslideInRight .3s ease;animation:gslideInRight .3s ease}
.gzoomIn{-webkit-animation:gzoomIn .5s ease;animation:gzoomIn .5s ease}
.gzoomOut{-webkit-animation:gzoomOut .5s ease;animation:gzoomOut .5s ease}
@-webkit-keyframes lightboxLoader{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
@keyframes lightboxLoader{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
@-webkit-keyframes gfadeIn{from{opacity:0}to{opacity:1}}
@keyframes gfadeIn{from{opacity:0}to{opacity:1}}
@-webkit-keyframes gfadeOut{from{opacity:1}to{opacity:0}}
@keyframes gfadeOut{from{opacity:1}to{opacity:0}}
@-webkit-keyframes gslideInLeft{from{opacity:0;-webkit-transform:translate3d(-60%,0,0);transform:translate3d(-60%,0,0)}to{visibility:visible;-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}}
@keyframes gslideInLeft{from{opacity:0;-webkit-transform:translate3d(-60%,0,0);transform:translate3d(-60%,0,0)}to{visibility:visible;-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}}
@-webkit-keyframes gslideOutLeft{from{opacity:1;visibility:visible;-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}to{-webkit-transform:translate3d(-60%,0,0);transform:translate3d(-60%,0,0);opacity:0;visibility:hidden}}
@keyframes gslideOutLeft{from{opacity:1;visibility:visible;-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}to{-webkit-transform:translate3d(-60%,0,0);transform:translate3d(-60%,0,0);opacity:0;visibility:hidden}}
@-webkit-keyframes gslideInRight{from{opacity:0;visibility:visible;-webkit-transform:translate3d(60%,0,0);transform:translate3d(60%,0,0)}to{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}}
@keyframes gslideInRight{from{opacity:0;visibility:visible;-webkit-transform:translate3d(60%,0,0);transform:translate3d(60%,0,0)}to{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);opacity:1}}
@-webkit-keyframes gslideOutRight{from{opacity:1;visibility:visible;-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}to{-webkit-transform:translate3d(60%,0,0);transform:translate3d(60%,0,0);opacity:0}}
@keyframes gslideOutRight{from{opacity:1;visibility:visible;-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}to{-webkit-transform:translate3d(60%,0,0);transform:translate3d(60%,0,0);opacity:0}}
@-webkit-keyframes gzoomIn{from{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}to{opacity:1}}
@keyframes gzoomIn{from{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}to{opacity:1}}
@-webkit-keyframes gzoomOut{from{opacity:1}50%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}to{opacity:0}}
@keyframes gzoomOut{from{opacity:1}50%{opacity:0;-webkit-transform:scale3d(.3,.3,.3);transform:scale3d(.3,.3,.3)}to{opacity:0}}
@media (min-width:769px){.glightbox-container .ginner-container{width:auto;height:auto;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-direction:row;flex-direction:row}.glightbox-container .ginner-container.desc-top .gslide-description{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}.glightbox-container .ginner-container.desc-top .gslide-image,.glightbox-container .ginner-container.desc-top .gslide-image img{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}.glightbox-container .ginner-container.desc-left .gslide-description{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}.glightbox-container .ginner-container.desc-left .gslide-image{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}.gslide-image img{max-height:97vh;max-width:100%}.gslide-image img.zoomable{cursor:-webkit-zoom-in;cursor:zoom-in}.zoomed .gslide-image img.zoomable{cursor:-webkit-grab;cursor:grab}.gslide-inline{max-height:95vh}.gslide-external{max-height:100vh}.gslide-description.description-left,.gslide-description.description-right{max-width:275px}.glightbox-open{height:auto}.goverlay{background:rgba(0,0,0,.92)}.glightbox-clean .gslide-media{-webkit-box-shadow:1px 2px 9px 0 rgba(0,0,0,.65);box-shadow:1px 2px 9px 0 rgba(0,0,0,.65)}.glightbox-clean .description-left .gdesc-inner,.glightbox-clean .description-right .gdesc-inner{position:absolute;height:100%;overflow-y:auto}.glightbox-clean .gclose,.glightbox-clean .gnext,.glightbox-clean .gprev{background-color:rgba(0,0,0,.32)}.glightbox-clean .gclose:hover,.glightbox-clean .gnext:hover,.glightbox-clean .gprev:hover{background-color:rgba(0,0,0,.7)}.glightbox-clean .gprev{top:45%}.glightbox-clean .gnext{top:45%}}
@media (min-width:992px){.glightbox-clean .gclose{opacity:.7;right:20px}}
@media screen and (max-height:420px){.goverlay{background:#000}}
.max-width-padding {
  padding: 0 10vw 0 10vw;
}
@media (max-width: 1255px) {
.max-width-padding {
    padding-left: 20px;
    padding-right: 20px
}
  }
.default-block {
  width: 100%;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;

  padding: 0 10vw 0 10vw;
}
@media (max-width: 1255px) {
.default-block {
    padding-left: 20px;
    padding-right: 20px
}
  }
.default-block.no-padding {
    padding: 0;
  }
.home-hero {
  position: relative;
  width: 100%;
  margin-top: 75px;
  overflow: hidden;
}
@media (max-width: 1024px) {
.home-hero {
    margin-top: 50px
}
  }
.home-hero .left {
    width: 50%;
    height: auto;
    position: relative;
  }
.home-hero .left .main-carousel {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      background: #212C47;
    }
.home-hero .left .main-carousel .carousel-cell {
        width: 100%;
        height: 100vh;
        background-size: cover;
        background-position: center center;
      }
.home-hero .left .main-carousel .carousel-cell img {
          display: block;
          width: 100%;
          height: 100vh;
          -o-object-fit: cover;
             object-fit: cover;
        }
.home-hero .left .main-carousel .flickity-page-dots {
        bottom: 2vw;
      }
.home-hero .left .main-carousel .flickity-page-dots .dot {
          width: 20px;
          height: 20px;
          opacity: 1;
          background-color: transparent;
          border: 4px solid #F2EEDF;
          -webkit-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
          z-index: 200;
        }
@media (max-width: 599px) {
.home-hero .left .main-carousel .flickity-page-dots .dot {
            width: 13px;
            height: 13px;
            border: 2px solid #F2EEDF;
            margin: 0 4px
        }
          }
.home-hero .left .main-carousel .flickity-page-dots .dot.is-selected {
            background-color: #F2EEDF;
          }
.home-hero .right {
    width: 50%;
    height: 100%;
    background-color: #212C47;
  }
.home-hero .right .top {
      width: 100%;
      height: 45%;
      padding: 6vw 6vw;
    }
@media (max-width: 1024px) {
.home-hero .right .top {
        padding-left: 25px;
        padding-right: 25px
    }
      }
.home-hero .right .top .home-title {
        font-family: 'alvar_black', 'Arial', 'sans-serif';
        font-style: normal;
        font-weight: 900;
        font-size: 52px;
        line-height: 100%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #F2EEDF;
      }
@media (max-width: 1255px) {
.home-hero .right .top .home-title {
          font-size: 44px
      }
        }
@media (max-width: 599px) {
.home-hero .right .top .home-title {
          font-size: 32px
      }
        }
.home-hero .right .top .ctas {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-top: 25px;
      }
@media (max-width: 599px) {
.home-hero .right .top .ctas {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column
      }
        }
.home-hero .right .top .ctas .cta-wrap {
          margin-right: 5%;
        }
@media (max-width: 599px) {
.home-hero .right .top .ctas .cta-wrap {
            width: 100%;
            margin: 0;
            margin-bottom: 10px
        }

            .home-hero .right .top .ctas .cta-wrap:last-child {
              margin-bottom: 0;
            }
          }
.home-hero .right .top .ctas .cta-wrap .sub-title {
            font-size: 13px;
          }
.home-hero .right .bottom {
      position: relative;
      width: 100%;
      height: 55%;
    }
.home-hero .right .bottom img {
        display: block;
        width: 100%;
      }
.home-hero .right .bottom .placeholder {
        opacity: 0;
      }
.home-hero .right .bottom .bottom-img {
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;

        -webkit-transition: opacity 0.55s ease 0s;

        -o-transition: opacity 0.55s ease 0s;

        transition: opacity 0.55s ease 0s;
      }
.home-hero .right .bottom .bottom-img.is-active {
          opacity: 1;
        }
@media (max-width: 1024px) {
.home-hero {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap
}

    .home-hero .left, .home-hero .right {
      width: 100%;
      height: auto;
    }

      .home-hero .left .bottom, .home-hero .left .top, .home-hero .right .bottom, .home-hero .right .top {
        height: auto;
      }

      .home-hero .left .main-carousel, .home-hero .right .main-carousel {
        position: relative;
      }

        .home-hero .left .main-carousel .carousel-cell, .home-hero .right .main-carousel .carousel-cell {
          height: 80vw;
        }
  }
body .filters-bar-wrapper {
  background-color: #212C47;
}
body .filters-bar-wrapper .title {
    color: #F2EEDF;
    margin-bottom: 1vw;
  }
body .filters-bar-wrapper .select-radio-wrap {
    margin-bottom: 0;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
@media (max-width: 955px) {
body .filters-bar-wrapper .select-radio-wrap {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap
  }
    }
body .filters-bar-wrapper .select-radio-wrap .select {
      margin: 0;
      background-color: #212C47;
      border-color: #F2EEDF;
      color: #F2EEDF;
      background-image: url(/img/site/arrow-down-light.svg);
    }
body .filters-bar-wrapper .select-radio-wrap .select:first-child {
        border-left-color: #F2EEDF;
      }
@media (max-width: 955px) {
body .filters-bar-wrapper .select-radio-wrap .select {
				width: 100%;
        margin-bottom: 10px
    }
			}
body .filters-bar-wrapper .select-radio-wrap .filters-button {
			background-image: url(/img/site/slider-arrow-right.jpg);
    }
.scroll-fix {
  position: relative;
  width: 100%;
}
.scroll-fix .fixed-floating-image {
    position: absolute;
    top: 0;
    right: 0;

    width: 50%;
    height: 100vh;

    background: #F2EEDF;

    z-index: 9;
  }
@media (max-width: 1024px) {
.scroll-fix .fixed-floating-image {
      display: none
  }
    }
.scroll-fix .fixed-floating-image .cta-wrap {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 5;
    }
.scroll-fix .fixed-floating-image .float-img {
      display: block;
      width: 100%;
      height: 100vh;
      -o-object-fit: cover;
         object-fit: cover;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      object-fit: cover;

      opacity: 0;

      -webkit-transition: opacity 0.65s ease 0s;

      -o-transition: opacity 0.65s ease 0s;

      transition: opacity 0.65s ease 0s;
    }
.scroll-fix .fixed-floating-image .float-img.is-active {
        opacity: 1;
      }
.split {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 100vh;
}
.split .left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

    width: 50%;
    height: 100%;
    padding: 10vw 10vw;
  }
@media (max-width: 1255px) {
.split .left {
      padding-left: 25px;
      padding-right: 25px
  }
    }
.split .left .split-title {
      font-family: 'alvar_black', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 46px;
      line-height: 100%;
      /* or 55px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Bleu */
      color: #212C47;

      margin: 0;
      padding: 0;
      margin-bottom: 40px;
    }
@media (max-width: 1255px) {
.split .left .split-title {
        font-size: 35px
    }
      }
@media (max-width: 599px) {
.split .left .split-title {
        font-size: 32px
    }
      }
.split .left .split-subtitle {
      font-family: 'alvar_semibold', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 1.2;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Bleu */
      color: #212C47;

      margin: 0;
      padding: 0;
      margin-bottom: 20px;
    }
@media (max-width: 1255px) {
.split .left .split-subtitle {
        font-size: 22px;
        line-height: 1.3
    }
      }
@media (max-width: 599px) {
.split .left .split-subtitle {
        font-size: 20px
    }
      }
.split .left .split-text {
      font-family: 'alvar_regular', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* or 30px */

      letter-spacing: 0.05em;

      /* Bleu */
      color: #212C47;

      margin: 0;
      padding: 0;
      margin-bottom: 35px;
    }
.split .right {
    position: relative;
    width: 50%;
    height: 100%;
  }
.split .right .cta-wrap {
      position: absolute;
      right: 0;
      bottom: 0;
    }
@media (max-width: 1024px) {
.split {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    height: auto
}

    .split .left, .split .right {
      width: 100%;
      height: auto;
    }

    .split .right img {
      display: block;
      width: 100%;
      max-height: 60vw;
      -o-object-fit: cover;
         object-fit: cover;
    }
  }
.stats .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    padding: 10px 0;
  }
.stats .row span {
      min-width: 115px;
      margin: 0;
      padding: 0;

      font-family: 'alvar_black', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 52px;
      line-height: 100%;
      /* identical to box height, or 65px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Bleu */

      color: #212C47;
    }
@media (max-width: 599px) {
.stats .row span {
        font-size: 42px;
        min-width: 97px
    }
      }
.stats .row p {
      margin: 0;
      padding: 0;

      font-family: 'alvar_semibold', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Bleu */

      color: #212C47;
    }
.marquee {
  position: relative;
  overflow: hidden;

  padding: 6vw 0 0 0;
  background: #212C47;

  font-family: 'alvar_light', 'Arial', 'sans-serif';
  font-style: normal;
  font-weight: 100;
  font-size: 100px;
  line-height: 100%;
  /* identical to box height, or 150px */

  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* Rouge */
  color: #F2EEDF;
}
@media (max-width: 1024px) {
.marquee {
    font-size: 77px
}
  }
@media (max-width: 599px) {
.marquee {
    font-size: 56px
}
  }
.marquee .marquee-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-animation: infinityLeft 300s linear infinite;

            animation: infinityLeft 300s linear infinite;
  }
.marquee strong {
    color: #D31C1C;
  }
.marquee span {
    display: inline-block;
    padding-right: 45px;
  }
.carrieres {
  position: relative;
  width: 100%;
  padding: 6vw 0 0 10vw;
  background: #212C47;
}
@media (max-width: 1255px) {
.carrieres {
    padding-left: 20px
}
  }
.carrieres .carrieres-title {
    font-family: 'alvar_black', 'Arial', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 34px;
    line-height: 100%;
    /* or 45px */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* Beige */
    color: #F2EEDF;
    margin-bottom: 45px;
  }
@media (max-width: 599px) {
.carrieres .carrieres-title {
      font-size: 23px
  }
    }
.carrieres .headings {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-right: 12.5vw;
  }
.carrieres .controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
@media (max-width: 767px) {
.carrieres .controls {
      display: none
  }
    }
.carrieres .controls .control {
      width: 55px;
      margin-left: 15px;
      cursor: pointer;
    }
.carrieres .controls .control rect {
        fill: #F2EEDF;
        -webkit-transition: fill 0.5s ease 0s;
        -o-transition: fill 0.5s ease 0s;
        transition: fill 0.5s ease 0s;
      }
.carrieres .controls .control:hover rect {
          fill: #D31C1C;
        }
.carrieres .listing {
    position: relative;
    overflow: hidden;
  }
.carrieres .listing .thumb-ph {
      width: 400px;
      height: 1px;
    }
@media (max-width: 599px) {
.carrieres .listing .thumb-ph {
        display: none
    }
      }
.carrieres .listing .thumb {
      position: relative;
      display: block;
      width: calc(100vw / 4);
      margin-right: 20px;
      border: 1px solid #F2EEDF;

      -webkit-transition: none;

      -o-transition: none;

      transition: none;
    }
@media (max-width: 1255px) {
.carrieres .listing .thumb {
        width: calc(100vw / 2.65)
    }
      }
@media (max-width: 1024px) {
.carrieres .listing .thumb {
        width: calc(100vw / 1.95)
    }
      }
@media (max-width: 599px) {
.carrieres .listing .thumb {
        width: 330px;
        margin-right: 10px
    }
      }
.carrieres .listing .thumb .img-wrap {
        position: relative;
        overflow: hidden;
        border-bottom: 1px solid #F2EEDF;
      }
.carrieres .listing .thumb img {
        display: block;
        width: 100%;

        -webkit-transform: none;

            -ms-transform: none;

                transform: none;
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-filter: none;
                filter: none;
        -webkit-transition: all 4.5s ease;
        -o-transition: all 4.5s ease;
        transition: all 4.5s ease;
      }
.carrieres .listing .thumb .content {
        padding: 20px;
      }
.carrieres .listing .thumb {

      -webkit-transition: all 0.2s ease;

      -o-transition: all 0.2s ease;

      transition: all 0.2s ease;
}
.carrieres .listing .thumb:hover {
        background-color: rgba(255,255,255, 0.1275);
      }
.carrieres .listing .thumb:hover img {
          -webkit-transform: scale(1.175);
              -ms-transform: scale(1.175);
                  transform: scale(1.175);
        }
.carrieres .listing .thumb .thumb-title {
        font-family: 'alvar_semibold', 'Arial', 'sans-serif';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Vert */
        color: #63B3C1;

      }
.carrieres .listing .thumb .infos {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;

        margin-top: 20px;
      }
.carrieres .listing .thumb .infos .col {
          width: 50%;
          text-align: center;
          border: 1px solid #F2EEDF;
          padding: 10px 5px;

          font-family: 'alvar_semibold', 'Arial', 'sans-serif';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.05em;
          text-transform: uppercase;

          /* Beige */
          color: #F2EEDF;
        }
.carrieres .listing .thumb .infos .col:first-child {
            border-right: none;
          }
@media (max-width: 1255px) {
.carrieres .listing .thumb .infos .col {
            font-size: 14px
        }
          }
.emplois {
  position: relative;
  width: 100%;
  padding: 8vw 0;
  background: #212C47;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.emplois .left {
    width: 50%;
  }
.emplois .left img {
      display: block;
      width: 100%;
    }
.emplois .right {
    width: 50%;
    padding: 0 10vw 0 6vw;
  }
@media (max-width: 1255px) {
.emplois .right {
      padding-right: 20px
  }
    }
.emplois .right .emplois-title {
      font-family: 'alvar_black', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 38px;
      line-height: 100%;
      /* or 45px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Beige */
      color: #F2EEDF;
      margin-bottom: 35px;
    }
@media (max-width: 1255px) {
.emplois .right .emplois-title {
        font-size: 35px
    }
      }
@media (max-width: 599px) {
.emplois .right .emplois-title {
        font-size: 24px
    }
      }
.emplois .right .emplois-text {
      font-family: 'alvar_regular', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* or 30px */

      letter-spacing: 0.05em;

      /* Bleu */
      color: #F2EEDF;

      margin: 0;
      padding: 0;
      margin-bottom: 35px;
    }
.emplois .right .avantages .avantage {
        width: 100%;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
.emplois .right .avantages .avantage:hover {
          background-color: rgba(255,255,255, 0.1275);
        }
@media (max-width: 1024px) {
.emplois {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap
}

    .emplois .left, .emplois .right {
      width: 100%;
    }

    .emplois .right {
      padding-top: 10vw;
      padding-bottom: 10vw;
    }

    .emplois .left img {
      display: block;
      width: 100%;
      max-height: 60vw;
      -o-object-fit: cover;
         object-fit: cover;
    }
  }
@media (max-width: 599px) {
    .emplois .right {
      padding-bottom: 0;
    }
  }
.emplois .avantages {
    margin-top: 2.5vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
.emplois .avantages .sub-title {
      width: 100%;
      font-size: 18px;
      color: #F2EEDF;
      margin-bottom: 1.5vw;
    }
.emplois .avantages .avantage {
      position: relative;
      width: calc(33.33% - 10px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 20px;
      border: 2px solid #F2EEDF;
      margin-bottom: 20px;
    }
.emplois .avantages .avantage img {
        width: 25px;
        margin-right: 15px;
      }
.emplois .avantages .avantage .sub-title {
        font-size: 14px;
        color: #F2EEDF;
        margin-bottom: 0;
      }
.emplois .avantages .avantage .click-icon {
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
        width: 18px;
      }
.profile {
  position: relative;
  width: 100%;
  padding: 10vw 0;
  background: #63B3C1;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.profile .left {
    width: 50%;
    padding: 0 10vw;
  }
@media (max-width: 1255px) {
.profile .left {
      padding-left: 20px;
      padding-right: 20px
  }
    }
@media (max-width: 599px) {
.profile .left {
      padding-top: 25px
  }
    }
.profile .left .profile-title {
      font-family: 'alvar_black', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 100%;
      /* or 45px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Beige */
      color: #F2EEDF;
    }
@media (max-width: 599px) {
.profile .left .profile-title {
        font-size: 28px
    }
      }
.profile .right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 50%;
  }
.profile .right img {
      display: block;
      width: 100%;
      margin-bottom: -8vw;
    }
@media (max-width: 1024px) {
.profile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column
}

    .profile .left, .profile .right {
      width: 100%;
    }

    .profile .right {
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
    }

    .profile .left {
      padding-top: 10vw;
      padding-bottom: 10vw;
    }

    .profile .right img {
      display: inline-block;
      width: 80%;
    }
  }
@media (max-width: 599px) {
    .profile .left {
      padding-top: 0;
    }

    .profile .right {
      display: none;
    }
  }
.arguments {
  padding-top: 45px;
}
.arguments .row {
    position: relative;
    padding-left: 55px;
    margin-bottom: 45px;
  }
.arguments .row svg {
      position: absolute;
      top: 0;
      left: 0;
    }
.arguments .row strong {
      font-family: 'alvar_semibold', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Beige */
      color: #F2EEDF;
    }
.arguments .row p {
      font-family: 'alvar_regular', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 30px */

      letter-spacing: 0.05em;

      /* Beige */
      color: #F2EEDF;
    }
@-webkit-keyframes infinityLeft {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(-500%, 0, 0);
                transform: translate3d(-500%, 0, 0);
    }
}
@keyframes infinityLeft {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(-500%, 0, 0);
                transform: translate3d(-500%, 0, 0);
    }
}
.simple-hero-recits {
    position: relative;
    width: 100%;
  
    margin-top:115px;
}
@media (max-width: 1024px) {
.simple-hero-recits {
      margin-top: 75px
}
    }
.simple-hero-recits img.main {
      display: block;
      width: 100%;
    }
@media (max-width: 1224px) {
.simple-hero-recits img.main {
        min-height: 600px;
        -o-object-fit: cover;
           object-fit: cover
    }
      }
@media (max-width: 599px) {
.simple-hero-recits img.main {
        min-height: 445px
    }
      }
.simple-hero-recits .img-video-asset {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      z-index: 2;
  }
.simple-hero-recits .img-video-asset video {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
             object-fit: cover;
      }
.simple-hero-recits .img-video-asset video::-webkit-media-controls {
              display: none !important;
          }
.simple-hero-recits .content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
  
      width: 48%;
      height: 100%;
      padding: 4vw 10vw;
  
      display: -webkit-box;
  
      display: -ms-flexbox;
  
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;

      margin-top: 25px;
    }
@media (max-width: 1224px) {
.simple-hero-recits .content {
        width: 60%
    }
      }
@media (max-width: 599px) {
.simple-hero-recits .content {
        width: 100%;
        margin-top: 0;
        padding-top: 45px
    }
      }
.simple-hero-recits .simple-hero-title {
      font-family: 'alvar_black', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 46px;
      line-height: 100%;
      /* or 65px */
  
      letter-spacing: 0.05em;
      text-transform: uppercase;
  
      /* Beige */
      color: #F2EEDF;

      margin-top: 75px;
    }
@media (max-width: 1024px) {
.simple-hero-recits .simple-hero-title {
        font-size: 32px
    }
      }
.simple-hero-recits .simple-hero-subtitle {
        font-family: 'alvar_semibold', 'Arial', 'sans-serif';
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 100%;
        /* or 65px */
    
        letter-spacing: 0.05em;
        text-transform: uppercase;
    
        /* Beige */
        color: #63B3C1;

        margin-top: 25px;
        margin-bottom: 5px;
    }
.simple-hero-recits .simple-hero-text {
      font-family: 'alvar_regular', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.05em;
      margin: 0;
      padding: 0;
      /* Beige */
      color: #F2EEDF;

      margin-top: 5px;
      margin-bottom: 25px;
    }
.simple-hero-recits .play-btn {
      max-width: 55px;
    }
@media (max-width: 1024px) {
.simple-hero-recits .play-btn {
        max-width: 45px
    }
      }
.simple-hero-recits:before {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        bottom: 0;
        left: 0;
        z-index: 3;
        background: -webkit-gradient(linear,  left top, right top,  from(rgba(0,0,0,0.95)),to(rgba(0,0,0,0)));
        background: -o-linear-gradient(left,  rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to right,  rgba(0,0,0,0.95) 0%,rgba(0,0,0,0) 100%);
    }
@media (max-width: 1024px) {
.simple-hero-recits:before {
          right: 0
    }
        }
.notice {
  padding: 2vw 10vw;
  text-align: center;
  background: rgba(33,44,71, 0.05123);
}
@media (max-width: 1255px) {
.notice {
    padding-left: 20px;
    padding-right: 20px
}
  }
.recits-block {
    position: relative;
    width: 100%;
    background: #F2EEDF;
    padding: 4vw 10vw;
    padding-bottom: 0;
}
@media (max-width: 1255px) {
.recits-block {
      padding-left: 20px;
      padding-right: 20px
}
    }
.recits-block .articles-block-title {
      font-family: 'alvar_black', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 38px;
      line-height: 100%;
      /* identical to box height, or 45px */
  
      letter-spacing: 0.05em;
      text-transform: uppercase;
  
      /* Bleu */
      color: #212C47;
      padding-bottom: 45px;
    }
@media (max-width: 1255px) {
.recits-block .articles-block-title {
        font-size: 32px
    }
      }
@media (max-width: 1024px) {
.recits-block .articles-block-title {
        font-size: 25px;
        line-height: 1;
        padding-bottom: 20px
    }
      }
.recits-block .articles {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
.recits-block .articles .article {
        width: calc(98% / 2);
        margin-bottom: 45px;
      }
@media (max-width: 599px) {
.recits-block .articles .article {
          width: 100%
      }
        }
.recits-block .articles .article .img-wrap {
            position: relative;
        }
.recits-block .articles .article .img-wrap:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(33,44,71, 0.4);
                z-index: 1;
            }
.recits-block .articles .article img {
          display: block;
          width: 100%;
        }
.recits-block .articles .article .overlay-link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;

            display: -webkit-box;

            display: -ms-flexbox;

            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
        }
.recits-block .articles .article .overlay-link img {
                display: block;
                width: 55px;
            }
.recits-block .articles .article .article-title {
          font-family: 'alvar_regular', 'Arial', 'sans-serif';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 1.2;
          /* identical to box height */
  
          letter-spacing: 0.05em;
          text-transform: uppercase;
  
          /* Bleu */
          color: #63B3C1;
          margin-bottom: 15px;
          padding-top: 15px;
        }
.recits-block .articles .article .article-text {
          font-family: 'alvar_regular', 'Arial', 'sans-serif';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.05em;
          color: #212C47;
          margin: 0;
          padding: 0;
          margin-bottom: 35px;
        }
@media (max-width: 599px) {
.recits-block .articles .article .article-text {
            max-width: 100%
        }
          }
.img-video-asset {
        position: relative;
        overflow: hidden;
        width: 100%;
        min-height: 375px;
    }
.img-video-asset img {
            width: 100%;
        }
@media (max-width: 699px) {
.img-video-asset img {
                min-height: 375px;
                -o-object-fit: cover;
                   object-fit: cover
        }
            }
.img-video-asset video {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            -o-object-fit: cover;
               object-fit: cover;
        }
@media (max-width: 699px) {
.img-video-asset video {
                min-height: 375px
        }
            }
.img-video-asset video::-webkit-media-controls {
                display: none !important;
            }
.simple-hero {
  position: relative;
  width: 100%;

  margin-top: 115px;
}
@media (max-width: 1024px) {
.simple-hero {
    margin-top: 75px
}
  }
.simple-hero img.main {
    display: block;
    width: 100%;
  }
@media (max-width: 599px) {
.simple-hero img.main {
      min-height: 400px;
      -o-object-fit: cover;
         object-fit: cover
  }
    }
.simple-hero .content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    width: 100%;
    height: 100%;
    padding: 4vw 10vw;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
@media (max-width: 1255px) {
.simple-hero .content {
      padding-left: 20px;
      padding-right: 20px
  }
    }
.simple-hero .simple-hero-title {
    font-family: 'alvar_black', 'Arial', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 54px;
    line-height: 100%;
    /* or 65px */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* Beige */
    color: #F2EEDF;
  }
@media (max-width: 1255px) {
.simple-hero .simple-hero-title {
      font-size: 46px
  }
    }
@media (max-width: 599px) {
.simple-hero .simple-hero-title {
      font-size: 32px
  }
    }
@media (max-width: 1024px) {
.simple-hero .play-btn {
      max-width: 55px
  }
    }
.intro {
  position: relative;
  width: 100%;
  padding: 6vw 0;
  padding-bottom: 4vw;
  background: #F2EEDF;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.intro.reverse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
.intro .left {
    width: 50%;
    padding: 0 10vw;
  }
@media (max-width: 1255px) {
.intro .left {
      padding-left: 20px;
      padding-right: 20px
  }
    }
.intro .left .intro-title {
      font-family: 'alvar_black', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 100%;
      /* or 45px */

      letter-spacing: 0.05em;
      text-transform: uppercase;

      /* Beige */
      color: #212C47;
      margin-bottom: 45px;
    }
@media (max-width: 1255px) {
.intro .left .intro-title {
        font-size: 36px
    }
      }
@media (max-width: 599px) {
.intro .left .intro-title {
        font-size: 26px;
        margin-bottom: 20px
    }
      }
.intro .left .intro-text {
      font-family: 'alvar_regular', 'Arial', 'sans-serif';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      /* or 30px */

      letter-spacing: 0.05em;

      /* Bleu */
      color: #212C47;

      margin: 0;
      padding: 0;
      margin-bottom: 35px;
    }
.intro .right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 50%;
  }
.intro .right img {
      display: block;
      width: 100%;
    }
@media (max-width: 1024px) {
.intro {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding-top: 45px
}

    .intro.reverse {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse;
      padding-top: 45px;
    }
      .intro .intro-title br {
        display: none;
      }

    .intro .left, .intro .right {
      width: 100%;
    }

    .intro .left {
      padding-top: 45px;
    }

      .intro .right img {
        display: block;
        width: 100%;
        max-height: 60vw;
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: center bottom;
           object-position: center bottom;
      }
  }
.testimonial-block {
  position: relative;
  width: 100%;
  padding: 4vw 10vw;
  background: #F2EEDF;
}
@media (max-width: 1255px) {
.testimonial-block {
    padding-left: 20px;
    padding-right: 20px
}
  }
@media (max-width: 599px) {
.testimonial-block {
    margin-top: 15px;
    margin-bottom: 25px
}
  }
.testimonial-block .box {
    padding: 4vw;
    background: #63B3C1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
@media (max-width: 767px) {
.testimonial-block .box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      padding: 0
  }
    }
.testimonial-block .box .left {
      width: 35%;
      border: 5px solid #F2EEDF;
    }
@media (max-width: 767px) {
.testimonial-block .box .left {
        width: 100%;
        border: none
    }
      }
.testimonial-block .box .left img {
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
      }
.testimonial-block .box .right {
      width: calc(100% - 35%);
      padding: 4vw;
    }
@media (max-width: 767px) {
.testimonial-block .box .right {
        width: 100%;
        padding: 20px
    }
      }
.testimonial-block .box .right .testimonial-text,
      .testimonial-block .box .right span {
        font-family: 'alvar_semibold', 'Arial', 'sans-serif';
        font-style: italic;
        font-weight: 600;
        font-size: 25px;
        line-height: 150%;
        /* or 45px */

        letter-spacing: 0.05em;

        /* Beige */
        color: #F2EEDF;
        margin-top: 35px;
      }
@media (max-width: 1255px) {
.testimonial-block .box .right .testimonial-text,
      .testimonial-block .box .right span {
          font-size: 18px
      }
        }
.testimonial-block .box .right span {
        display: inline-block;
        font-family: 'alvar_light', 'Arial', 'sans-serif';
        line-height: 1.2;
      }
.testimonial-block .box .right span strong {
          font-family: 'alvar_semibold', 'Arial', 'sans-serif';
          display: block;
        }
.articles-block {
  position: relative;
  width: 100%;
  background: #F2EEDF;
  padding: 4vw 10vw;
  padding-bottom: 0;
}
@media (max-width: 1255px) {
.articles-block {
    padding-left: 20px;
    padding-right: 20px
}
  }
.articles-block .articles-block-title {
    font-family: 'alvar_black', 'Arial', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    line-height: 100%;
    /* identical to box height, or 45px */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* Bleu */
    color: #212C47;
    padding-bottom: 45px;
  }
@media (max-width: 1255px) {
.articles-block .articles-block-title {
      font-size: 32px
  }
    }
@media (max-width: 1024px) {
.articles-block .articles-block-title {
      font-size: 25px;
      line-height: 1;
      padding-bottom: 20px
  }
    }
.articles-block .articles {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
.articles-block .articles .article {
      width: calc(98% / 2);
      margin-bottom: 45px;
    }
@media (max-width: 599px) {
.articles-block .articles .article {
        width: 100%
    }
      }
.articles-block .articles .article img {
        display: block;
        width: 100%;
      }
.articles-block .articles .article .article-title {
        font-family: 'alvar_regular', 'Arial', 'sans-serif';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 1.2;
        /* identical to box height */

        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Bleu */
        color: #212C47;
        margin-bottom: 15px;
        padding-top: 25px;
      }
@media (max-width: 1255px) {
.articles-block .articles .article .article-title {
          font-size: 25px;
          line-height: 1
      }
        }
.articles-block .articles .article .article-text {
        font-family: 'alvar_regular', 'Arial', 'sans-serif';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
        /* or 30px */

        letter-spacing: 0.05em;

        /* Bleu */

        color: #212C47;
      }
.ctas-center {
  padding-top: 0;
  padding-bottom: 4vw;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 599px) {
.ctas-center {
    margin-bottom: 30px
}
  }
.avatanges-list {
  position: relative;
  width: 100%;
  background: #212C47;
  padding: 4vw 10vw;
  padding-bottom: 6vw;
}
@media (max-width: 1255px) {
.avatanges-list {
    padding-left: 20px;
    padding-right: 20px
}
  }
.avatanges-list .avatanges-title {
    width: 100%;

    font-family: 'alvar_black', 'Arial', 'sans-serif';
    font-style: normal;
    font-weight: 900;
    font-size: 38px;
    line-height: 100%;
    /* or 45px */

    letter-spacing: 0.05em;
    text-transform: uppercase;

    padding-bottom: 45px;

    /* Beige */
    color: #F2EEDF;
  }
@media (max-width: 599px) {
.avatanges-list .avatanges-title {
      font-size: 25px;
      padding-bottom: 20px
  }
    }
.avatanges-list .avantages {
    margin-top: 2.5vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
.avatanges-list .avantages .sub-title {
      width: 100%;
      font-size: 18px;
      color: #F2EEDF;
      margin-bottom: 1.5vw;
    }
.avatanges-list .avantages .avantage {
      width: calc(33.33% - 10px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 20px;
      border: 2px solid #F2EEDF;
      margin-bottom: 20px;
    }
@media (max-width: 1024px) {
.avatanges-list .avantages .avantage {
        padding: 15px
    }
      }
@media (max-width: 599px) {
.avatanges-list .avantages .avantage {
        width: 100%
    }
      }
.avatanges-list .avantages .avantage img {
        width: 25px;
        margin-right: 15px;
      }
.avatanges-list .avantages .avantage .sub-title {
        font-size: 14px;
        color: #F2EEDF;
        margin-bottom: 0;
      }
@media (max-width: 1024px) {
.avatanges-list .avantages .avantage .sub-title {
          font-size: 12px;
          line-height: 1.2
      }
        }
.testimonial-block-carousel {
  position: relative;
}
.testimonial-block-carousel .box, .testimonial-block-carousel .box.carousel-cell {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    min-height: 100%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
@media (max-width: 767px) {
.testimonial-block-carousel .box, .testimonial-block-carousel .box.carousel-cell {
      padding-bottom: 45px
  }
    }
@media (max-width: 599px) {
      .testimonial-block-carousel .box .testimonial-text, .testimonial-block-carousel .box.carousel-cell .testimonial-text {
        font-size: 16px;
      }

      .testimonial-block-carousel .box .right span, .testimonial-block-carousel .box.carousel-cell .right span {
        font-size: 16px;
      }

      .testimonial-block-carousel .box .left img, .testimonial-block-carousel .box.carousel-cell .left img {
        min-height: 420px;
      }
    }
.testimonial-block-carousel .flickity-page-dots {
		bottom: inherit;
		right: 50%;
		bottom: 20px;
		-webkit-transform: translate(50%, 0);
		    -ms-transform: translate(50%, 0);
		        transform: translate(50%, 0);
		width: auto;
		z-index: 200;
	}
@media (max-width: 767px) {
.testimonial-block-carousel .flickity-page-dots {
      bottom: 20px
	}
    }
.testimonial-block-carousel .flickity-page-dots .dot {
			display: inline-block;
			margin-bottom: 0;
			width: 20px;
			height: 20px;
			opacity: 1;
			background-color: transparent;
			border: 4px solid #F2EEDF;
			-webkit-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
.testimonial-block-carousel .flickity-page-dots .dot.is-selected {
				background-color: #F2EEDF;
			}
@media (max-width: 767px) {
.testimonial-block-carousel .flickity-page-dots .dot {
        display: inline-block;
        margin: 0 5px
		}
      }
.login-header .main-logo {
		display: none;
	}
.login-wrap {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
@media (max-width: 955px) {
.login-wrap {
		margin-top: 50px
}
	}
.login-wrap .save {
		margin-top: 25px;
	}
.login-wrap .left {
		width: 38vw;
		position: relative;
	}
.login-wrap .left .logo {
			width: 125px;
			position: absolute;
			left: 3vw;
			top: 2vw;

			display: none;
		}
@media (max-width: 955px) {
.login-wrap .left .logo {
				display: none;
				width: 475px
		}
			}
.login-wrap .left .form-wrap {
			width: 100%;
			padding: 0 3vw;
			position: absolute;
			top: 50%;
			-webkit-transform: translate(0,-50%);
			    -ms-transform: translate(0,-50%);
			        transform: translate(0,-50%);
		}
@media (max-width: 955px) {
.login-wrap .left .form-wrap {
				position: relative;
				top: 0;
				-webkit-transform: none;
				    -ms-transform: none;
				        transform: none;

				padding: 45px 20px
		}
			}
@media (max-width: 599px) {
.login-wrap .left .form-wrap {
				padding: 30px 20px
		}
			}
.login-wrap .left .title {
			font-size: 33px;
			margin-bottom: 25px;
		}
@media (max-width: 955px) {
.login-wrap .left .title {
				font-size: 24px;
				margin-bottom: 0
		}
			}
.login-wrap .left .sup-title {
			font-size: 15px;
			margin: 0;
			margin-bottom: 10px;
			text-transform: none;
		}
@media (max-width: 955px) {
.login-wrap .left .sup-title {
				margin-bottom: 0
		}
			}
.login-wrap .left .sub-title {
			display: block;
			font-size: 15px;
			margin-bottom: 35px;
			margin-top: 35px;
			line-height: 1.4;

			text-transform: none;
		}
@media (max-width: 1025px) {
.login-wrap .left .sub-title {
				margin-bottom: 20px;
				margin-top: 10px
		}
			}
@media (max-width: 955px) {
.login-wrap .left .sub-title {
				margin-bottom: 20px;
				margin-top: 20px
		}
			}
.login-wrap .left .sub-title a {
				color: #D31C1C;
			}
.login-wrap .left .forgot-password {
			color: #D31C1C;
			font-size: 15px;
			width: 100%;
			display: block;
			margin-top: 10px;
		}
.login-wrap .left .form-input  {
			margin-bottom: 0;
		}
.login-wrap .right {
		width: calc(100% - 38vw);
		min-height: 100vh;
		background-image: url("/img/site/login.jpg");
		background-size: cover;
	}
@media (max-width: 955px) {
.login-wrap .right {
			width: calc(100% - 475px)
	}
		}
@media (max-width: 955px) {
.login-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		    -ms-flex-direction: column-reverse;
		        flex-direction: column-reverse
}

		.login-wrap .left, .login-wrap .right {
			width: 100%;
			height: auto;
			min-height: inherit;
		}

		.login-wrap .right {
			height: 55vw;
			max-height: 340px;
			background-position: center center;
		}
	}
.account-creation .left {
		width: calc(100% - 40%);
		padding-top: 10vw;
	}
@media (max-width: 955px) {
.account-creation .left {
		  padding-top: 40px
	}
		}
.account-creation .left .steps {
			position: absolute;
		  right: 3vw;
		  top: 4vw;

			font-family: 'alvar_black', 'Arial', 'sans-serif';
			font-style: normal;
			font-weight: 900;
			font-size: 35px;
			line-height: 100%;
			/* identical to box height, or 35px */

			letter-spacing: 0.05em;
			text-transform: uppercase;

			/* Bleu */

			color: #212C47;
		}
@media (max-width: 955px) {
.account-creation .left .steps {
			  right: auto;
			  left: 20px;
			  top: 25px;
			  font-size: 26px
		}
			}
.account-creation .right {
		width: 40%;

		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}
.account-creation .right:before {
		  content: '';
		  position: absolute;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  left: 0;
		  background-color: rgba(33,44,71, .70);
		  z-index: 1;
		}
.account-creation .right ul {
			position: relative;
			z-index: 2;

			list-style-type: none;
			padding: 0;
			margin: 0;
		}
@media (max-width: 955px) {
.account-creation .right ul {
				display: none
		}
			}
.account-creation .right ul li {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				-webkit-box-pack: start;
				    -ms-flex-pack: start;
				        justify-content: flex-start;

				margin: 0;
				padding: 25px 0;

				font-family: 'alvar_regular', 'Arial', 'sans-serif';
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 25px;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				color: #F2EEDF;
				text-align: left;
			}
@media (max-width: 955px) {
.account-creation .right ul li {
				  font-size: 13px;
				  line-height: 1.2;
				  padding: 10px;
				  padding-left: 165px;
				  padding-right: 20px
			}
				}
.account-creation .right ul li img {
					display: block;
					width: 30px;
					margin-right: 20px;
				}
@media (max-width: 599px) {
.account-creation .right ul li img {
						width: 24px;
						margin-right: 10px
				}
					}
.account-creation .left .form-wrap {
		position: relative;
		top: 0;
		left: 0;
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;

		width: 100%;
		height: 100%;
		max-width: 840px;
		margin: 0 auto;

		display: -webkit-box;

		display: -ms-flexbox;

		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-align: start;
		    -ms-flex-align: start;
		        align-items: flex-start;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}
.account-creation .form-wrap form {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;

		padding-top: 15px;
	}
.account-creation .form-wrap form .third {
			width: calc(33.33% - 10px);
		}
.account-creation .form-wrap form .half {
			width: calc(50% - 10px);
		}
@media (max-width: 955px) {
.account-creation .form-wrap form .half {
				width: calc(50% - 4px)
		}
			}
@media (max-width: 955px) {
.account-creation .form-wrap form .half-file {
				width: 100%
		}
			}
.account-creation .form-wrap form .quarter {
			width: calc(25% - 10px);
		}
.account-creation .form-wrap form .save {
			min-width: 175px;
			margin-top: 25px;
			text-align: left;
		}
.account-creation .form-wrap form .form-input {
			margin-bottom: 20px;
		}
@media (max-width: 955px) {
.account-creation .form-wrap form .form-input {
				margin-bottom: 0px
		}
			}
.account-creation .form-wrap form .cols {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: start;
			    -ms-flex-align: start;
			        align-items: flex-start;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			-ms-flex-wrap: wrap;
			    flex-wrap: wrap;
		}
@media (max-width: 955px) {

			}
.account-creation .form-wrap form .checkbox-wrap {
			width: 100%;
		}
@media (max-width: 955px) {
.account-creation .form-wrap form .checkbox-wrap {
				width: 100%
		}
			}
.account-creation .form-wrap form .checkbox-wrap.half {
				width: 50%;
			}
@media (max-width: 955px) {
.account-creation .form-wrap form .checkbox-wrap.half {
					width: 100%
			}
				}
.account-creation .form-wrap form .checkbox-wrap .sub-title {
				width: 100%;
			}
@media (max-width: 955px) {
.account-creation .form-wrap form .checkbox-wrap .sub-title {
					margin-bottom: 25px
			}
				}
.account-creation .form-wrap form .checkbox-wrap .container {
				font-size: 15px;
				font-family: 'alvar_semibold', 'Arial', 'sans-serif';
				text-transform: uppercase;
				color: #212C47;
			}
.account-creation .form-wrap form .checkbox-wrap.thin .container {
					font-family: 'alvar_light', 'Arial', 'sans-serif';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 150%;
					text-transform: none;
					/* identical to box height, or 30px */
					letter-spacing: 0.05em;

					/* Bleu */
					color: #212C47;
				}
.account-creation .form-wrap form .checkbox-wrap.thin .container .checkmark {
						top: 4px;
					}
@media (max-width: 955px) {
		.account-creation .left, .account-creation .right {
			width: 100%;
		}
	}
.hero-nav {
	width: 100%;
	min-height: 500px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	    -ms-flex-align: end;
	        align-items: flex-end;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	padding: 0 10vw;
	background-image: url("/img/site/hero-admin-account.jpg");
	background-size: cover;
	background-position: center top;
}
@media (max-width: 1255px) {
.hero-nav {
    padding-left: 20px;
    padding-right: 20px
}
  }
@media (max-width: 955px) {
.hero-nav {
  	min-height: 375px
}
  }
@media (max-width: 599px) {
.hero-nav {
  	min-height: 305px;
  	padding-right: 0
}
  }
.hero-nav .left {
		padding-bottom: 20px;
	}
@media (max-width: 955px) {
.hero-nav .left {
			display: none
	}
		}
.hero-nav .left .title {
			color: #F2EEDF;
			font-size: 33px;
			margin-bottom: 5px;
		}
.hero-nav .left .sub-title {
			color: #F2EEDF;
			font-size: 16px;
		}
.hero-nav .right {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
@media (max-width: 599px) {
.hero-nav .right {
			width: 100%;
			overflow-y: hidden;
			overflow-x: auto
	}
		}
.hero-nav .right a {
			font-family: 'alvar_black', 'Arial', 'sans-serif';
			font-size: 14px;
			color: #F2EEDF;
			text-transform: uppercase;
			padding: 20px;
			-webkit-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			transition: all 0.2s ease;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;

			background: rgba(255,255,255, 0.123);
		}
@media (max-width: 955px) {
.hero-nav .right a {
			  padding: 15px 20px;
			  font-size: 14px;
			  margin-right: 5px
		}
			}
.hero-nav .right a.actif {
				background-color: #F2EEDF;
				color: #212C47;
			}
.hero-nav .right a:hover {
				background-color: #F2EEDF;
				color: #212C47;
			}
.admin-form-wrap {
	width: 100%;
	padding: 5vw 10vw;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
}
@media (max-width: 1255px) {
.admin-form-wrap {
    padding-left: 20px;
    padding-right: 20px
}
  }
@media (max-width: 955px) {
.admin-form-wrap {
  	padding-top: 75px
}
  }
.admin-form-wrap.with-aside {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		    -ms-flex-direction: row-reverse;
		        flex-direction: row-reverse;
	}
@media (max-width: 955px) {
.admin-form-wrap.with-aside {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			    -ms-flex-direction: column;
			        flex-direction: column
	}
	  }
.admin-form-wrap .third {
		width: calc(33.33% - 5px);
	}
.admin-form-wrap .half {
		width: calc(50% - 10px);
	}
@media (max-width: 955px) {
.admin-form-wrap .half {
			width: calc(50% - 4px)
	}
		}
@media (max-width: 955px) {
.admin-form-wrap .half-file {
			width: 100%
	}
		}
.admin-form-wrap .quarter {
		width: calc(25% - 5px);
	}
.admin-form-wrap .save {
		margin-top: 2vw;
	}
.admin-form-wrap .save.is-hidden {
			display: none;
		}
@media (max-width: 955px) {
.admin-form-wrap .form-input.full {
			margin-bottom: 10px
	}
		}
.admin-form-wrap .textarea-wrapper {
		position: relative;
	}
.admin-form-wrap .maxlength-count {
		position: absolute;
		bottom: 15px;
		right: 0;
		font-family: 'alvar_regular', 'Arial', 'sans-serif';
		color: #212C47;
		line-height: 1.5;
		font-size: 14px;
		padding: 0 5px;
		background: #fff;
	}
.admin-form-wrap .checkbox-wrap {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		margin-top: 2.5vw;
	}
.admin-form-wrap .checkbox-wrap .sub-title {
			width: 100%;
			margin-bottom: 3vw;
		}
.admin-form-wrap .checkbox-wrap .container {
			width: 32%;
			font-size: 14px;
			font-family: 'alvar_semibold', 'Arial', 'sans-serif';
			text-transform: uppercase;
			color: #212C47;
		}
.admin-form-wrap .testimonials-wrap {
		margin-top: 2.5vw;
		margin-bottom: 3vw;
		background-color: #E8E4D7;
		padding: 2vw;
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
.admin-form-wrap .testimonials-wrap .sub-title {
			width: 100%;
			margin-bottom: 2vw;
		}
.admin-form-wrap .testimonials-wrap .actions {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: end;
			    -ms-flex-pack: end;
			        justify-content: flex-end;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			margin-top: 10px;
		}
.admin-form-wrap .testimonials-wrap .actions .action {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				margin-right: 20px;
				cursor: pointer;
			}
.admin-form-wrap .testimonials-wrap .actions .action img {
					width: 20px;
					margin-right: 10px;
				}
.admin-form-wrap .testimonials-wrap .actions .action .sub-title {
					color: #212C47;
					font-size: 14px;
					margin-bottom: 0;
				}
.admin-form-wrap .photos-wrap {
		position: relative;
		margin-top: 2.5vw;
		margin-bottom: 3vw;
		background-color: #E8E4D7;
		padding: 30px;
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
.admin-form-wrap .photos-wrap .sub-title {
			width: 100%;
			margin-bottom: 2vw;
		}
.admin-form-wrap .photos-wrap .upper-label {
			position: absolute;
			top: 70px;
			right: 105px;
    	z-index: 5;
			font-family: 'alvar_regular', 'Arial', 'sans-serif';
			color: #fff;
			line-height: 1.5;
			font-size: 12px;
			padding: 0 5px;
			background: #212C47;
		}
.admin-form-wrap .photos-wrap .photo-row {
			position: relative;
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			margin-bottom: 2.5vw;

			background: url('/img/site/drag-icon.svg');
			background-position: right 10px center;
			background-repeat: no-repeat;
		}
.admin-form-wrap .photos-wrap .photo-row .left {
				width: calc(50% - 5px);
			}
.admin-form-wrap .photos-wrap .photo-row .dragger {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				    -ms-flex-direction: column;
				        flex-direction: column;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				-webkit-box-pack: center;
				    -ms-flex-pack: center;
				        justify-content: center;
				background: #212C47;
				padding: 25px;

				z-index: -1;
			}
.admin-form-wrap .photos-wrap .photo-row .right {
				position: relative;
				width: calc(50% - 5px);
				padding-left: 20px;
			}
.admin-form-wrap .photos-wrap .photo-row .right.smaller-emplois {
					width: calc(30% - 5px);
				}
.admin-form-wrap .photos-wrap .photo-row .right img {
					width: 100%;
				}
.admin-form-wrap .photos-wrap .photo-row .right .actions {
					width: 100%;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					    -ms-flex-align: center;
					        align-items: center;
					margin-top: 10px;
				}
.admin-form-wrap .photos-wrap .photo-row .right .actions .action {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						    -ms-flex-align: center;
						        align-items: center;
						margin-right: 20px;
						cursor: pointer;
					}
.admin-form-wrap .photos-wrap .photo-row .right .actions .action img {
							width: 20px;
							margin-right: 10px;
						}
.admin-form-wrap .photos-wrap .photo-row .right .actions .action .sub-title {
							color: #212C47;
							font-size: 14px;
							margin-bottom: 0;
						}
.pagination-wrap.admin {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;

	padding: 20px;
}
.pagination-wrap.admin .cta-wrap {
		margin: 0 5px;
	}
.add-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	cursor: pointer;
	margin-top: 20px;
}
.add-wrap .regular-text {
		display: inline-block;
		padding-right: 10px;
	}
.add-wrap img {
		width: 25px;
	}
.admin-job-list {
	width: 100%;
	padding: 5vw 10vw;
}
@media (max-width: 1255px) {
.admin-job-list {
		padding-left: 20px;
		padding-right: 20px
}
	}
.admin-job-list .cta-wrap {
		background-color: #D31C1C;
		margin-bottom: 2.5vw;
	}
.admin-job-list .job-wrap {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		background-color: #212C47;
		padding: 20px 20px 20px 30px;
		margin-bottom: 10px;

	}
.admin-job-list .job-wrap .left .sub-title {
				font-size: 18px;
				color: #F2EEDF;
				margin-bottom: 5px;
			}
.admin-job-list .job-wrap .left .sub-title a {
					color: inherit;
				}
.admin-job-list .job-wrap .left .regular-text {
				font-size: 14px;
				color: #F2EEDF;
			}
.admin-job-list .job-wrap .left .regular-text a {
					color: inherit;
					color: #D31C1C;
				}
.admin-job-list .job-wrap .right {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
		}
.admin-job-list .job-wrap .right .sub-title {
				font-size: 18px;
				color: #F2EEDF;
				margin-bottom: 5px;
			}
.admin-job-list .job-wrap .right .regular-text {
				font-size: 14px;
				color: #F2EEDF;
				padding: 0 25px;
				text-align: right;
			}
.admin-job-list .job-wrap .right .arrow {
				width: 40px;
				height: 40px;
				background-color: #63B3C1;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: center;
				    -ms-flex-pack: center;
				        justify-content: center;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				cursor: pointer;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
.admin-job-list .job-wrap .right .arrow img {
					width: 15px;
				}
.admin-job-list .job-wrap .right .arrow:hover {
					background-color: #D31C1C;
				}
.admin-job-list .job-wrap .right .duplicate {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				margin-right: 20px;
				cursor: pointer;
			}
.admin-job-list .job-wrap .right .duplicate img {
					width: 30px;
					margin-right: 10px;
				}
.admin-job-list .job-wrap .right .duplicate .sub-title {
					font-size: 14px;
					color: #63B3C1;
				}
.admin-job-list .job-wrap .right .type-emploi {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				margin-right: 20px;
			}
.admin-job-list .job-wrap .right .type-emploi img {
					width: 30px;
					margin-right: 10px;
				}
.admin-job-list .job-wrap .right .type-emploi .sub-title {
					font-size: 14px;
					color: #F2EEDF;
				}
.admin-job-list .job-wrap .right .download {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				margin-right: 20px;
			}
.admin-job-list .job-wrap .right .download img {
					width: 15px;
					margin-right: 10px;
				}
.admin-job-list .job-wrap .right .download .sub-title {
					font-size: 14px;
					color: #F2EEDF;
				}
.admin-job-list .job-wrap .right .status {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				padding: 0 20px;
				height: 40px;
				border: 2px solid #F2EEDF;
				margin-right: 20px;
			}
.admin-job-list .job-wrap .right .status p {
					color: #F2EEDF;
					font-size: 14px;
				}
@media (max-width: 599px) {
.admin-job-list .job-wrap {
		  -ms-flex-wrap: wrap;
		      flex-wrap: wrap

	}

		  .admin-job-list .job-wrap .left, .admin-job-list .job-wrap .right {
		  	width: 100%;
		  }

		  .admin-job-list .job-wrap .right {
		  	display: block;
		  }

		  	.admin-job-list .job-wrap .right .status {
		  		text-align: center;
		  		-webkit-box-pack: center;
		  		    -ms-flex-pack: center;
		  		        justify-content: center;
		  	}

		  	.admin-job-list .job-wrap .right .date {
		  		width: 100%;
		  		padding: 15px 0;
		  		margin: 0;
		  		text-align: left;
		  	}

		  		.admin-job-list .job-wrap .right .date .regular-text {
		  			text-align: left;
		  			padding: 0;
		  			margin: 0;
		  		}
		}
.admin-job-list .other-jobs {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		margin-top: 3vw;
		background-image: url("/img/site/dot.jpg");
		background-repeat: repeat-x;
		background-size: 35px;
	}
.admin-job-list .other-jobs .sub-title {
			font-size: 16px;
			color: #212C47;
			background-color: #F2EEDF;
			padding-right: 30px;
		}
.admin-job-list .other-jobs .sub-title.link {
				color: #63B3C1;
				padding-right: 0;
				padding-left: 30px;
				cursor: pointer;
			}
.half-section-wrap {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	margin-top: 3vw;
}
.half-section {
	width: calc(50% - 5px);
	margin-bottom: 3vw;
}
.half-section .sub-title {
		width: 100%;
		margin-bottom: 2vw;
	}
.half-section textarea {
		margin-bottom: 10px;
		height: 150px;
		min-height: 150px;
	}
.candidat-side-panel.show .side-panel {
			right: 0;
			z-index: 5000;
		}
.candidat-side-panel.show .overlay {
			opacity: 0.8;
			z-index: 2000;
		}
.candidat-side-panel .side-panel {
		width: 500px;
		height: 100vh;
		background-color: #212C47;
		position: fixed;
		top: 0;
		right: -500px;
		z-index: 100;
		padding: 5vw 3vw;
		overflow-y: scroll;
		-webkit-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
.candidat-side-panel .side-panel .close {
			position: absolute;
			top: 25px;
			right: 25px;
			cursor: pointer;
		}
.candidat-side-panel .side-panel .close img {
				width: 30px;
			}
.candidat-side-panel .side-panel .title {
			color: #F2EEDF;
			font-size: 30px;
			margin-bottom: 15px;
		}
.candidat-side-panel .side-panel .contact-info {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			    -ms-flex-direction: column;
			        flex-direction: column;
			-webkit-box-align: start;
			    -ms-flex-align: start;
			        align-items: flex-start;
			line-height: 1.5;
		}
.candidat-side-panel .side-panel .contact-info .contact {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				margin-right: 20px;
			}
.candidat-side-panel .side-panel .contact-info .contact img {
					width: 15px;
					margin-right: 10px;
				}
.candidat-side-panel .side-panel .contact-info .contact .sub-title {
					color: #F2EEDF;
					font-size: 15px;
					-webkit-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
				}
.candidat-side-panel .side-panel .contact-info .contact:hover .sub-title {
						color: #63B3C1;
					}
.candidat-side-panel .side-panel .poste-wrap {
			width: 100%;
			margin-top: 3vw;
		}
.candidat-side-panel .side-panel .poste-wrap .sub-title {
				color: #F2EEDF;
				font-size: 20px;
			}
.candidat-side-panel .side-panel .poste-wrap .sub-title.smaller {
					color: #63B3C1;
					font-size: 14px;
					margin-bottom: 10px;
				}
.candidat-side-panel .side-panel .download-wrap {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			margin-top: 3vw;
		}
.candidat-side-panel .side-panel .download-wrap .download {
				width: calc(50% - 5px);
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				-webkit-box-pack: justify;
				    -ms-flex-pack: justify;
				        justify-content: space-between;
				padding: 15px 20px;
				border: 2px solid #F2EEDF;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
.candidat-side-panel .side-panel .download-wrap .download img {
					width: 12px;
				}
.candidat-side-panel .side-panel .download-wrap .download .sub-title {
					font-size: 14px;
					color: #F2EEDF;
					-webkit-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
				}
.candidat-side-panel .side-panel .download-wrap .download:hover {
					background-color: #F2EEDF;
				}
.candidat-side-panel .side-panel .download-wrap .download:hover .sub-title {
						color: #212C47;
					}
.candidat-side-panel .side-panel .message-wrap {
			width: 100%;
			margin-top: 2.5vw;
		}
.candidat-side-panel .side-panel .message-wrap .sub-title.smaller {
					color: #63B3C1;
					font-size: 14px;
					margin-bottom: 10px;
				}
.candidat-side-panel .side-panel .message-wrap .regular-text {
				color: #F2EEDF;
				line-height: 1.5;
				font-size: 14px;
			}
.candidat-side-panel .side-panel .status-wrap {
			width: 100%;
			margin-top: 2.5vw;
		}
.candidat-side-panel .side-panel .status-wrap .sub-title.smaller {
					color: #63B3C1;
					font-size: 14px;
					margin-bottom: 20px;
				}
.candidat-side-panel .side-panel .status-wrap .container {
				font-family: 'alvar_semibold', 'Arial', 'sans-serif';
				text-transform: uppercase;
				font-size: 14px;
				color: #F2EEDF;
			}
.candidat-side-panel .side-panel .status-wrap .checkmark {
				background-color: #212C47;
				border: 3px solid #F2EEDF;
			}
.candidat-side-panel .side-panel .status-wrap .container input:checked ~ .checkmark {
				background-color: #D31C1C;
				border: 3px solid #D31C1C;
			}
.candidat-side-panel .side-panel .note-wrap {
			margin-top: 2.5vw;
		}
.candidat-side-panel .side-panel .note-wrap .sub-title.smaller {
					color: #63B3C1;
					font-size: 14px;
					margin-bottom: 20px;
				}
.candidat-side-panel .side-panel .interets-wrap {
			margin-top: 2.5vw;
		}
.candidat-side-panel .side-panel .interets-wrap .sub-title.smaller {
					color: #63B3C1;
					font-size: 14px;
					margin-bottom: 10px;
					margin-top: 2.5vw;
				}
.candidat-side-panel .side-panel .interets-wrap .regular-text {
				color: #F2EEDF;
				margin-bottom: 10px;
			}
.candidat-side-panel .side-panel .save {
			background-color: #D31C1C;
			margin-top: 2.5vw;
		}
.candidat-side-panel .side-panel .save.is-hidden {
				display: none;
			}
.candidat-side-panel .overlay {
		position: fixed;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: #F2EEDF;
		opacity: 0;
		z-index: -1;
		-webkit-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}
.outils-wrap {
	width: 100%;
	padding: 5vw 10vw;
}
.outils-wrap .title {
		font-size: 24px;
		margin-bottom: 10px;
	}
.outils-wrap .regular-text {
		font-size: 16px;
		line-height: 1.5;
		width: 50%;
	}
.outils-wrap .tool-box-wrap {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		margin-top: 2.5vw;
	}
.outils-wrap .tool-box-wrap .tool-box {
			width: calc(50% - 5px);
			background-color: #212C47;
			padding: 3vw;
			position: relative;
			margin-bottom: 10px;
		}
.outils-wrap .tool-box-wrap .tool-box .title {
				color: #F2EEDF;
				font-size: 24px;
				margin-bottom: 2vw;
			}
.outils-wrap .tool-box-wrap .tool-box .puce {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				margin-bottom: 20px;
			}
.outils-wrap .tool-box-wrap .tool-box .puce img {
					width: 30px;
					margin-right: 10px;
				}
.outils-wrap .tool-box-wrap .tool-box .puce .sub-title {
					color: #F2EEDF;
					font-size: 16px;
				}
.outils-wrap .tool-box-wrap .tool-box .arrow-box {
				width: 40px;
				height: 40px;
				background-color: #63B3C1;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				-webkit-box-pack: center;
				    -ms-flex-pack: center;
				        justify-content: center;
				position: absolute;
				right: 1vw;
				bottom: 1vw;

			}
.outils-wrap .tool-box-wrap .tool-box .arrow-box img {
					width: 20px;
				}
.helper-zone {
	margin: 0;
	margin-top: 0;
	margin-bottom: 50px;
	background: #fff;
	padding: 25px 20px;
	color: #212C47;
	line-height: 1.5;
	border-radius: 4px;
	border-left: 4px solid #212C47;
}
.helper-zone p {
		position: relative;
		top: 0;
		left: 0;
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
	}
.helper-zone strong {
		display: inline-block;
		padding-bottom: 5px;
		font-size: 120%;
		font-weight: 700;
		text-transform: uppercase;
	}
.helper-zone span {
		color: #D31C1C;
	}
.btns-wrap {
	margin-top: 2vw;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
}
.btns-wrap input,
	.btns-wrap input.save {
		margin: 0;
		margin-right: 45px;
	}
.btns-wrap input.is-hidden, .btns-wrap input.save.is-hidden {
			display: none;
		}
.swal2-container {
	z-index: 9999;
}
.form-fields {
	width: 70%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;

	-ms-flex-wrap: wrap;

	    flex-wrap: wrap;

	padding-right: 35px;
}
@media (max-width: 955px) {
.form-fields {
		width: 100%;
		padding-right: 0
}
  }
.form-fields .regular-text.placeholder-label,
	.form-fields .regular-text.placeholder-label a {
		width: 50%;
		color: #212C47;

		-moz-text-align-last: left;

		     text-align-last: left;
	}
.form-fields .regular-text.placeholder-label:last-child, .form-fields .regular-text.placeholder-label a:last-child {
			text-align: right;
		}
.form-aside {
	width: 30%;
	padding: 35px;
	margin-bottom: 130px;
	background: #212C47;
}
@media (max-width: 955px) {
.form-aside {
		width: 100%;
		margin-top: 0;
		margin-bottom: 45px
}
  }
.form-aside .checkbox-wrap {
		margin: 0;
		padding: 0;

		color: #F2EEDF;
	}
.form-aside .checkbox-wrap label {
			display: inline-block;
		}
.form-aside .checkbox-wrap .sub-title {
			color: #F2EEDF;
		}
.form-aside .checkbox-wrap .container {
			width: 100%;
			color: #F2EEDF;
		}
.form-with-cols {
	width: 80%;
}
@media (max-width: 599px) {
.form-with-cols {
		width: 100%;
		padding-right: 20px
}
	}
.form-with-cols .cols {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: start;
		    -ms-flex-align: start;
		        align-items: flex-start;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
	}
@media (max-width: 599px) {
.form-with-cols .cols {
		  -ms-flex-wrap: wrap;
		      flex-wrap: wrap
	}
		}
.form-with-cols .checkbox-wrap {
		width: 100%;
	}
.form-with-cols .checkbox-wrap .sub-title {
			width: 100%;
		}
.form-with-cols .checkbox-wrap .container {
			width: 100%;
			font-size: 14px;
			font-family: 'alvar_semibold', 'Arial', 'sans-serif';
			text-transform: uppercase;
			color: #212C47;
		}
.form-with-cols .checkbox-wrap.thin {
			margin-top: 0;
		}
.form-with-cols .checkbox-wrap.thin .container {
				font-family: 'alvar_regular', 'Arial', 'sans-serif';
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 150%;
				text-transform: none;
				/* identical to box height, or 30px */
				letter-spacing: 0.05em;

				/* Bleu */
				color: #212C47;
			}
@media (max-width: 599px) {
.form-with-cols .checkbox-wrap.thin .container {
				  font-size: 16px;
				  line-height: 1.3
			}
				}
.form-with-cols .checkbox-wrap.thin .container .checkmark {
					top: 4px;
				}
.separator {
	height: 2px;
	width: 100%;
	background: #D31C1C;
	border: none;
}
.sub-form-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;

	width: 100%;
	padding: 5vw 10vw;
}
@media (max-width: 1255px) {
.sub-form-container {
    padding-left: 20px;
    padding-right: 20px
}
  }
@media (max-width: 955px) {
.sub-form-container {
  	-webkit-box-orient: vertical;
  	-webkit-box-direction: normal;
  	    -ms-flex-direction: column;
  	        flex-direction: column;
  	padding-top: 45px
}
  }
.sub-form-container .sub-form {
		width: calc(90% / 2);
		padding: 0;
	}
@media (max-width: 955px) {
.sub-form-container .sub-form {
			width: 100%;
			padding-bottom: 45px;
			margin-bottom: 45px;
			border-bottom: 2px solid #212C47
	}
		}
.inputs-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;

	margin-top: 25px;
}
@media (max-width: 599px) {
.inputs-wrap {
	  -webkit-box-orient: vertical;
	  -webkit-box-direction: normal;
	      -ms-flex-direction: column;
	          flex-direction: column;
	  -webkit-box-align: start;
	      -ms-flex-align: start;
	          align-items: flex-start;
	  width: 100%
}
	}
.inputs-wrap input, .inputs-wrap .cta-wrap, .inputs-wrap .save {
		margin: 0;
		margin-right: 25px;
		top: 0;
	}
@media (max-width: 599px) {
.inputs-wrap input, .inputs-wrap .cta-wrap, .inputs-wrap .save {
			margin-bottom: 15px;
			width: 100%
	}
		}
.inputs-wrap input.alternate, .inputs-wrap .cta-wrap.alternate, .inputs-wrap .save.alternate {
			background-color: #63B3C1;
		}
.inputs-wrap input.alternate:hover, .inputs-wrap .cta-wrap.alternate:hover, .inputs-wrap .save.alternate:hover {
				background: #212C47;
			}
.inputs-wrap .cta-wrap.alternate {

	}
[data-statut="Ignorer/Cacher"] {
	opacity: 0.125;
}
.select-lang-wrap {
	width: 100%;
    padding: 5vw 10vw;
	padding-bottom: 0;
	max-width: 45%;
}
.hero-all-entreprises {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 75px;
}
@media (max-width: 1024px) {
.hero-all-entreprises {
    margin-top: 50px
}
  }
.hero-all-entreprises .left {
		width: 50%;
		height: 40vw;
		background-image: url("/img/site/hero-entreprises.jpg");
		background-size: cover;
		background-position: center center;
		position: relative;
	}
.hero-all-entreprises .left img {
			position: absolute;
		}
@media (max-width: 1024px) {
.hero-all-entreprises .left img {
				display: none
		}
			}
.hero-all-entreprises .left #work1 {
			width: 3vw;
			top: 26vw;
			left: 5vw;
			opacity: 0;
			-webkit-animation-name: work1;
			        animation-name: work1;
  			-webkit-animation-duration: 1s;
  			        animation-duration: 1s;
  			-webkit-animation-delay: 0s;
  			        animation-delay: 0s;
  			-webkit-animation-fill-mode: forwards;
  			        animation-fill-mode: forwards;
  			-webkit-animation-timing-function: ease;
  			        animation-timing-function: ease;
		}
@-webkit-keyframes work1 {
		  from {top: 16vw; opacity: 0;}
		  to {top: 21vw; opacity: 1;}
		}
@keyframes work1 {
		  from {top: 16vw; opacity: 0;}
		  to {top: 21vw; opacity: 1;}
		}
.hero-all-entreprises .left #work2 {
			width: 4.5vw;
			top: 20vw;
			left: 40vw;
			opacity: 0;
			-webkit-animation-name: work2;
			        animation-name: work2;
  			-webkit-animation-duration: 1s;
  			        animation-duration: 1s;
  			-webkit-animation-delay: 0.6s;
  			        animation-delay: 0.6s;
  			-webkit-animation-fill-mode: forwards;
  			        animation-fill-mode: forwards;
  			-webkit-animation-timing-function: ease;
  			        animation-timing-function: ease;
		}
@-webkit-keyframes work2 {
		  from {top: 15vw; opacity: 0;}
		  to {top: 20vw; opacity: 1;}
		}
@keyframes work2 {
		  from {top: 15vw; opacity: 0;}
		  to {top: 20vw; opacity: 1;}
		}
.hero-all-entreprises .left #work3 {
			width: 5vw;
			top: 24vw;
			left: 16vw;
			opacity: 0;
			-webkit-animation-name: work3;
			        animation-name: work3;
  			-webkit-animation-duration: 1s;
  			        animation-duration: 1s;
  			-webkit-animation-delay: 1.2s;
  			        animation-delay: 1.2s;
  			-webkit-animation-fill-mode: forwards;
  			        animation-fill-mode: forwards;
  			-webkit-animation-timing-function: ease;
  			        animation-timing-function: ease;
		}
@-webkit-keyframes work3 {
		  from {top: 19vw; opacity: 0;}
		  to {top: 24vw; opacity: 1;}
		}
@keyframes work3 {
		  from {top: 19vw; opacity: 0;}
		  to {top: 24vw; opacity: 1;}
		}
.hero-all-entreprises .left #play1 {
			width: 3vw;
			top: 13vw;
			left: 43vw;
			opacity: 0;
			-webkit-animation-name: play1;
			        animation-name: play1;
  			-webkit-animation-duration: 1s;
  			        animation-duration: 1s;
  			-webkit-animation-delay: 0.2s;
  			        animation-delay: 0.2s;
  			-webkit-animation-fill-mode: forwards;
  			        animation-fill-mode: forwards;
  			-webkit-animation-timing-function: ease;
  			        animation-timing-function: ease;
		}
@-webkit-keyframes play1 {
		  from {top: 8vw; opacity: 0;}
		  to {top: 13vw; opacity: 1;}
		}
@keyframes play1 {
		  from {top: 8vw; opacity: 0;}
		  to {top: 13vw; opacity: 1;}
		}
.hero-all-entreprises .left #play2 {
			width: 3.9vw;
			top: 18vw;
			left: 21vw;
			opacity: 0;
			-webkit-animation-name: play2;
			        animation-name: play2;
  			-webkit-animation-duration: 1s;
  			        animation-duration: 1s;
  			-webkit-animation-delay: 0.8s;
  			        animation-delay: 0.8s;
  			-webkit-animation-fill-mode: forwards;
  			        animation-fill-mode: forwards;
  			-webkit-animation-timing-function: ease;
  			        animation-timing-function: ease;
		}
@-webkit-keyframes play2 {
		  from {top: 13vw; opacity: 0;}
		  to {top: 18vw; opacity: 1;}
		}
@keyframes play2 {
		  from {top: 13vw; opacity: 0;}
		  to {top: 18vw; opacity: 1;}
		}
.hero-all-entreprises .left #play3 {
			width: 4.8vw;
			top: 30vw;
			left: 30vw;
			opacity: 0;
			-webkit-animation-name: play3;
			        animation-name: play3;
  			-webkit-animation-duration: 1s;
  			        animation-duration: 1s;
  			-webkit-animation-delay: 1.4s;
  			        animation-delay: 1.4s;
  			-webkit-animation-fill-mode: forwards;
  			        animation-fill-mode: forwards;
  			-webkit-animation-timing-function: ease;
  			        animation-timing-function: ease;
		}
@-webkit-keyframes play3 {
		  from {top: 25vw; opacity: 0;}
		  to {top: 30vw; opacity: 1;}
		}
@keyframes play3 {
		  from {top: 25vw; opacity: 0;}
		  to {top: 30vw; opacity: 1;}
		}
.hero-all-entreprises .left #relax1 {
			width: 2.8vw;
			top: 12.5vw;
			left: 27vw;
			opacity: 0;
			-webkit-animation-name: relax1;
			        animation-name: relax1;
  			-webkit-animation-duration: 1s;
  			        animation-duration: 1s;
  			-webkit-animation-delay: 0.4s;
  			        animation-delay: 0.4s;
  			-webkit-animation-fill-mode: forwards;
  			        animation-fill-mode: forwards;
  			-webkit-animation-timing-function: ease;
  			        animation-timing-function: ease;
		}
@-webkit-keyframes relax1 {
		  from {top: 7vw; opacity: 0;}
		  to {top: 12.5vw; opacity: 1;}
		}
@keyframes relax1 {
		  from {top: 7vw; opacity: 0;}
		  to {top: 12.5vw; opacity: 1;}
		}
.hero-all-entreprises .left #relax2 {
			width: 5vw;
			top: 31vw;
			left: 10vw;
			opacity: 0;
			-webkit-animation-name: relax2;
			        animation-name: relax2;
  			-webkit-animation-duration: 1s;
  			        animation-duration: 1s;
  			-webkit-animation-delay: 1s;
  			        animation-delay: 1s;
  			-webkit-animation-fill-mode: forwards;
  			        animation-fill-mode: forwards;
  			-webkit-animation-timing-function: ease;
  			        animation-timing-function: ease;
		}
@-webkit-keyframes relax2 {
		  from {top: 26vw; opacity: 0;}
		  to {top: 31vw; opacity: 1;}
		}
@keyframes relax2 {
		  from {top: 26vw; opacity: 0;}
		  to {top: 31vw; opacity: 1;}
		}
.hero-all-entreprises .right {
		width: 50%;
		height: 40vw;
		background-color: #212C47;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		padding: 8vw 6vw;
	}
@media (max-width: 1255px) {
.hero-all-entreprises .right {
			padding-left: 45px;
			padding-right: 45px
	}
		}
@media (max-width: 955px) {
.hero-all-entreprises .right {
			height: auto;
			padding: 30px 20px
	}
		}
.hero-all-entreprises .right .title {
			color: #F2EEDF;
			font-size: 55px;
			margin-bottom: 1vw;
		}
@media (max-width: 955px) {
.hero-all-entreprises .right .title {
				font-size: 42px
		}
			}
.hero-all-entreprises .right .sub-title {
			color: #F2EEDF;
			font-size: 22px;
			width: 70%;
			line-height: 1.2;
		}
.hero-all-entreprises .right .stats {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
		}
.hero-all-entreprises .right .stats .stat {
				text-align: center;
				margin-right: 5vw;
				margin-top: 2vw;
			}
.hero-all-entreprises .right .stats .stat .number {
					font-size: 100px;
					color: #D31C1C;
					line-height: 1;
					font-family: 'alvar_light', 'Arial', 'sans-serif';
				}
@media (max-width: 955px) {
.hero-all-entreprises .right .stats .stat .number {
						font-size: 65px
				}
					}
.hero-all-entreprises .right .stats .stat .sub-title {
					font-size: 18px;
					width: 100%;
				}
@media (max-width: 955px) {
.hero-all-entreprises {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap
}

		.hero-all-entreprises .left, .hero-all-entreprises .right {
			width: 100%;
		}

		.hero-all-entreprises .data {
			width: 100%;
		}
	}
.hero-entreprise {
	width: 100%;
	margin-top: 75px;
	position: relative;
}
@media (max-width: 1024px) {
.hero-entreprise {
    margin-top: 50px
}
  }
.hero-entreprise .carousel-cell {
		width: 100%;
		height: 70vh;
		position: relative;
		z-index: 10;

		background-size: cover;
		background-position: center center;
	}
.hero-entreprise .carousel-cell .play {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%,-50%);
			    -ms-transform: translate(-50%,-50%);
			        transform: translate(-50%,-50%);
			width: 80px;
			cursor: pointer;
		}
.hero-entreprise .flickity-page-dots {
		bottom: inherit;
		right: 20px;
		top: 50%;
		-webkit-transform: translate(0,-50%);
		    -ms-transform: translate(0,-50%);
		        transform: translate(0,-50%);
		width: auto;
		z-index: 200;
	}
.hero-entreprise .flickity-page-dots .dot {
			display: block;
			margin-bottom: 15px;
			width: 20px;
			height: 20px;
			opacity: 1;
			background-color: transparent;
			border: 4px solid #F2EEDF;
			-webkit-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
.hero-entreprise .flickity-page-dots .dot.is-selected {
				background-color: #F2EEDF;
			}
.hero-entreprise .gradient {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 100;
		width: 120px;
		height: 100%;
		background: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0)), to(rgba(0,0,0,1)));
		background: -o-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
		background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
		opacity: 0.5;
	}
.entreprise-profil-header {
	width: 100%;
	height: 130px;
	padding: 0 10vw;
	position: relative;
	background-color: #F2EEDF;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
@media (max-width: 1024px) {
.entreprise-profil-header {
		padding-left: 20px;
		padding-right: 20px
}
	}
.entreprise-profil-header .profil-picture {
		width: 200px;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%,-50%);
		    -ms-transform: translate(-50%,-50%);
		        transform: translate(-50%,-50%);
	}
@media (max-width: 599px) {
.entreprise-profil-header .profil-picture {
			width: 140px;
			top: 0;
			left: 0;
			-webkit-transform: translate( 0, -100%);
			    -ms-transform: translate( 0, -100%);
			        transform: translate( 0, -100%)
	}
		}
.entreprise-profil-header .title {
		font-size: 28px;
		max-width: 25vw;
	}
@media (max-width: 599px) {
.entreprise-profil-header .title {
			max-width: 100%
	}
		}
.entreprise-profil-header .cta-wrap img {
			-webkit-transform: rotate(90deg);
			    -ms-transform: rotate(90deg);
			        transform: rotate(90deg);
		}
.entreprise-profil-header .cta-wrap:hover {
			background-color: #D31C1C;
		}
@media (max-width: 599px) {
.entreprise-profil-header .cta-wrap {
			display: none
	}
		}
.entreprise-informations {
	width: 100%;
	background-color: #212C47;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	padding: 6vw 10vw 10vw 10vw;
}
@media (max-width: 1255px) {
.entreprise-informations {
		padding-left: 20px;
		padding-right: 20px
}
	}
.entreprise-informations .left {
		width: 47%;
	}
.entreprise-informations .left .sub-title {
			font-size: 18px;
			color: #F2EEDF;
			margin-bottom: 1.5vw;
		}
.entreprise-informations .left .regular-text {
			color: #F2EEDF;
		}
.entreprise-informations .left address {
			margin-top: 1.5vw;
		}
@media (max-width: 1025px) {
.entreprise-informations .left address {
			  margin-top: 35px;
			  margin-bottom: 15px
		}
			}
.entreprise-informations .left address a {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
			}
.entreprise-informations .left address a img {
					width: 25px;
					margin-right: 15px;
				}
.entreprise-informations .right {
		width: 47%;
	}
.entreprise-informations .right .stat {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			margin-bottom: 3.5vw;
			background-image: url("/img/site/dot-2.jpg");
			background-repeat: repeat-x;
			background-size: 30px;
			background-position: center center;
		}
.entreprise-informations .right .stat .title {
				font-size: 40px;
				color: #F2EEDF;
				background-color: #212C47;
				padding-right: 20px;
			}
@media (max-width: 599px) {
.entreprise-informations .right .stat .title {
					font-size: 26px
			}
        }
.entreprise-informations .right .stat .sub-title {
				font-size: 16px;
				color: #F2EEDF;
				background-color: #212C47;
				padding-left: 20px;
			}
@media (max-width: 955px) {
.entreprise-informations {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap
}

		.entreprise-informations .left, .entreprise-informations .right {
			width: 100%;
			padding-bottom: 45px;
		}
	}
.entreprise-informations .avantages {
		margin-top: 2.5vw;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-webkit-box-align: stretch;
		    -ms-flex-align: stretch;
		        align-items: stretch;
	}
.entreprise-informations .avantages .sub-title {
			width: 100%;
			font-size: 18px;
			color: #F2EEDF;
			margin-bottom: 1.5vw;
		}
.entreprise-informations .avantages .avantage {
			width: calc(33.33% - 10px);
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			padding: 20px;
			border: 2px solid #F2EEDF;
			margin-bottom: 20px;
		}
@media (max-width: 599px) {
.entreprise-informations .avantages .avantage {
				width: 100%
		}
			}
.entreprise-informations .avantages .avantage img {
				width: 25px;
				margin-right: 15px;
			}
.entreprise-informations .avantages .avantage .sub-title {
				font-size: 14px;
				color: #F2EEDF;
				margin-bottom: 0;
			}
.testimonials-wrap {
	padding: 0 10vw;
	margin-top: -5vw;
}
@media (max-width: 1255px) {
.testimonials-wrap {
		padding-left: 20px;
		padding-right: 20px
}
	}
.testimonials-wrap .main-carousel {
		width: 100%;
		background-color: #63B3C1;
		padding: 7vw 0 9vw 0;
	}
.testimonials-wrap .main-carousel .carousel-cell {
			width: 100%;
			text-align: center;
		}
.testimonials-wrap .main-carousel .carousel-cell img {
				width: 80px;
				display: inline-block;
				margin-bottom: 2.5vw;
			}
.testimonials-wrap .main-carousel .carousel-cell .quote {
				text-transform: none;
				font-size: 25px;
				font-style: italic;
				margin-bottom: 2.5vw;
				padding: 0 10vw;
				color: #F2EEDF;
				line-height: 1.3;
			}
.testimonials-wrap .main-carousel .carousel-cell .name {
				font-size: 16px;
				color: #F2EEDF;
			}
.testimonials-wrap .main-carousel .carousel-cell .poste {
				color: #F2EEDF;
			}
.testimonials-wrap .main-carousel .flickity-page-dots {
			bottom: 2vw;
		}
.testimonials-wrap .main-carousel .flickity-page-dots .dot {
				width: 20px;
				height: 20px;
				opacity: 1;
				background-color: transparent;
				border: 4px solid #F2EEDF;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
.testimonials-wrap .main-carousel .flickity-page-dots .dot.is-selected {
					background-color: #F2EEDF;
				}
.entreprise-job-list .title {
		font-size: 28px;
		margin-bottom: 1.5vw;
	}
.entreprise-job-list .job-wrap .left .sub-title {
				margin-bottom: 0;
			}
.entreprise-job-list .job-wrap .right .regular-text {
				color: #F2EEDF;
			}
@media (max-width: 599px) {
.entreprise-job-list .job-wrap {
			-ms-flex-wrap: wrap;
			    flex-wrap: wrap;
			padding: 15px
	}

			.entreprise-job-list .job-wrap .left, .entreprise-job-list .job-wrap .right {
				width: 100%;
			}

			.entreprise-job-list .job-wrap .left {
				padding-bottom: 25px;
			}

				.entreprise-job-list .job-wrap .left .sub-title {
					line-height: 1.3;
				}

			.entreprise-job-list .job-wrap .right {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: justify;
				    -ms-flex-pack: justify;
				        justify-content: space-between;
			}
		}
.entreprise-job-list.item-half {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-webkit-box-align: stretch;
		    -ms-flex-align: stretch;
		        align-items: stretch;

		-ms-flex-wrap: wrap;

		    flex-wrap: wrap;
	}
.entreprise-job-list.item-half .title {
			width: 100%;
		}
.entreprise-job-list.item-half .job-wrap {
			width: calc(98% / 2);
		}
@media (max-width: 955px) {
.entreprise-job-list.item-half .job-wrap {
				width: 100%
		}
			}
.entreprise-job-list.item-half .job-wrap:hover .arrow {
					background-color: #D31C1C;
				}
.candidature-form {
	width: 100%;
	background-color: #63B3C1;
	padding: 7vw 20vw;
}
@media (max-width: 1255px) {
.candidature-form {
		padding-left: 20px;
		padding-right: 20px
}
	}
.candidature-form .user-account-box {
		width: 100%;
		background-color: #212C47;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		padding: 2vw;
		margin-bottom: 1.5vw;

	}
@media (max-width: 955px) {
.candidature-form .user-account-box {
			padding: 20px

	}
		}
.candidature-form .user-account-box .left {
			width: 50%;
		}
.candidature-form .user-account-box .left .sub-title {
				font-size: 14px;
				color: #63B3C1;
				margin-bottom: 20px;
			}
.candidature-form .user-account-box .left .regular-text {
				color: #F2EEDF;
				text-align: left;
			}
@media (max-width: 1025px) {
.candidature-form .user-account-box .left .regular-text {
					text-align: left;
					font-size: 18px;
					line-height: 1.2
			}
				}
.candidature-form .user-account-box .right {
			width: 50%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: end;
			    -ms-flex-pack: end;
			        justify-content: flex-end;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
		}
@media (max-width: 955px) {
.candidature-form .user-account-box .right {
				-webkit-box-align: start;
				    -ms-flex-align: start;
				        align-items: flex-start;
				-webkit-box-pack: start;
				    -ms-flex-pack: start;
				        justify-content: flex-start
		}
			}
@media (max-width: 599px) {
.candidature-form .user-account-box .right {
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				    -ms-flex-direction: column;
				        flex-direction: column
		}
      }
.candidature-form .user-account-box .right .cta-wrap {
				background-color: #63B3C1;
				margin-left: 10px;
			}
@media (max-width: 955px) {
.candidature-form .user-account-box .right .cta-wrap {
					margin-left: 0;
					margin-right: 10px
			}
				}
@media (max-width: 599px) {
.candidature-form .user-account-box .right .cta-wrap {
					width: 100%;
					margin: 5px 0
			}
        }
.candidature-form .user-account-box .right .cta-wrap:hover {
					background-color: #D31C1C;
				}
@media (max-width: 955px) {
.candidature-form .user-account-box {
			-ms-flex-wrap: wrap;
			    flex-wrap: wrap;
			-webkit-box-align: start;
			    -ms-flex-align: start;
			        align-items: flex-start;
			text-align: left

	}

			.candidature-form .user-account-box .left, .candidature-form .user-account-box .right {
				width: 100%;
			}
		}
.candidature-form .user-profile-box {
		width: 100%;
		background: url('/img/site/icon-double-down.svg') center right+25px no-repeat;
		background-color: #212C47;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: felx-start;
		    -ms-flex-pack: felx-start;
		        justify-content: felx-start;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		padding: 2vw;
		margin-bottom: 1.5vw;

		color: #F2EEDF;

	}
@media (max-width: 599px) {
.candidature-form .user-profile-box {
			padding: 10px;
			background: #212C47

	}
		}
.candidature-form .user-profile-box img {
			display: block;
			width: 120px;
		}
@media (max-width: 599px) {
.candidature-form .user-profile-box img {
				width: 88px
		}
			}
.candidature-form .user-profile-box p {
			padding-left: 25px;
			font-family: 'alvar_light', 'Arial', 'sans-serif';
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 1.5;
			letter-spacing: 0.05em;
		}
@media (max-width: 599px) {
.candidature-form .user-profile-box p {
				font-size: 14px;
				line-height: 1.1;
				margin-bottom: 5px
		}
			}
.candidature-form .user-profile-box p strong {
				display: block;
				font-family: 'alvar_semibold', 'Arial', 'sans-serif';
				text-transform: uppercase;
				font-size: 20px;
			}
@media (max-width: 599px) {
.candidature-form .user-profile-box p strong {
					font-size: 18px;
					padding-bottom: 5px
			}
				}
.candidature-form form {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
.candidature-form .title {
		width: 100%;
		font-size: 28px;
		margin-bottom: 1vw;
		color: #F2EEDF;
		text-align: center;
	}
@media (max-width: 955px) {
.candidature-form .title {
			margin-bottom: 25px
	}
		}
@media (max-width: 599px) {
.candidature-form .title {
			margin-bottom: 10px
	}
	  }
.candidature-form .regular-text {
		width: 100%;
		font-size: 22px;
		margin-bottom: 2.5vw;
		color: #F2EEDF;
		text-align: center;
	}
@media (max-width: 599px) {
.candidature-form .regular-text {
			font-size: 18px;
			line-height: 1.2;
			margin-bottom: 25px
	}
		}
.candidature-form .third {
		width: calc(33.33% - 5px);
	}
.candidature-form .half {
		width: calc(50% - 5px);
	}
@media (max-width: 955px) {
.candidature-form .half-file {
			width: 100%
	}
		}
.candidature-form .quarter {
		width: calc(25% - 5px);
	}
.candidature-form textarea {
		margin-bottom: 10px;
	}
.candidature-form .submit-wrap {
		width: 100%;
		margin-top: 2.5vw;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}
.emplois-hero {
	width: 100%;
	margin-top: 115px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media (max-width: 1024px) {
.emplois-hero {
		margin-top: 75px
}
	}
.emplois-hero .left {
		width: 50%;
		height: 25vw;
		background-image: url("/img/site/emplois-hero-1.jpg");
		background-size: cover;
		background-position: center center;
	}
.emplois-hero .right {
		width: 50%;
		height: 25vw;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
@media (max-width: 599px) {
.emplois-hero .right {
		  display: none
	}
		}
.emplois-hero .right .half {
			width: 50%;
		}
.emplois-hero .right .half.work {
				position: relative;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				-webkit-box-pack: center;
				    -ms-flex-pack: center;
				        justify-content: center;
				background-color: #212C47;
			}
.emplois-hero .right .half.work img {
					width: 10vw;
				}
.emplois-hero .right .half.work .sub-title {
					position: absolute;
					right: 20px;
					bottom: 20px;
					font-size: 18px;
					color: #F2EEDF;
				}
.emplois-hero .right .half.img {
				background-image: url("/img/site/emplois-hero-3.jpg");
				background-size: cover;
				background-position: center center;
			}
@media (max-width: 955px) {
.emplois-hero {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap
}

		.emplois-hero .left, .emplois-hero .right {
			width: 100%;
			height: 255px;
		}
	}
.emploi-details {
	width: 100%;
	margin-top: 75px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media (max-width: 1024px) {
.emploi-details {
    margin-top: 50px
}
  }
.emploi-details.fixed .left .main-carousel {
				position: fixed;
				top: 75px;
				left: 0;
				width: 50%;
			}
@media (max-width: 955px) {
.emploi-details.fixed .left .main-carousel {
					position: relative;
					width: 100%;
					top: 0;
					height: 80vw
			}

					.emploi-details.fixed .left .main-carousel .carousel-cell {
						height: 80vw;
					}
				}
.emploi-details .left {
		width: 50%;
		position: relative;
	}
.emploi-details .left .main-carousel {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: calc(100vh - 75px);
		}
.emploi-details .left .main-carousel .carousel-cell {
				width: 100%;
				height: calc(100vh - 75px);
				background-size: cover;
				background-position: center center;
			}
.emploi-details .left .main-carousel .carousel-cell .play {
					position: absolute;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%,-50%);
					    -ms-transform: translate(-50%,-50%);
					        transform: translate(-50%,-50%);
					width: 80px;
					cursor: pointer;
				}
.emploi-details .left .main-carousel .flickity-page-dots {
				bottom: 2vw;
			}
.emploi-details .left .main-carousel .flickity-page-dots .dot {
					width: 20px;
					height: 20px;
					opacity: 1;
					background-color: transparent;
					border: 4px solid #F2EEDF;
					-webkit-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
					z-index: 200;
				}
@media (max-width: 599px) {
.emploi-details .left .main-carousel .flickity-page-dots .dot {
            width: 13px;
            height: 13px;
            border: 2px solid #F2EEDF;
            margin: 0 4px
				}
          }
.emploi-details .left .main-carousel .flickity-page-dots .dot.is-selected {
						background-color: #F2EEDF;
					}
.emploi-details .left .logo {
			position: absolute;
			left: 45px;
			top: calc(90vh - 200px);
			z-index: 9;

			display: block;
			width: 200px;
			height: auto;
		}
.emploi-details .right {
		width: 50%;
		min-height: calc(100vh - 75px);
		background-color: #212C47;
		padding: 5vw;
	}
@media (max-width: 599px) {
.emploi-details .right {
			padding-bottom: 15px;
			padding-top: 45px
	}
	  }
.emploi-details .right .emplois-infos {
			margin-top: 25px;
			margin-bottom: 4vw;
			line-height: 1.5;
		}
.emploi-details .right .title {
			font-size: 28px;
			color: #F2EEDF;
		}
.emploi-details .right .entreprise-infos {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			margin-top: 1.5vw;
			margin-bottom: 0;
			padding-bottom: 0;
		}
.emploi-details .right .entreprise-infos img {
				width: 75px;
				margin-right: 20px;
			}
.emploi-details .right .entreprise-infos .sub-title {
				color: #63B3C1;
				margin-bottom: 0;
				padding-bottom: 0;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
.emploi-details .right .entreprise-infos:hover .sub-title {
					color: #D31C1C;
				}
.emploi-details .right .avantages {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			-ms-flex-wrap: wrap;
			    flex-wrap: wrap;
			margin-top: 35px;
		}
.emploi-details .right .avantages .avantage {
				width: 48%;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				margin-bottom: 1vw;
			}
@media (max-width: 599px) {
.emploi-details .right .avantages .avantage {
				  width: 100%;
				  margin-bottom: 0;
				  padding: 10px 0
			}
				}
.emploi-details .right .avantages .avantage img {
					width: 25px;
					margin-right: 15px;
				}
.emploi-details .right .avantages .avantage .sub-title {
					font-size: 14px;
					color: #F2EEDF;
				}
.emploi-details .right .salaire-wrap {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			background-image: url("/img/site/dot-2.jpg");
			background-repeat: repeat-x;
			background-size: 30px;
			background-position: center center;
			position: relative;
			margin-top: 3vw;
			margin-bottom: 2.5vw;
		}
@media (max-width: 955px) {
.emploi-details .right .salaire-wrap {
				margin-top: 45px;
				margin-bottom: 45px
		}
			}
.emploi-details .right .salaire-wrap .round {
				width: auto;
				height: auto;
				background-color: #F2EEDF;
				border-radius: 50%;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				-webkit-box-pack: center;
				    -ms-flex-pack: center;
				        justify-content: center;
				padding: 10px;
				border-radius: 0;
			}
.emploi-details .right .salaire-wrap .round .sub-title {
					color: #212C47;
					font-size: 14px;
					margin-bottom: 0;
					line-height: 1;
				}
.emploi-details .right .salaire-wrap .salaire-title {
				position: absolute;
				top: -10px;
				left: 50%;
				-webkit-transform: translate(-50%,0);
				    -ms-transform: translate(-50%,0);
				        transform: translate(-50%,0);
				color: #F2EEDF;
				font-size: 14px;
			}
.emploi-details .right .salaire-wrap.only-one {
				background: none;
			}
.emploi-details .right .salaire-wrap.only-one .round {
					width: auto;
					height: auto;
					padding: 10px;
					border-radius: 0;
				}
.emploi-details .right .salaire-wrap.only-one .salaire-title {
					position: absolute;
					top: -30px;
					left: 0;
					-webkit-transform: none;
					    -ms-transform: none;
					        transform: none;
				}
.emploi-details .right .data-wrap {
			width: 100%;
			margin-top: 3vw;
			margin-bottom: 3vw;
		}
.emploi-details .right .data-wrap .sub-title {
				font-size: 18px;
				color: #F2EEDF;
				margin-bottom: 1.5vw;
			}
.emploi-details .right .data-wrap .regular-text {
				color: #F2EEDF;
				margin-bottom: 1.5vw;
			}
.emploi-details .right .data-wrap ul li {
					padding-left: 30px;
					position: relative;
				}
.emploi-details .right .data-wrap ul li.regular-text {
						margin-bottom: 10px;
					}
.emploi-details .right .data-wrap ul li:before {
						content: "";
						display: block;
						width: 14px;
						height: 14px;
						border-radius:50%;
						background-color: #63B3C1;
						position: absolute;
						left: 0;
						top: 50%;
						-webkit-transform: translate(0,-50%);
						    -ms-transform: translate(0,-50%);
						        transform: translate(0,-50%);
					}
.emploi-details .right .cta-wrap {
			background-color: #63B3C1;
		}
@media (max-width: 955px) {
.emploi-details .right .cta-wrap {
				margin-top: 30px;
				margin-bottom: 30px
		}
			}
.emploi-details .right .cta-wrap:hover {
				background-color: #D31C1C;
			}
@media (max-width: 955px) {
.emploi-details {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap
}

		.emploi-details .left, .emploi-details .right {
			width: 100%;
			min-height: inherit;
		}
	}
.emplois-listing,
.filters-bar-wrapper {
	width: 100%;
	padding: 5vw 10vw;

	display: -webkit-box;

	display: -ms-flexbox;

	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
.emplois-listing.no-padding, .filters-bar-wrapper.no-padding {
		padding: 0;
	}
.emplois-listing.no-padding .title, .filters-bar-wrapper.no-padding .title {
			margin-bottom: 0;
		}
@media (max-width: 1255px) {
.emplois-listing,
.filters-bar-wrapper {
		padding-left: 20px;
		padding-right: 20px
}
	}
.emplois-listing .title, .filters-bar-wrapper .title {
		width: 100%;
		font-size: 28px;
		color: #212C47;
		margin-bottom: 2.5vw;
	}
@media (max-width: 767px) {
.emplois-listing .title, .filters-bar-wrapper .title {
			margin-bottom: 25px
	}
		}
.emplois-listing .subtitle, .filters-bar-wrapper .subtitle {
		width: 100%;
		font-size: 20px;
		color: #63B3C1;
		margin-bottom: 1.5vw;
	}
.emplois-listing .regular-text, .filters-bar-wrapper .regular-text {
		width: 100%;
		margin-bottom: 25px;
	}
.emplois-listing .regular-text.intro-text, .filters-bar-wrapper .regular-text.intro-text {
		font-size: 20px;
	}
@media (max-width: 767px) {
.emplois-listing .regular-text.intro-text, .filters-bar-wrapper .regular-text.intro-text {
			font-size: 16px
	}
		}
.emplois-listing .select-radio-wrap, .filters-bar-wrapper .select-radio-wrap {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 3vw;
		margin-top: 1vw;
		position: relative;
		z-index: 100;

		-webkit-box-align: stretch;

		    -ms-flex-align: stretch;

		        align-items: stretch;
		-ms-flex-wrap: nowrap;
		    flex-wrap: nowrap;
	}
@media (max-width: 955px) {
.emplois-listing .select-radio-wrap, .filters-bar-wrapper .select-radio-wrap {
			-ms-flex-wrap: wrap;
			    flex-wrap: wrap
	}
		}
.emplois-listing .select-radio-wrap .select, .filters-bar-wrapper .select-radio-wrap .select {
			margin: 0;
			width: 33.33%;
			height: 70px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
			padding: 25px;
			border-top: 2px solid #212C47;
			border-bottom: 2px solid #212C47;
			border-right: 2px solid #212C47;
			cursor: pointer;
			position: relative;
			background-color: #F2EEDF;

			color: #212C47;
			text-decoration: none;
			font-size: 16px;
			text-transform: none;
			font-family: 'alvar_black', 'Arial', 'sans-serif';
		}
@media (max-width: 955px) {
.emplois-listing .select-radio-wrap .select, .filters-bar-wrapper .select-radio-wrap .select {
				width: 100%;
				margin-bottom: 10px;
				border-top: 2px solid #212C47;
				border-bottom: 2px solid #212C47;
				border-right: 2px solid #212C47;
				border-left: 2px solid #212C47
		}
			}
.emplois-listing .select-radio-wrap .select.half, .filters-bar-wrapper .select-radio-wrap .select.half {
				width: 50%;
			}
@media (max-width: 955px) {
.emplois-listing .select-radio-wrap .select.half, .filters-bar-wrapper .select-radio-wrap .select.half {
					width: 100%
			}
				}
.emplois-listing .select-radio-wrap .select:first-child, .filters-bar-wrapper .select-radio-wrap .select:first-child {
				border-left: 2px solid #212C47;
			}
.emplois-listing .select-radio-wrap .select .sub-title, .filters-bar-wrapper .select-radio-wrap .select .sub-title {
				font-size: 16px;
				color: #212C47;
			}
.emplois-listing .select-radio-wrap .select img, .filters-bar-wrapper .select-radio-wrap .select img {
				width: 15px;
			}
.emplois-listing .select-radio-wrap .select.selected .drop-down, .filters-bar-wrapper .select-radio-wrap .select.selected .drop-down {
					height: 256px;
				}
.emplois-listing .select-radio-wrap .select .wrap, .filters-bar-wrapper .select-radio-wrap .select .wrap {
				padding: 2vw;
			}
.emplois-listing .select-radio-wrap .select .drop-down, .filters-bar-wrapper .select-radio-wrap .select .drop-down {
				width: calc(100% + 4px);
				height: 0;
				background-color: #63B3C1;
				position: absolute;
				left: -2px;
				top: 68px;
				overflow: hidden;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
/* The container */
.emplois-listing .select-radio-wrap .select .container, .filters-bar-wrapper .select-radio-wrap .select .container {
				display: block;
				position: relative;
				padding-left: 35px;
				margin-bottom: 12px;
				cursor: pointer;
				font-size: 16px;
				text-transform: uppercase;
				-webkit-user-select: none;
				   -moz-user-select: none;
				    -ms-user-select: none;
				        user-select: none;
				font-family: 'alvar_semibold', 'Arial', 'sans-serif';
				color: #212C47;
				padding-top: 3px;
				text-align: left;
			}
.emplois-listing .select-radio-wrap .select .container:last-child, .filters-bar-wrapper .select-radio-wrap .select .container:last-child {
					margin-bottom: 0;
				}
/* Hide the browser's default radio button */
.emplois-listing .select-radio-wrap .select .container input, .filters-bar-wrapper .select-radio-wrap .select .container input {
				position: absolute;
				opacity: 0;
				cursor: pointer;
			}
/* Create a custom radio button */
.emplois-listing .select-radio-wrap .select .checkmark, .filters-bar-wrapper .select-radio-wrap .select .checkmark {
				position: absolute;
				top: 0;
				left: 0;
				height: 20px;
				width: 20px;
				background-color: #63B3C1;
				border-radius: 50%;
				border: 4px solid #212C47;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
/* When the radio button is checked, add a blue background */
.emplois-listing .select-radio-wrap .select .container input:checked ~ .checkmark, .filters-bar-wrapper .select-radio-wrap .select .container input:checked ~ .checkmark {
				background-color: #212C47;
			}
/* Create the indicator (the dot/circle - hidden when not checked) */
.emplois-listing .select-radio-wrap .select .checkmark:after, .filters-bar-wrapper .select-radio-wrap .select .checkmark:after {
				content: "";
				position: absolute;
				display: none;
			}
/* Show the indicator (dot/circle) when checked */
.emplois-listing .select-radio-wrap .select .container input:checked ~ .checkmark:after, .filters-bar-wrapper .select-radio-wrap .select .container input:checked ~ .checkmark:after {
				display: block;
			}
/* Style the indicator (dot/circle) */
.emplois-listing .select-radio-wrap .select .container .checkmark:after, .filters-bar-wrapper .select-radio-wrap .select .container .checkmark:after {
			 	opacity: 0;
			}
.emplois-listing .select-radio-wrap .select .container input:checked ~ .checkmark, .filters-bar-wrapper .select-radio-wrap .select .container input:checked ~ .checkmark {
				border: 5px solid #212C47;
			}
.emplois-listing .select-radio-wrap .filters-button, .filters-bar-wrapper .select-radio-wrap .filters-button {
			width: 70px;
			background-color: #F2EEDF;
			background-image: url(/img/site/slider-arrow-right-reverted.jpg);
			background-position: center center;
			background-repeat: no-repeat;
	  
			cursor: pointer;
	  
			-webkit-transition: opacity 0.2s ease;
	  
			-o-transition: opacity 0.2s ease;
	  
			transition: opacity 0.2s ease;
		  }
.emplois-listing .select-radio-wrap .filters-button:hover, .filters-bar-wrapper .select-radio-wrap .filters-button:hover {
			  opacity: 0.75;
			}
@media (max-width: 955px) {
.emplois-listing .select-radio-wrap .filters-button, .filters-bar-wrapper .select-radio-wrap .filters-button {
			  display: none
		  }
			}
.emplois-listing .pagination-wrap, .filters-bar-wrapper .pagination-wrap {
		margin-top: 2.5vw;
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
	}
.emplois-listing .pagination-wrap .cta-wrap, .filters-bar-wrapper .pagination-wrap .cta-wrap {
			margin: 0 2%;
		}
.emplois-listing .pagination-wrap .cta-wrap:hover, .filters-bar-wrapper .pagination-wrap .cta-wrap:hover {
				background-color: #D31C1C;
			}
.emplois-listing .emplois-box, .filters-bar-wrapper .emplois-box {
		width: calc(50% - 10px);
		margin-bottom: 20px;
		background-color: #212C47;
		padding: 3vw 1vw 1vw 3vw;
		position: relative;
	}
@media (max-width: 599px) {
.emplois-listing .emplois-box, .filters-bar-wrapper .emplois-box {
			width: 100%;
			padding: 30px 20px
	}
		}
.emplois-listing .emplois-box.entreprise-box, .filters-bar-wrapper .emplois-box.entreprise-box {
			padding: 0;
		}
.emplois-listing .emplois-box.entreprise-box .entreprise-img, .filters-bar-wrapper .emplois-box.entreprise-box .entreprise-img {
				width: 100%;
			}
.emplois-listing .emplois-box.entreprise-box .data, .filters-bar-wrapper .emplois-box.entreprise-box .data {
				padding: 3vw 1vw 1vw 3vw;
			}
@media (max-width: 599px) {
.emplois-listing .emplois-box.entreprise-box .data, .filters-bar-wrapper .emplois-box.entreprise-box .data {
					padding: 30px 20px
			}
				}
.emplois-listing .emplois-box.entreprise-box .data .avantages, .filters-bar-wrapper .emplois-box.entreprise-box .data .avantages {
					margin: 2.5vw 0;
				}
.emplois-listing .emplois-box.entreprise-box .data .box-footer, .filters-bar-wrapper .emplois-box.entreprise-box .data .box-footer {
					position: absolute;
					right: 1vw;
					bottom: 1vw;
					width: -webkit-fit-content;
					width: -moz-fit-content;
					width: fit-content;
				}
@media (max-width: 1025px) {
.emplois-listing .emplois-box.entreprise-box .data .box-footer, .filters-bar-wrapper .emplois-box.entreprise-box .data .box-footer {
					  right: 0;
					  bottom: 0
				}
					}
.emplois-listing .emplois-box .title, .filters-bar-wrapper .emplois-box .title {
			font-size: 24px;
			color: #F2EEDF;
			margin-bottom: 10px;
		}
.emplois-listing .emplois-box .sub-title, .filters-bar-wrapper .emplois-box .sub-title {
			font-size: 14px;
			color: #F2EEDF;
		}
.emplois-listing .emplois-box .avantages, .filters-bar-wrapper .emplois-box .avantages {
			margin: 3.5vw 0;

			margin-top: 25px;
		}
@media (max-width: 599px) {
.emplois-listing .emplois-box .avantages, .filters-bar-wrapper .emplois-box .avantages {
			  margin-bottom: 25px
		}
			}
.emplois-listing .emplois-box .avantages .avantage, .filters-bar-wrapper .emplois-box .avantages .avantage {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				margin-bottom: 1vw;
			}
@media (max-width: 599px) {
.emplois-listing .emplois-box .avantages .avantage, .filters-bar-wrapper .emplois-box .avantages .avantage {
					margin-bottom: 10px
			}
				}
.emplois-listing .emplois-box .avantages .avantage img, .filters-bar-wrapper .emplois-box .avantages .avantage img {
					width: 25px;
					margin-right: 15px;
				}
.emplois-listing .emplois-box .avantages .avantage .sub-title, .filters-bar-wrapper .emplois-box .avantages .avantage .sub-title {
					font-size: 14px;
					color: #F2EEDF;
				}
.emplois-listing .emplois-box .box-header, .filters-bar-wrapper .emplois-box .box-header {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: stretch;
			    -ms-flex-align: stretch;
			        align-items: stretch;
			-webkit-box-pack: start;
			    -ms-flex-pack: start;
			        justify-content: flex-start;

			-webkit-box-orient: horizontal;

			-webkit-box-direction: reverse;

			    -ms-flex-direction: row-reverse;

			        flex-direction: row-reverse;

			padding-bottom: 0;
			margin-bottom: 0;
		}
.emplois-listing .emplois-box .box-header .logo-wrap, .filters-bar-wrapper .emplois-box .box-header .logo-wrap {
				max-width: 95px;
				min-width: 95px;
				min-height: 95px;

				padding: 5px;

				margin-right: 0;
				margin-left: auto;

				background: #F2EEDF;

				display: -webkit-box;

				display: -ms-flexbox;

				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				    -ms-flex-direction: column;
				        flex-direction: column;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				-webkit-box-pack: center;
				    -ms-flex-pack: center;
				        justify-content: center;
			}
@media (max-width: 599px) {
.emplois-listing .emplois-box .box-header .logo-wrap, .filters-bar-wrapper .emplois-box .box-header .logo-wrap {
					max-width: 75px;
					min-width: 75px;
					min-height: 75px
			}
				}
.emplois-listing .emplois-box .box-header .text-wrap, .filters-bar-wrapper .emplois-box .box-header .text-wrap {
				width: 100%;
				max-width: 375px;

				display: -webkit-box;

				display: -ms-flexbox;

				display: flex;
				-webkit-box-orient: vertical;
				-webkit-box-direction: normal;
				    -ms-flex-direction: column;
				        flex-direction: column;
				-webkit-box-pack: center;
				    -ms-flex-pack: center;
				        justify-content: center;
			}
.emplois-listing .emplois-box .box-header .text-wrap .title, .filters-bar-wrapper .emplois-box .box-header .text-wrap .title {
					padding-right: 2vw;
				}
.emplois-listing .emplois-box .box-header .text-wrap .sub-title, .filters-bar-wrapper .emplois-box .box-header .text-wrap .sub-title {
					display: none;
				}
@media (max-width: 599px) {
					.emplois-listing .emplois-box .box-header .text-wrap .title, .filters-bar-wrapper .emplois-box .box-header .text-wrap .title {
						font-size: 18px;
					}
				}
.emplois-listing .emplois-box .box-footer, .filters-bar-wrapper .emplois-box .box-footer {
			width: 100%;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			    -ms-flex-pack: justify;
			        justify-content: space-between;
			-webkit-box-align: center;
			    -ms-flex-align: center;
			        align-items: center;
		}
.emplois-listing .emplois-box .box-footer .regular-text, .filters-bar-wrapper .emplois-box .box-footer .regular-text {
				font-size: 14px;
				color: #F2EEDF;
			}
.emplois-listing .emplois-box .box-footer .cta, .filters-bar-wrapper .emplois-box .box-footer .cta {
				width: 50px;
				height: 50px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: center;
				    -ms-flex-pack: center;
				        justify-content: center;
				-webkit-box-align: center;
				    -ms-flex-align: center;
				        align-items: center;
				background-color: #63B3C1;
				-webkit-transition: all 0.2s ease;
				-o-transition: all 0.2s ease;
				transition: all 0.2s ease;
			}
.emplois-listing .emplois-box .box-footer .cta img, .filters-bar-wrapper .emplois-box .box-footer .cta img {
					width: 15px;
				}
.emplois-listing .emplois-box:hover .box-footer .cta, .filters-bar-wrapper .emplois-box:hover .box-footer .cta {
					background-color: #D31C1C;
				}
p.disclaimer {
	width: 100%;
	line-height: 1.25;
}
@media (max-width: 599px) {
p.disclaimer {
		text-align: center
}
	}
p.disclaimer small {
	width: 100%;
	font-size: 88%;
	line-height: 1.25;
}
@media (max-width: 599px) {
p.disclaimer small {
		text-align: center;
		font-size: 80%
}
	}
.header-lang {
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    font-family: 'alvar_black', 'Arial', 'sans-serif';
    background: #212C47;
    color: #F2EEDF;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}
.top-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #F2EEDF;
    z-index: 400;
    width: 100%;
    background: #4B5365;
    padding-left: 22vw;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.left-search {
    position: relative;
  }
.left-search form {
      position: relative;
    }
.left-search input {
      width: 340px;
      border-radius: 0;
      background: transparent;
      border: none;
      padding: 15px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      color: #F2EEDF;
      border-left: 1px solid rgba(242,238,223, 0.3);
    }
@media (max-width: 767px) {
.left-search input {
        width: 186px;
        min-width: 186px
    }
      }
.left-search input::-webkit-input-placeholder {
        color: #F2EEDF;
      }
.left-search button {
      position: absolute;
      top: 50%;
      right: 10px;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      z-index: 2;
      background: none;
      border: none;
      margin-top: 2px;
      cursor: pointer;
    }
.recherche .max-container {
    max-width: 1040px;
    margin: 0 auto;
    padding: 0 20px;
    padding-bottom: 80px;
  }
.recherche .pagination-wrap {
    text-align: center;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
.recherche .no-results {
    padding-top: 4vw;
  }
.recherche ul {
    list-style-type: none;
    margin: 0;
    padding: 6vw 0;
  }
.recherche ul a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(33,44,71, 0.3);
      color: #212C47;
    }
.recherche ul a .right {
        width: 100px;
        min-width: 100px;
        border-radius: 0;
        margin-left: 6vw;
      }
.recherche ul a .right img,
        .recherche ul a .right svg {
          display: block;
          width: 100%;
          height: 100%;
          -o-object-fit: contain;
             object-fit: contain;
          border-radius: 0;
        }
.recherche ul a .medium-title {
        color: #212C47;
        font-size: 20px;
        font-weight: 900;
        font-family: 'alvar_black', 'Arial', 'sans-serif';
        text-transform: uppercase;
      }
.recherche ul a .small-title {
        color: #63B3C1;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 10px;
        font-weight: 900;
        font-family: 'alvar_black', 'Arial', 'sans-serif';
        text-transform: uppercase;
      }
.recherche ul a .reading-text {
        font-size: 14px;
        font-weight: 300;
        line-height: 1.55;
      }
.search-header-box {
    background: #212C47;
    padding: 6vw;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.search-header-box .search-title {
        font-family: 'alvar_black', 'Arial', 'sans-serif';
        font-weight: 900;
        text-transform: uppercase;
        color: #F2EEDF;
        font-size: 48px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%; /* 65px */
        letter-spacing: 3.25px;
        text-transform: uppercase;
        padding-bottom: 20px;
    }
.search-header-box .search-subtitle {
        font-family: 'alvar_black', 'Arial', 'sans-serif';
        font-weight: 900;
        text-transform: uppercase;
        color: #63B3C1;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%; /* 30px */
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }
.emploi-details.region-hero .right .emplois-infos {
  margin-bottom: 0;
}
.accordion-wrapper {
  padding: 2.5vw 0;
}
.accordion-wrapper .accordion-title {
    font-family: 'alvar_regular', 'Arial', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #F2EEDF;

    cursor: pointer;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-image: url(/img/site/dot-2.jpg);
    background-repeat: repeat-x;
    background-size: 20px;
    background-position: center center;
    position: relative;
    padding: 15px 0;
    margin-right: 25px;
  }
.accordion-wrapper .accordion-title span {
      display: inline-block;
      background: #212C47;
      padding-right: 10px;
    }
.accordion-wrapper .accordion-title svg {
      position: relative;
      right: -25px;
      display: inline-block;
      background: #212C47;
    }
.accordion-wrapper .accordion-content {
    position: relative;
    overflow: hidden;
    height: 0;
    -webkit-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
            transform: scale(1, 0);
    -webkit-transform-origin: center top;
        -ms-transform-origin: center top;
            transform-origin: center top;

    -webkit-transition: height 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0.5s, -webkit-transform 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0s;

    transition: height 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0.5s, -webkit-transform 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0s;

    -o-transition: height 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0.5s, transform 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0s;

    transition: height 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0.5s, transform 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0s;

    transition: height 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0.5s, transform 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0s, -webkit-transform 0.5s cubic-bezier(0.35, 0, 0.22, 1.26) 0s;
  }
.accordion-wrapper .accordion-content .inner {
      background: rgba(255,255,255, 0.05723);
      padding: 15px;
      font-family: 'alvar_regular', 'Arial', 'sans-serif';
    }
.accordion-wrapper .accordion-content .inner .regular-title, .accordion-wrapper .accordion-content .inner h2, .accordion-wrapper .accordion-content .inner h3, .accordion-wrapper .accordion-content .inner h4, .accordion-wrapper .accordion-content .inner h5, .accordion-wrapper .accordion-content .inner h6 {
        margin-bottom: 10px;
        color: #F2EEDF;
        text-transform: uppercase;
        font-size: 24px;
      }
@media (max-width: 1100px) {
.accordion-wrapper .accordion-content .inner .regular-title, .accordion-wrapper .accordion-content .inner h2, .accordion-wrapper .accordion-content .inner h3, .accordion-wrapper .accordion-content .inner h4, .accordion-wrapper .accordion-content .inner h5, .accordion-wrapper .accordion-content .inner h6 {
          font-size: 22px
      }
        }
@media (max-width: 890px) {
.accordion-wrapper .accordion-content .inner .regular-title, .accordion-wrapper .accordion-content .inner h2, .accordion-wrapper .accordion-content .inner h3, .accordion-wrapper .accordion-content .inner h4, .accordion-wrapper .accordion-content .inner h5, .accordion-wrapper .accordion-content .inner h6 {
          font-size: 18px
      }
        }
.accordion-wrapper .accordion-content .inner, .accordion-wrapper .accordion-content .inner .regular-text, .accordion-wrapper .accordion-content .inner p, .accordion-wrapper .accordion-content .inner ul, .accordion-wrapper .accordion-content .inner ul li {
        color: #F2EEDF;
        font-weight: 400;
        margin-bottom: 20px;
        line-height: 1.8;
        font-size: 16px;
      }
@media (max-width: 1100px) {
.accordion-wrapper .accordion-content .inner, .accordion-wrapper .accordion-content .inner .regular-text, .accordion-wrapper .accordion-content .inner p, .accordion-wrapper .accordion-content .inner ul, .accordion-wrapper .accordion-content .inner ul li {
          font-size: 14px
      }
        }
.accordion-wrapper .accordion-content .inner a, .accordion-wrapper .accordion-content .inner .regular-text a, .accordion-wrapper .accordion-content .inner p a, .accordion-wrapper .accordion-content .inner ul a, .accordion-wrapper .accordion-content .inner ul li a {
          color: #63B3C1;
        }
.accordion-wrapper .accordion-content .inner ul {
        padding-left: 15px;
        list-style-type: disc;
        line-height: 1.15;
      }
.accordion-wrapper .accordion-content .inner ul li {
        margin: 0;
      }
.accordion-wrapper .accordion-content .inner li {
        margin: 0;
        line-height: 1.25;
        padding-bottom: 7px;
      }
.accordion-wrapper .accordion-content .inner figure {
        margin: 0;
        padding: 0;
      }
.accordion-wrapper .accordion-content .inner img.full,
      .accordion-wrapper .accordion-content .inner figure img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 4px;
      }
.accordion-wrapper .accordion-content .inner .iframe-fix {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 25px 0;
      }
.accordion-wrapper .accordion-content .inner .iframe-fix iframe {
          position: relative;
          width: 100% !important;
          height: auto !important;
          min-height: 32vw !important;
        }
@media (max-width: 700px) {
.accordion-wrapper .accordion-content .inner .iframe-fix iframe {
            min-height: 50vw !important
        }
          }
.accordion-wrapper .is-active .accordion-content {
    height: auto;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
  }
.form-message {
  font-family: 'alvar_regular', 'Arial', 'sans-serif';
  display: block;
  margin: 0;
  margin-bottom: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 50px;
  line-height: 1;
  color: green;
}
.form-message.error {
    color: red;
  }
.basic-content {
  width: 100%;
  max-width: 1100px;
  margin: 2.5vw auto;
  padding: 2.5vw 20px;
  background: #fff;
}
.basic-content-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 0;

  font-family: 'alvar_regular', 'Arial', 'sans-serif';
}
.basic-content-container .regular-title, .basic-content-container h1, .basic-content-container h2, .basic-content-container h3, .basic-content-container h4, .basic-content-container h5, .basic-content-container h6 {
    margin-bottom: 10px;
    color: #212C47;
    text-transform: uppercase;
    font-size: 30px;
  }
@media (max-width: 1100px) {
.basic-content-container .regular-title, .basic-content-container h1, .basic-content-container h2, .basic-content-container h3, .basic-content-container h4, .basic-content-container h5, .basic-content-container h6 {
      font-size: 24px
  }
    }
@media (max-width: 890px) {
.basic-content-container .regular-title, .basic-content-container h1, .basic-content-container h2, .basic-content-container h3, .basic-content-container h4, .basic-content-container h5, .basic-content-container h6 {
      font-size: 22px
  }
    }
.basic-content-container h3 {
    font-size: 28px;
  }
@media (max-width: 1100px) {
.basic-content-container h3 {
      font-size: 22px
  }
    }
@media (max-width: 890px) {
.basic-content-container h3 {
      font-size: 20px
  }
    }
.basic-content-container h4 {
    font-size: 26px;
  }
@media (max-width: 1100px) {
.basic-content-container h4 {
      font-size: 20px
  }
    }
@media (max-width: 890px) {
.basic-content-container h4 {
      font-size: 18px
  }
    }
.basic-content-container h5 {
    font-size: 26px;
  }
@media (max-width: 1100px) {
.basic-content-container h5 {
      font-size: 20px
  }
    }
@media (max-width: 890px) {
.basic-content-container h5 {
      font-size: 18px
  }
    }
.basic-content-container h6 {
    font-size: 26px;
  }
@media (max-width: 1100px) {
.basic-content-container h6 {
      font-size: 20px
  }
    }
@media (max-width: 890px) {
.basic-content-container h6 {
      font-size: 18px
  }
    }
.basic-content-container, .basic-content-container .regular-text, .basic-content-container p, .basic-content-container ul, .basic-content-container ul li {
    color: #212C47;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 1.8;
    font-size: 18px;
  }
.basic-content-container strong, .basic-content-container .regular-text strong, .basic-content-container p strong, .basic-content-container ul strong, .basic-content-container ul li strong {
      font-family: 'alvar_semibold', 'Arial', 'sans-serif';
      font-weight: 500;
    }
@media (max-width: 1100px) {
.basic-content-container, .basic-content-container .regular-text, .basic-content-container p, .basic-content-container ul, .basic-content-container ul li {
      font-size: 16px
  }
    }
.basic-content-container a, .basic-content-container .regular-text a, .basic-content-container p a, .basic-content-container ul a, .basic-content-container ul li a {
      color: #D31C1C;
    }
.basic-content-container ul li {
    margin: 0;
  }
.basic-content-container li {
    margin-bottom: 10px;
  }
.basic-content-container figure {
    margin: 0;
    padding: 0;
  }
.basic-content-container img.full,
  .basic-content-container figure img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
.basic-content-container .iframe-fix {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 25px 0;
  }
.basic-content-container .iframe-fix iframe {
      position: relative;
      width: 100% !important;
      height: auto !important;
      min-height: 32vw !important;
    }
@media (max-width: 700px) {
.basic-content-container .iframe-fix iframe {
        min-height: 50vw !important
    }
      }

