.instagram-footer {
	width: 100%;
	background-color: $colorAccent1;
	padding: 5vw 10vw;

	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	@media (max-width: 955px) {
		padding-left: 25px;
		padding-right: 25px;
	}

	@media (max-width: 599px) {
		justify-content: center;
	}

	.title {
		font-size: 28px;
		color: $colorAccent2;
	}

	.social {
		display: flex;
		align-items: center;

		@media (max-width: 599px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}

		.icon {
			width: 36px;
			height: 36px;
			border-radius: 50%;
			margin-left: 15px;
			background-color: $colorAccent4;
			transition: all 0.2s ease;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				background-color: $colorAccent3;
			}

			img {
				width: 20px;

				&.facebook {
					width: 10px;
				}

				&.linkedin {
					width: 16px;
				}
			}
		}
	}

	.feed {
		display: flex;
		justify-content: space-between;
		margin-top: 2.5vw;
		flex-wrap: wrap;

		img {
			width: 24.5%;
    	margin-bottom: 0.5%;

    	@media (max-width: 599px) {
    		width: 49%;
    		margin-bottom: 10px;
    	}
		}

		a {
			width: 24.5%;
			margin-bottom: 0.5%;

			img {
				display: block;
				width: 100%;
				margin-bottom: 0;
			}
		}

		@media (max-width: 599px) {
			a, img {
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7),
				&:nth-child(8) {
					display: none;
				}
			}
		}
	}
}

.legal {
	width: 100%;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;

	.spacer {
		height: auto;
	}

	.regular-text {
		margin: 0 5px;
	}

	a {
		transition: all 0.2s ease;
		&:hover {
			color: $colorAccent3;
		}
	}

	@media (max-width: 955px) {
		flex-direction: column;
		text-align: center;

		.spacer {
			display: none;
		}

		a {
			display: inline-block;
			padding-top: 12px;
		}
	}
}
