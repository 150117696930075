.login-header {
	.main-logo {
		display: none;
	}
}

.login-wrap {
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	@media (max-width: 955px) {
		margin-top: 50px;
	}

	.save {
		margin-top: 25px;
	}

	.left {
		width: 38vw;
		position: relative;

		.logo {
			width: 125px;
			position: absolute;
			left: 3vw;
			top: 2vw;

			display: none;

			@media (max-width: 955px) {
				display: none;
				width: 475px;
			}
		}

		.form-wrap {
			width: 100%;
			padding: 0 3vw;
			position: absolute;
			top: 50%;
			transform: translate(0,-50%);

			@media (max-width: 955px) {
				position: relative;
				top: 0;
				transform: none;

				padding: 45px 20px;
			}

			@media (max-width: 599px) {
				padding: 30px 20px;
			}
		}

		.title {
			font-size: 33px;
			margin-bottom: 25px;

			@media (max-width: 955px) {
				font-size: 24px;
				margin-bottom: 0;
			}
		}

		.sup-title {
			font-size: 15px;
			margin: 0;
			margin-bottom: 10px;
			text-transform: none;

			@media (max-width: 955px) {
				margin-bottom: 0;
			}
		}

		.sub-title {
			display: block;
			font-size: 15px;
			margin-bottom: 35px;
			margin-top: 35px;
			line-height: 1.4;

			text-transform: none;

			@media (max-width: 1025px) {
				margin-bottom: 20px;
				margin-top: 10px;
			}

			@media (max-width: 955px) {
				margin-bottom: 20px;
				margin-top: 20px;
			}

			a {
				color: $colorAccent3;
			}
		}

		.forgot-password {
			color: $colorAccent3;
			font-size: 15px;
			width: 100%;
			display: block;
			margin-top: 10px;
		}

		.form-input  {
			margin-bottom: 0;
		}
	}

	.right {
		width: calc(100% - 38vw);
		min-height: 100vh;
		background-image: url("/img/site/login.jpg");
		background-size: cover;

		@media (max-width: 955px) {
			width: calc(100% - 475px);
		}
	}

	@media (max-width: 955px) {
		flex-direction: column-reverse;

		.left, .right {
			width: 100%;
			height: auto;
			min-height: inherit;
		}

		.right {
			height: 55vw;
			max-height: 340px;
			background-position: center center;
		}
	}
}

.account-creation {
	.left {
		width: calc(100% - 40%);
		padding-top: 10vw;

		@media (max-width: 955px) {
		  padding-top: 40px;
		}

		.steps {
			position: absolute;
		  right: 3vw;
		  top: 4vw;

			font-family: $fontBlack;
			font-style: normal;
			font-weight: 900;
			font-size: 35px;
			line-height: 100%;
			/* identical to box height, or 35px */

			letter-spacing: 0.05em;
			text-transform: uppercase;

			/* Bleu */

			color: #212C47;

			@media (max-width: 955px) {
			  right: auto;
			  left: 20px;
			  top: 25px;
			  font-size: 26px;
			}
		}
	}

	.right {
		width: 40%;

		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&:before {
		  content: '';
		  position: absolute;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  left: 0;
		  background-color: rgba(#212C47, .70);
		  z-index: 1;
		}

		ul {
			position: relative;
			z-index: 2;

			list-style-type: none;
			padding: 0;
			margin: 0;

			@media (max-width: 955px) {
				display: none;
			}

			li {
				display: flex;
				align-items: center;
				justify-content: flex-start;

				margin: 0;
				padding: 25px 0;

				font-family: $fontRegular;
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 25px;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				color: #F2EEDF;
				text-align: left;

				@media (max-width: 955px) {
				  font-size: 13px;
				  line-height: 1.2;
				  padding: 10px;
				  padding-left: 165px;
				  padding-right: 20px;
				}

				img {
					display: block;
					width: 30px;
					margin-right: 20px;

					@media (max-width: 599px) {
						width: 24px;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.left .form-wrap {
		position: relative;
		top: 0;
		left: 0;
		transform: none;

		width: 100%;
		height: 100%;
		max-width: 840px;
		margin: 0 auto;

		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}

	.form-wrap form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		padding-top: 15px;

		.third {
			width: calc(33.33% - 10px);
		}

		.half {
			width: calc(50% - 10px);

			@media (max-width: 955px) {
				width: calc(50% - 4px);
			}
		}

		.half-file {
			@media (max-width: 955px) {
				width: 100%;
			}
		}

		.quarter {
			width: calc(25% - 10px);
		}

		.save {
			min-width: 175px;
			margin-top: 25px;
			text-align: left;
		}

		.form-input {
			margin-bottom: 20px;

			@media (max-width: 955px) {
				margin-bottom: 0px;
			}
		}

		.cols {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			flex-wrap: wrap;

			@media (max-width: 955px) {

			}
		}

		.checkbox-wrap {
			width: 100%;

			@media (max-width: 955px) {
				width: 100%;
			}

			&.half {
				width: 50%;

				@media (max-width: 955px) {
					width: 100%;
				}
			}

			.sub-title {
				width: 100%;

				@media (max-width: 955px) {
					margin-bottom: 25px;
				}
			}

			.container {
				font-size: 15px;
				font-family: $fontSemi;
				text-transform: uppercase;
				color: $colorAccent1;
			}

			&.thin {
				.container {
					font-family: $fontLight;
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 150%;
					text-transform: none;
					/* identical to box height, or 30px */
					letter-spacing: 0.05em;

					/* Bleu */
					color: #212C47;

					.checkmark {
						top: 4px;
					}
				}
			}
		}
	}

	@media (max-width: 955px) {
		.left, .right {
			width: 100%;
		}
	}
}
