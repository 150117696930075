.hero-nav {
	width: 100%;
	min-height: 500px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding: 0 10vw;
	background-image: url("/img/site/hero-admin-account.jpg");
	background-size: cover;
	background-position: center top;

	@media (max-width: 1255px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 955px) {
  	min-height: 375px;
  }

  @media (max-width: 599px) {
  	min-height: 305px;
  	padding-right: 0;
  }

	.left {
		padding-bottom: 20px;

		@media (max-width: 955px) {
			display: none;
		}

		.title {
			color: $colorAccent2;
			font-size: 33px;
			margin-bottom: 5px;
		}

		.sub-title {
			color: $colorAccent2;
			font-size: 16px;
		}
	}

	.right {
		display: flex;

		@media (max-width: 599px) {
			width: 100%;
			overflow-y: hidden;
			overflow-x: auto;
		}

		a {
			font-family: $fontBlack;
			font-size: 14px;
			color: $colorAccent2;
			text-transform: uppercase;
			padding: 20px;
			transition: all 0.2s ease;
			border-top-left-radius: 3px;
			border-top-right-radius: 3px;

			background: rgba(#fff, 0.123);

			@media (max-width: 955px) {
			  padding: 15px 20px;
			  font-size: 14px;
			  margin-right: 5px;
			}

			&.actif {
				background-color: $colorAccent2;
				color: $colorAccent1;
			}

			&:hover {
				background-color: $colorAccent2;
				color: $colorAccent1;
			}
		}
	}
}

.admin-form-wrap {
	width: 100%;
	padding: 5vw 10vw;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media (max-width: 1255px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 955px) {
  	padding-top: 75px;
  }

	&.with-aside {
		flex-direction: row-reverse;

		@media (max-width: 955px) {
			flex-direction: column;
	  }
	}

	.third {
		width: calc(33.33% - 5px);
	}

	.half {
		width: calc(50% - 10px);

		@media (max-width: 955px) {
			width: calc(50% - 4px);
		}
	}

	.half-file {
		@media (max-width: 955px) {
			width: 100%;
		}
	}

	.quarter {
		width: calc(25% - 5px);
	}

	.save {
		margin-top: 2vw;

		&.is-hidden {
			display: none;
		}
	}

	.form-input.full {
		@media (max-width: 955px) {
			margin-bottom: 10px;
		}
	}

	.textarea-wrapper {
		position: relative;
	}

	.maxlength-count {
		position: absolute;
		bottom: 15px;
		right: 0;
		font-family: 'alvar_regular', 'Arial', 'sans-serif';
		color: #212C47;
		line-height: 1.5;
		font-size: 14px;
		padding: 0 5px;
		background: #fff;
	}

	.checkbox-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 2.5vw;

		.sub-title {
			width: 100%;
			margin-bottom: 3vw;
		}

		.container {
			width: 32%;
			font-size: 14px;
			font-family: $fontSemi;
			text-transform: uppercase;
			color: $colorAccent1;
		}
	}

	.testimonials-wrap {
		margin-top: 2.5vw;
		margin-bottom: 3vw;
		background-color: #E8E4D7;
		padding: 2vw;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.sub-title {
			width: 100%;
			margin-bottom: 2vw;
		}

		.actions {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin-top: 10px;

			.action {
				display: flex;
				align-items: center;
				margin-right: 20px;
				cursor: pointer;

				img {
					width: 20px;
					margin-right: 10px;
				}

				.sub-title {
					color: $colorAccent1;
					font-size: 14px;
					margin-bottom: 0;
				}
			}
		}
	}

	.photos-wrap {
		position: relative;
		margin-top: 2.5vw;
		margin-bottom: 3vw;
		background-color: #E8E4D7;
		padding: 30px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.sub-title {
			width: 100%;
			margin-bottom: 2vw;
		}

		.upper-label {
			position: absolute;
			top: 70px;
			right: 105px;
    	z-index: 5;
			font-family: 'alvar_regular', 'Arial', 'sans-serif';
			color: #fff;
			line-height: 1.5;
			font-size: 12px;
			padding: 0 5px;
			background: #212C47;
		}

		.photo-row {
			position: relative;
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 2.5vw;

			background: url('/img/site/drag-icon.svg');
			background-position: right 10px center;
			background-repeat: no-repeat;

			.left {
				width: calc(50% - 5px);
			}

			.dragger {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background: $colorAccent1;
				padding: 25px;

				z-index: -1;
			}

			.right {
				position: relative;
				width: calc(50% - 5px);
				padding-left: 20px;

				&.smaller-emplois {
					width: calc(30% - 5px);
				}

				img {
					width: 100%;
				}

				.actions {
					width: 100%;
					display: flex;
					align-items: center;
					margin-top: 10px;

					.action {
						display: flex;
						align-items: center;
						margin-right: 20px;
						cursor: pointer;

						img {
							width: 20px;
							margin-right: 10px;
						}

						.sub-title {
							color: $colorAccent1;
							font-size: 14px;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

.pagination-wrap.admin {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 20px;

	.cta-wrap {
		margin: 0 5px;
	}
}

.add-wrap {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-top: 20px;

	.regular-text {
		display: inline-block;
		padding-right: 10px;
	}

	img {
		width: 25px;
	}
}

.admin-job-list {
	width: 100%;
	padding: 5vw 10vw;

	@media (max-width: 1255px) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.cta-wrap {
		background-color: $colorAccent3;
		margin-bottom: 2.5vw;
	}

	.job-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $colorAccent1;
		padding: 20px 20px 20px 30px;
		margin-bottom: 10px;

		.left {
			.sub-title {
				font-size: 18px;
				color: $colorAccent2;
				margin-bottom: 5px;

				a {
					color: inherit;
				}
			}
			.regular-text {
				font-size: 14px;
				color: $colorAccent2;

				a {
					color: inherit;
					color: $colorAccent3;
				}
			}
		}

		.right {
			display: flex;
			align-items: center;

			.sub-title {
				font-size: 18px;
				color: $colorAccent2;
				margin-bottom: 5px;
			}

			.regular-text {
				font-size: 14px;
				color: $colorAccent2;
				padding: 0 25px;
				text-align: right;
			}

			.arrow {
				width: 40px;
				height: 40px;
				background-color: $colorAccent4;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				transition: all 0.2s ease;

				img {
					width: 15px;
				}

				&:hover {
					background-color: $colorAccent3;
				}
			}

			.duplicate {
				display: flex;
				align-items: center;
				margin-right: 20px;
				cursor: pointer;

				img {
					width: 30px;
					margin-right: 10px;
				}

				.sub-title {
					font-size: 14px;
					color: $colorAccent4;
				}
			}

			.type-emploi {
				display: flex;
				align-items: center;
				margin-right: 20px;

				img {
					width: 30px;
					margin-right: 10px;
				}

				.sub-title {
					font-size: 14px;
					color: $colorAccent2;
				}
			}

			.download {
				display: flex;
				align-items: center;
				margin-right: 20px;

				img {
					width: 15px;
					margin-right: 10px;
				}

				.sub-title {
					font-size: 14px;
					color: $colorAccent2;
				}
			}

			.status {
				display: flex;
				align-items: center;
				padding: 0 20px;
				height: 40px;
				border: 2px solid $colorAccent2;
				margin-right: 20px;

				p {
					color: $colorAccent2;
					font-size: 14px;
				}
			}
		}

		@media (max-width: 599px) {
		  flex-wrap: wrap;

		  .left, .right {
		  	width: 100%;
		  }

		  .right {
		  	display: block;

		  	.status {
		  		text-align: center;
		  		justify-content: center;
		  	}

		  	.date {
		  		width: 100%;
		  		padding: 15px 0;
		  		margin: 0;
		  		text-align: left;

		  		.regular-text {
		  			text-align: left;
		  			padding: 0;
		  			margin: 0;
		  		}
		  	}
		  }
		}

	}

	.other-jobs {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 3vw;
		background-image: url("/img/site/dot.jpg");
		background-repeat: repeat-x;
		background-size: 35px;

		.sub-title {
			font-size: 16px;
			color: $colorAccent1;
			background-color: $colorAccent2;
			padding-right: 30px;

			&.link {
				color: $colorAccent4;
				padding-right: 0;
				padding-left: 30px;
				cursor: pointer;
			}
		}
	}
}

.half-section-wrap {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 3vw;
}
.half-section {
	width: calc(50% - 5px);
	margin-bottom: 3vw;

	.sub-title {
		width: 100%;
		margin-bottom: 2vw;
	}

	textarea {
		margin-bottom: 10px;
		height: 150px;
		min-height: 150px;
	}
}

.candidat-side-panel {

	&.show {

		.side-panel {
			right: 0;
			z-index: 5000;
		}

		.overlay {
			opacity: 0.8;
			z-index: 2000;
		}
	}

	.side-panel {
		width: 500px;
		height: 100vh;
		background-color: $colorAccent1;
		position: fixed;
		top: 0;
		right: -500px;
		z-index: 100;
		padding: 5vw 3vw;
		overflow-y: scroll;
		transition: all 0.5s ease;

		.close {
			position: absolute;
			top: 25px;
			right: 25px;
			cursor: pointer;
			img {
				width: 30px;
			}
		}

		.title {
			color: $colorAccent2;
			font-size: 30px;
			margin-bottom: 15px;
		}

		.contact-info {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			line-height: 1.5;

			.contact {
				display: flex;
				align-items: center;
				margin-right: 20px;

				img {
					width: 15px;
					margin-right: 10px;
				}

				.sub-title {
					color: $colorAccent2;
					font-size: 15px;
					transition: all 0.2s ease;
				}

				&:hover {
					.sub-title {
						color: $colorAccent4;
					}
				}
			}
		}

		.poste-wrap {
			width: 100%;
			margin-top: 3vw;

			.sub-title {
				color: $colorAccent2;
				font-size: 20px;
				&.smaller {
					color: $colorAccent4;
					font-size: 14px;
					margin-bottom: 10px;
				}
			}
		}

		.download-wrap {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 3vw;

			.download {
				width: calc(50% - 5px);
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 15px 20px;
				border: 2px solid $colorAccent2;
				transition: all 0.2s ease;

				img {
					width: 12px;
				}

				.sub-title {
					font-size: 14px;
					color: $colorAccent2;
					transition: all 0.2s ease;
				}

				&:hover {
					background-color: $colorAccent2;

					.sub-title {
						color: $colorAccent1;
					}
				}
			}
		}

		.message-wrap {
			width: 100%;
			margin-top: 2.5vw;

			.sub-title {
				&.smaller {
					color: $colorAccent4;
					font-size: 14px;
					margin-bottom: 10px;
				}
			}

			.regular-text {
				color: $colorAccent2;
				line-height: 1.5;
				font-size: 14px;
			}
		}

		.status-wrap {
			width: 100%;
			margin-top: 2.5vw;

			.sub-title {
				&.smaller {
					color: $colorAccent4;
					font-size: 14px;
					margin-bottom: 20px;
				}
			}

			.container {
				font-family: $fontSemi;
				text-transform: uppercase;
				font-size: 14px;
				color: $colorAccent2;
			}

			.checkmark {
				background-color: $colorAccent1;
				border: 3px solid $colorAccent2;
			}

			.container input:checked ~ .checkmark {
				background-color: $colorAccent3;
				border: 3px solid $colorAccent3;
			}
		}

		.note-wrap {
			margin-top: 2.5vw;
			.sub-title {
				&.smaller {
					color: $colorAccent4;
					font-size: 14px;
					margin-bottom: 20px;
				}
			}
		}

		.interets-wrap {
			margin-top: 2.5vw;
			.sub-title {
				&.smaller {
					color: $colorAccent4;
					font-size: 14px;
					margin-bottom: 10px;
					margin-top: 2.5vw;
				}
			}

			.regular-text {
				color: $colorAccent2;
				margin-bottom: 10px;
			}
		}

		.save {
			background-color: $colorAccent3;
			margin-top: 2.5vw;

			&.is-hidden {
				display: none;
			}
		}
	}

	.overlay {
		position: fixed;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: $colorAccent2;
		opacity: 0;
		z-index: -1;
		transition: all 0.5s ease;
	}
}

.outils-wrap {
	width: 100%;
	padding: 5vw 10vw;

	.title {
		font-size: 24px;
		margin-bottom: 10px;
	}

	.regular-text {
		font-size: 16px;
		line-height: 1.5;
		width: 50%;
	}

	.tool-box-wrap {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 2.5vw;

		.tool-box {
			width: calc(50% - 5px);
			background-color: $colorAccent1;
			padding: 3vw;
			position: relative;
			margin-bottom: 10px;

			.title {
				color: $colorAccent2;
				font-size: 24px;
				margin-bottom: 2vw;
			}

			.puce {
				display: flex;
				align-items: center;
				margin-bottom: 20px;

				img {
					width: 30px;
					margin-right: 10px;
				}

				.sub-title {
					color: $colorAccent2;
					font-size: 16px;
				}
			}

			.arrow-box {
				width: 40px;
				height: 40px;
				background-color: $colorAccent4;
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				right: 1vw;
				bottom: 1vw;

				img {
					width: 20px;
				}

			}
		}
	}
}

.helper-zone {
	margin: 0;
	margin-top: 0;
	margin-bottom: 50px;
	background: #fff;
	padding: 25px 20px;
	color: $colorAccent1;
	line-height: 1.5;
	border-radius: 4px;
	border-left: 4px solid $colorAccent1;

	p {
		position: relative;
		top: 0;
		left: 0;
		transform: none;
	}

	strong {
		display: inline-block;
		padding-bottom: 5px;
		font-size: 120%;
		font-weight: 700;
		text-transform: uppercase;
	}

	span {
		color: $colorAccent3;
	}
}

.btns-wrap {
	margin-top: 2vw;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	input,
	input.save {
		margin: 0;
		margin-right: 45px;

		&.is-hidden {
			display: none;
		}
	}
}

.swal2-container {
	z-index: 9999;
}

.form-fields {
	width: 70%;
	display: flex;
	justify-content: space-between;

	flex-wrap: wrap;

	padding-right: 35px;

	@media (max-width: 955px) {
		width: 100%;
		padding-right: 0;
  }

	.regular-text.placeholder-label,
	.regular-text.placeholder-label a {
		width: 50%;
		color: $colorAccent1;

		text-align-last: left;

		&:last-child {
			text-align: right;
		}
	}
}

.form-aside {
	width: 30%;
	padding: 35px;
	margin-bottom: 130px;
	background: $colorAccent1;

	@media (max-width: 955px) {
		width: 100%;
		margin-top: 0;
		margin-bottom: 45px;
  }

	.checkbox-wrap {
		margin: 0;
		padding: 0;

		color: $colorAccent2;

		label {
			display: inline-block;
		}

		.sub-title {
			color: $colorAccent2;
		}

		.container {
			width: 100%;
			color: $colorAccent2;
		}
	}
}

.form-with-cols {
	width: 80%;

	@media (max-width: 599px) {
		width: 100%;
		padding-right: 20px;
	}

	.cols {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		@media (max-width: 599px) {
		  flex-wrap: wrap;
		}
	}

	.checkbox-wrap {
		width: 100%;

		.sub-title {
			width: 100%;
		}

		.container {
			width: 100%;
			font-size: 14px;
			font-family: $fontSemi;
			text-transform: uppercase;
			color: $colorAccent1;
		}

		&.thin {
			margin-top: 0;

			.container {
				font-family: $fontRegular;
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 150%;
				text-transform: none;
				/* identical to box height, or 30px */
				letter-spacing: 0.05em;

				/* Bleu */
				color: #212C47;

				@media (max-width: 599px) {
				  font-size: 16px;
				  line-height: 1.3;
				}

				.checkmark {
					top: 4px;
				}
			}
		}
	}
}

.separator {
	height: 2px;
	width: 100%;
	background: $colorAccent3;
	border: none;
}

.sub-form-container {
	display: flex;
	justify-content: space-between;

	width: 100%;
	padding: 5vw 10vw;

	@media (max-width: 1255px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 955px) {
  	flex-direction: column;
  	padding-top: 45px;
  }

	.sub-form {
		width: calc(90% / 2);
		padding: 0;

		@media (max-width: 955px) {
			width: 100%;
			padding-bottom: 45px;
			margin-bottom: 45px;
			border-bottom: 2px solid $colorAccent1;
		}
	}
}

.inputs-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-top: 25px;

	@media (max-width: 599px) {
	  flex-direction: column;
	  align-items: flex-start;
	  width: 100%;
	}

	input, .cta-wrap, .save {
		margin: 0;
		margin-right: 25px;
		top: 0;

		@media (max-width: 599px) {
			margin-bottom: 15px;
			width: 100%;
		}

		&.alternate {
			background-color: $colorAccent4;

			&:hover {
				background: $colorAccent1;
			}
		}
	}

	.cta-wrap.alternate {

	}
}

[data-statut="Ignorer/Cacher"] {
	opacity: 0.125;
}

.select-lang-wrap {
	width: 100%;
    padding: 5vw 10vw;
	padding-bottom: 0;
	max-width: 45%;
}